// CustomNode.js
import React, { useEffect, useState } from "react";
import "./CustomNode.css";
import {
  Flex,
  Menu,
  Switch,
  MenuButton,
  MenuList,
  Text,
  Tooltip,
  Button,
  background,
} from "@chakra-ui/react";
import { Handle, Position } from "reactflow";
import {
  BsFilePdf,
  BsFillFileEarmarkArrowDownFill,
  BsFillFolderFill,
} from "react-icons/bs";

const CustomNode = (props) => {
  // console.log( props?.data?.styles?.borderColor)
  const [showFilePreview, setShowFilePreview] = useState(
    window.localStorage.getItem("filePreview")
      ? JSON.parse(window.localStorage.getItem("filePreview"))
      : true
  );
  return (
    <Flex
      className="custom-node"
      style={{
        textAlign: "center",
        width: (props?.data?.styles?.fontSize * 13).toString() + "px",
        backgroundColor:
          props?.data?.styles?.backgroundColor !== "" &&
          props?.data?.styles?.backgroundColor,
        border: `3px solid ${
          props?.data?.styles?.borderColor !== "black" &&
          (props?.data?.styles?.borderColor === "orange"
            ? "#e68250"
            : props?.data?.styles?.borderColor)
        }`,
      }}
      maxW={(props?.data?.styles?.fontSize * 13).toString() + "px"}
      maxH={(props?.data?.styles?.fontSize * 11.5).toString() + "px"}
      justifyContent="center"
      alignItems="center"
      width="100%"
      cursor="auto"
    >
      <Handle type="target" id={props.id} isConnectable={props.isConnectable} />

      <Flex
        width="100%"
        zIndex={"10000"}
        borderBottom={
          props?.data?.file_links &&
          props?.data?.file_links?.length > 0 &&
          "2px solid  rgba(0,0,0, 0.3)"
        }
        justifyContent={"center"}
        cursor={props?.data?.isVersion === undefined && "pointer"}
        alignItems="center"
        onClick={() =>
          props?.data?.isVersion === undefined &&
          (window.location = "/page/" + props.id)
        }
      >
        <Flex
          width="100%"
          minW={"100px"}
          p={"5px"}
          justifyContent={"center"}
          alignItems="center"
          flexWrap="wrap"
        >
          {props?.data?.label?.includes("<Deleted Page>") &&
          props?.data?.isVersion !== undefined ? (
            <>
              <Tooltip label="This page has been deleted permanently" hasArrow>
                Deleted Block
              </Tooltip>
            </>
          ) : (
            props.data.label
          )}
        </Flex>
      </Flex>
      <Flex
        width="100%"
        p={showFilePreview && props?.data?.file_links?.length > 0 ? "5px" : 0}
        justifyContent={"start"}
        alignItems={"center"}
      >
        {showFilePreview &&
          props?.data?.file_links &&
          props?.data?.file_links
            .filter((file) => file.thumbnail !== null)
            .map(
              (file, ind) =>
                ind < 3 && (
                  <Tooltip label={file.title} placement="bottom" hasArrow>
                    <Flex
                      className="custom-node-content"
                      zIndex={"10000"}
                      cursor={props?.data?.isVersion === undefined && "pointer"}
                      maxH={
                        (props?.data?.styles?.fontSize * 5).toString() + "px"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(file.file, "_blank", "noopener,noreferrer");
                      }}
                      id={file.id}
                      m={1}
                      maxW={(99 / 3).toString() + "%"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      borderRadius="2px"
                    >
                      {file?.thumbnail && (
                        <img
                          src={file.thumbnail}
                          alt="Thumbnail"
                          style={{
                            maxHeight:
                              (props?.data?.styles?.fontSize * 7).toString() +
                              "px",
                            maxW: (99 / 3).toString() + "%",
                            minHeight:
                              (props?.data?.styles?.fontSize * 2).toString() +
                              "px",
                            border: "1px solid #000",
                          }}
                        />
                      )}
                    </Flex>
                  </Tooltip>
                )
            )}
        {showFilePreview &&
          props?.data?.file_links &&
          props?.data?.file_links
            .filter((file) => file.thumbnail === null)
            .map(
              (_, index) =>
                index +
                  props?.data?.file_links?.filter(
                    (file) => file.thumbnail !== null
                  ).length +
                  1 <=
                3
            ) &&
          props?.data?.file_links
            .filter((file) => file.thumbnail === null)
            .map(
              (file, ind) =>
                ind +
                  props?.data?.file_links?.filter(
                    (file) => file.thumbnail !== null
                  ).length +
                  1 <=
                  3 && (
                  <Tooltip label={file.title} placement="bottom" hasArrow>
                    <Flex
                      className="custom-node-content"
                      zIndex={"10000"}
                      cursor={props?.data?.isVersion === undefined && "pointer"}
                      maxH={
                        (props?.data?.styles?.fontSize * 5).toString() + "px"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(file.file, "_blank", "noopener,noreferrer");
                      }}
                      id={file.id}
                      m={1}
                      maxW={(99 / 3).toString() + "%"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      borderRadius="2px"
                    >
                      {file?.thumbnail === null && (
                        <Text color="bg">
                          {file.file.includes("document") &&
                            !file.file.includes(".zip") && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                                width={props?.data?.styles?.fontSize * 3.2}
                                height={props?.data?.styles?.fontSize * 3.2}
                              >
                                <path
                                  fill="#2196f3"
                                  d="M37,45H11c-1.657,0-3-1.343-3-3V6c0-1.657,1.343-3,3-3h19l10,10v29C40,43.657,38.657,45,37,45z"
                                />
                                <path fill="#bbdefb" d="M40 13L30 13 30 3z" />
                                <path fill="#1565c0" d="M30 13L40 23 40 13z" />
                                <path
                                  fill="#e3f2fd"
                                  d="M15 23H33V25H15zM15 27H33V29H15zM15 31H33V33H15zM15 35H25V37H15z"
                                />
                              </svg>
                            )}

                          {(file.file.includes("sheet") ||
                            file.file.includes("csv") ||
                            file.file.includes("xls")) &&
                            !file.file.includes(".zip") && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                                width={props?.data?.styles?.fontSize * 3.2}
                                height={props?.data?.styles?.fontSize * 3.2}
                              >
                                <linearGradient
                                  id="PTsiEfj2THKtO9xz06mlla"
                                  x1="24"
                                  x2="24"
                                  y1="5"
                                  y2="43"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop offset="0" stopColor="#21ad64" />
                                  <stop offset="1" stopColor="#088242" />
                                </linearGradient>
                                <path
                                  fill="url(#PTsiEfj2THKtO9xz06mlla)"
                                  d="M39,16v25c0,1.105-0.895,2-2,2H11c-1.105,0-2-0.895-2-2V7c0-1.105,0.895-2,2-2h17L39,16z"
                                />
                                <path
                                  fill="#61e3a7"
                                  d="M28,5v9c0,1.105,0.895,2,2,2h9L28,5z"
                                />
                                <path
                                  fill="#107c42"
                                  d="M39,16h-9c-0.473,0-0.917-0.168-1.257-0.444L39,27V16z"
                                />
                                <path
                                  fill="#fff"
                                  d="M32,23H16c-0.553,0-1,0.448-1,1v12c0,0.552,0.447,1,1,1h16c0.553,0,1-0.448,1-1V24 C33,23.448,32.553,23,32,23z M17,29h4v2h-4V29z M23,29h8v2h-8V29z M31,27h-8v-2h8V27z M21,25v2h-4v-2H21z M17,33h4v2h-4V33z M23,35 v-2h8v2H23z"
                                />
                                <path
                                  d="M32,22.5c0.827,0,1.5,0.673,1.5,1.5v12c0,0.827-0.673,1.5-1.5,1.5H16c-0.827,0-1.5-0.673-1.5-1.5V24 c0-0.827,0.673-1.5,1.5-1.5H32 M32,22H16c-1.103,0-2,0.897-2,2v12c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2V24 C34,22.897,33.103,22,32,22L32,22z"
                                  opacity=".05"
                                />
                                <path
                                  d="M32,23c0.553,0,1,0.448,1,1v12c0,0.552-0.447,1-1,1H16c-0.553,0-1-0.448-1-1V24c0-0.552,0.447-1,1-1 H32 M32,22.5H16c-0.827,0-1.5,0.673-1.5,1.5v12c0,0.827,0.673,1.5,1.5,1.5h16c0.827,0,1.5-0.673,1.5-1.5V24 C33.5,23.173,32.827,22.5,32,22.5L32,22.5z"
                                  opacity=".07"
                                />
                              </svg>
                            )}
                          {file.file.includes("sheet") === false &&
                            !file.file.includes("csv") &&
                            !file.file.includes("xls") &&
                            !file.file.includes(".zip") &&
                            file.file.includes("document") === false &&
                            file.file.includes("drive") && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                                width={props?.data?.styles?.fontSize * 3}
                                height={props?.data?.styles?.fontSize * 3}
                              >
                                <path
                                  fill="#FFC107"
                                  d="M17 6L31 6 45 30 31 30z"
                                />
                                <path
                                  fill="#1976D2"
                                  d="M9.875 42L16.938 30 45 30 38 42z"
                                />
                                <path
                                  fill="#4CAF50"
                                  d="M3 30.125L9.875 42 24 18 17 6z"
                                />
                              </svg>
                            )}
                          {file.file.includes(".zip") && (
                            <Text>
                              <svg
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                height={props?.data?.styles?.fontSize * 3}
                                width={props?.data?.styles?.fontSize * 3}
                              >
                                <path d="M5 7.5a1 1 0 011-1h1a1 1 0 011 1v.938l.4 1.599a1 1 0 01-.416 1.074l-.93.62a1 1 0 01-1.11 0l-.929-.62a1 1 0 01-.415-1.074L5 8.438V7.5zm2 0H6v.938a1 1 0 01-.03.243l-.4 1.598.93.62.929-.62-.4-1.598A1 1 0 017 8.438V7.5z" />
                                <path d="M14 4.5V14a2 2 0 01-2 2H4a2 2 0 01-2-2V2a2 2 0 012-2h5.5L14 4.5zm-3 0A1.5 1.5 0 019.5 3V1h-2v1h-1v1h1v1h-1v1h1v1H6V5H5V4h1V3H5V2h1V1H4a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V4.5h-2z" />
                              </svg>
                            </Text>
                          )}
                          {(file.file.includes("colab") ||
                            file.file.includes(".py") ||
                            file.file.includes("ipynb") ||
                            file.file.toLowerCase().includes(".r")) &&
                            !file.file.includes(".zip") && (
                              <Text>
                                <svg
                                  viewBox="0 0 16 16"
                                  fill="currentColor"
                                  height={props?.data?.styles?.fontSize * 3}
                                  width={props?.data?.styles?.fontSize * 3}
                                >
                                  <path d="M14 4.5V14a2 2 0 01-2 2H4a2 2 0 01-2-2V2a2 2 0 012-2h5.5L14 4.5zm-3 0A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V4.5h-2z" />
                                  <path d="M8.646 6.646a.5.5 0 01.708 0l2 2a.5.5 0 010 .708l-2 2a.5.5 0 01-.708-.708L10.293 9 8.646 7.354a.5.5 0 010-.708zm-1.292 0a.5.5 0 00-.708 0l-2 2a.5.5 0 000 .708l2 2a.5.5 0 00.708-.708L5.707 9l1.647-1.646a.5.5 0 000-.708z" />
                                </svg>
                              </Text>
                            )}
                          {file.file.toLowerCase().includes("sheet") ===
                            false &&
                            !file.file.includes("xls")&&
                            !file.file.includes("csv") &&
                            file.file.toLowerCase().includes("document") ===
                              false &&
                            file.file.toLowerCase().includes("drive") ===
                              false &&
                            file.file.includes("colab") === false &&
                            file.file.toLowerCase().includes(".r") === false &&
                            file.file.toLowerCase().includes("ipynb") ===
                              false &&
                            file.file.toLowerCase().includes("kms") &&
                            !file.file.includes(".zip") && (
                              <Text>
                                <svg
                                  width={props?.data?.styles?.fontSize * 3}
                                  height={props?.data?.styles?.fontSize * 3}
                                  viewBox="0 0 39 39"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    width="39"
                                    height="39"
                                    rx="19.5"
                                    fill="#2D3842"
                                  />
                                  <path
                                    d="M19.5683 8.37869L23.941 17.8038L8.5946 30.7297L19.5683 8.37869Z"
                                    fill="#BC9842"
                                  />
                                  <path
                                    d="M19.4711 8.35135L15.0984 17.7765L30.4448 30.7024L19.4711 8.35135Z"
                                    fill="#BC9842"
                                  />
                                </svg>
                              </Text>
                            )}
                          {file.file.toLowerCase().includes("sheet") ===
                            false &&
                            !file.file.includes("csv") &&
                            !file.file.includes("xls")&&

                            file.file.toLowerCase().includes("document") ===
                              false &&
                            file.file.toLowerCase().includes("drive") ===
                              false &&
                            file.file.includes("colab") === false &&
                            file.file.toLowerCase().includes(".r") === false &&
                            file.file.toLowerCase().includes("ipynb") ===
                              false &&
                            !file.file.toLowerCase().includes(".py") &&
                            !file.file.toLowerCase().includes("kms") &&
                            !file.file.includes(".zip") &&
                            file.file.includes("http") && (
                              <Text>
                                <svg
                                  viewBox="0 0 21 21"
                                  fill="currentColor"
                                  height={props?.data?.styles?.fontSize * 3}
                                  width={props?.data?.styles?.fontSize * 3}
                                >
                                  <g
                                    fill="none"
                                    fillRule="evenodd"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="M9.5 11.5c.97 1.367 3.011 1.127 4.011 0l1.989-2c1.124-1.228 1.164-2.814 0-4-1.136-1.157-2.864-1.157-4 0l-2 2" />
                                    <path d="M11.5 10.57c-.97-1.367-3-1.197-4-.07l-2 1.975c-1.124 1.228-1.164 2.839 0 4.025 1.136 1.157 2.864 1.157 4 0l2-2" />
                                  </g>
                                </svg>
                              </Text>
                            )}
                          {file.file.toLowerCase().includes("sheet") ===
                            false &&
                            !file.file.includes("xls")&&
                            !file.file.includes("csv") &&
                            file.file.toLowerCase().includes("document") ===
                              false &&
                            file.file.toLowerCase().includes("drive") ===
                              false &&
                            file.file.includes("colab") === false &&
                            file.file.toLowerCase().includes(".r") === false &&
                            file.file.toLowerCase().includes("ipynb") ===
                              false &&
                            !file.file.toLowerCase().includes(".py") &&
                            !file.file.toLowerCase().includes("kms") &&
                            !file.file.includes("http") &&
                            !file.file.includes(".zip") && (
                              <Text>
                                <svg
                                  viewBox="0 0 1024 1024"
                                  fill="currentColor"
                                  height={props?.data?.styles?.fontSize * 3}
                                  width={props?.data?.styles?.fontSize * 3}
                                >
                                  <path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494zM504 618H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM312 490v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8z" />
                                </svg>
                              </Text>
                            )}
                        </Text>
                      )}
                    </Flex>
                  </Tooltip>
                )
            )}
      </Flex>
      {props.data.has_children && (
        <Flex height="100%" zIndex="1000" width="30px" mb={-10} mr={-14}>
          <Flex
            onClick={() => props.data.nodeViewHandler(props.id)}
            mt={2}
            bg="rgba(255,255,255, 0.2)"
            width="100%"
            _hover={{ bg: "rgba(255,255,255, 0.6)" }}
            justifyContent={"center"}
            alignItems={"center"}
            h={"30px"}
            zIndex="10000"
          >
            {
              props.data.isCollapsed ? (
                // <Tooltip label={"Expand sub tree"} placement="top" hasArrow>
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.29688 0.5625H9.70312C9.82812 0.5625 9.89062 0.625 9.89062 0.75V17.25C9.89062 17.375 9.82812 17.4375 9.70312 17.4375H8.29688C8.17187 17.4375 8.10938 17.375 8.10938 17.25V0.75C8.10938 0.625 8.17187 0.5625 8.29688 0.5625Z"
                    fill="black"
                  />
                  <path
                    d="M1.125 8.10938H16.875C17 8.10938 17.0625 8.17187 17.0625 8.29688V9.70312C17.0625 9.82813 17 9.89062 16.875 9.89062H1.125C1 9.89062 0.9375 9.82813 0.9375 9.70312V8.29688C0.9375 8.17187 1 8.10938 1.125 8.10938Z"
                    fill="black"
                  />
                </svg>
              ) : (
                // </Tooltip>
                // <Tooltip label={"Compress sub tree"} placement="top" hasArrow>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="30"
                  viewBox="0 0 24 24"
                >
                  <path d="M19 13H5v-2h14v2z" />
                </svg>
              )
              // </Tooltip>
            }
          </Flex>
        </Flex>
      )}
      <Handle
        type="source"
        position={Position.Bottom}
        id={props.id}
        isConnectable={props.isConnectable}
      />
    </Flex>
  );
};

export default CustomNode;
