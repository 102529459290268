import { useState, useEffect, useRef, useContext, useMemo } from "react";
import {
  Flex,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Select,
  Th,
  Td,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Spinner,
  Tag,
  Icon,
  Box,
  SimpleGrid,
  Heading,
  useColorModeValue,
  Stack,
  Avatar,
  Center,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from "@chakra-ui/react";
import { ChevronDownIcon, SearchIcon, StarIcon } from "@chakra-ui/icons";

import { useParams } from "react-router-dom";

import AuthContext from "../store/auth";
import swal from "sweetalert";
import { BsStar, BsStarFill, BsStarHalf, BsTrash } from "react-icons/bs";
import {HiOutlineInformationCircle} from "react-icons/hi";
import Pagination from "../components/Pagination";
import Rating from "../components/Rating";
import { useHotkeys } from "react-hotkeys-hook";
import Breadcrumbs from "../components/BreadCrumbs";
import { useToast } from "@chakra-ui/react";
import { Url } from "../store/global";

const LeaderBoard = () => {
  // authentication module
  const toast = useToast();
  const authCtx = useContext(AuthContext);

  // filter state
  let { projectSlug } = useParams();
  const [activeFilter, setActiveFilter] = useState(
    window.localStorage.getItem("activeFilter")
      ? projectSlug
        ? projectSlug
        : window.localStorage.getItem("activeFilter")
      : "all"
  );

  // new template modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const title = useRef();
  const [isClient, setIsClient] = useState(true);
  const [loading, setLoading] = useState(false);
  const industry = useRef();
  const client = useRef();
  const visibility = useRef();
  const dateSlug = useRef("2023-08-07");

  const code = useRef();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  // template list state
  const [templateInfo, setTemplateInfo] = useState(null);
  const [leaderBoardInfo, setLeaderBoardInfo] = useState(null);
  const [rankInfo, setRankInfo] = useState(null);
  const [templateInfo2, setTemplateInfo2] = useState(null);
  const [currWeekMonday, setCurrWeekMonday] = useState();
  const [currWeekMondayFormatted, setCurrWeekMondayFormatted] = useState();


  const logoutHandler = () => {
    authCtx.logout();
  };

  const [bot, setBot] = useState(
    JSON.parse(window.localStorage.getItem("curr"))
      ? JSON.parse(window.localStorage.getItem("curr"))
      : 0
  );
  const [curr, setCurr] = useState(0);

  const [search, setSearch] = useState("");

  // const [currentPage, setCurrentPage] = useState(1);
  // // const [currentLength, setCurrentLength] = useState(templateInfo?templateInfo.length:0);
  // const currentLength = useRef(templateInfo ? templateInfo.length : 0);

  // useEffect(() => {
  //   setCurrentPage(1);
  // }, [search, activeFilter]);

  // useEffect(() => {
  //   if (templateInfo) currentLength.current = templateInfo.length;
  // }, [templateInfo]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  // POST request to create a new template
  const addProjectHandler = () => {
    // onClose();

    const rtitle = title.current.value;
    const rindustry = industry.current.value;
    const rclient = client.current.value;
    const rcode = code.current.value;
    const rvisibility = visibility.current.value;

    if (rtitle && rindustry && rclient && rcode) {
      fetch({ Url }.Url + "/create_project/", {
        method: "POST",
        body: JSON.stringify({
          title: rtitle,
          industry: rindustry,
          client_name: rclient,
          project_code: rcode,
          visibility: rvisibility,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            if (res.status < 300) {
              // console.log(res.data);
              toast({
                title: "Project created successfully!",
                description: "Let's visit the new template page.",
                status: "success",
                duration: 2000,
                isClosable: true,
                onCloseComplete: () => {
                  window.location = "/template/" + res.data.project_id;
                },
              });
            } else {
              // console.log(res.data);
              toast({
                title: "Project created failed.",
                description: res.data.detail,
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          })
      );
    }
  };

  function getCurrentWeekMonday() {
    var currentDate = new Date();

    // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    var currentDayOfWeek = currentDate.getDay();

    // Calculate the number of days to subtract to get to Monday (assuming Sunday as the first day of the week)
    var daysToSubtract = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;

    // Calculate the date of the previous Monday
    var previousMonday = new Date(currentDate);
    previousMonday.setDate(currentDate.getDate() - daysToSubtract);

    // Now 'previousMonday' contains the date of the current week's Monday
    console.log(previousMonday);
    function getOrdinalSuffix(day) {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    var options = { month: "long" };
    var dayOfMonth = previousMonday.getDate();
    var formattedDate =
      dayOfMonth +
      getOrdinalSuffix(dayOfMonth) +
      " " +
      previousMonday.toLocaleString("en-US", options);
    setCurrWeekMonday(formattedDate);
  }

  function getMonday() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    
    // Calculate the number of days to subtract to get to Monday (assuming Monday is the first day of the week)
    const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    
    // Calculate the date of the current week's Monday
    const monday = new Date(today);
    monday.setDate(today.getDate() - daysToSubtract);
    
    // Format the date as yyyy-mm-dd
    const year = monday.getFullYear();
    const month = (monday.getMonth() + 1).toString().padStart(2, '0');
    const day = monday.getDate().toString().padStart(2, '0');
    console.log(`${year}-${month}-${day}`)
    
    return `${year}-${month}-${day}`;
  }
  
  const start = new Date("2023-09-11");
  // const end = new Date("2023-09-08");
  const today = new Date();
  // console.log(today);
  const todayDate = {
    day: today.getDate(),
    month: today.getMonth() + 1, // Months are zero-based, so add 1
    year: today.getFullYear(),
  };
  const end = new Date(`${todayDate.year}-${todayDate.month}-${todayDate.day}`);
  // console.log(end)
  function addNumberSuffix(number) {
    if (number >= 11 && number <= 13) {
      return number + "th";
    }

    switch (number % 10) {
      case 1:
        return number + "st";
      case 2:
        return number + "nd";
      case 3:
        return number + "rd";
      default:
        return number + "th";
    }
  }
  const DAY = 24 * 60 * 60 * 1000;
  function getWeekOfMonth(date) {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const dayOfWeek = firstDayOfMonth.getDay();
    const dayOfMonth = date.getDate();
    const weekNumber = Math.ceil((dayOfMonth + dayOfWeek) / 7);
    return weekNumber;
  }
  const weeks = [];

  function SelectDates(dateAPI, dateDisplay) {
    this.dateAPI = dateAPI;
    this.dateDisplay = dateDisplay;
  }
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  for (
    let newStart = start.valueOf();
    newStart < end - 1;
    newStart += DAY * 7
  ) {
    for (let d = newStart; d < newStart + 7 * DAY; d += DAY) {
      const date = new Date(d);

      // console.log(date.getDay());
      const numericDate = {
        day: date.getDate(),
        month: date.getMonth() + 1, // Months are zero-based, so add 1
        year: date.getFullYear(),
      };

      if (date.getDay() === 1) {
        const weekNumber = getWeekOfMonth(date);
        const monthName = monthNames[date.getMonth()];
        // console.log(`${weekNumber} week of ${monthName}`);
        const display = `${addNumberSuffix(numericDate.day)} ${monthName}`;
        const zeroMonth = numericDate.month.toString().padStart(2, "0");
        const zeroDay = numericDate.day.toString().padStart(2, "0");
        // const zeroMonth = numericDate.month.toString().padStart(2, '0');
        console.log(zeroMonth);
        const varDate = `${numericDate.year}-${zeroMonth}-${zeroDay}`;
        weeks.push(new SelectDates(varDate, display));
      }
    }
  }

  console.log(weeks);
  weeks.reverse();

  // Leaderboard according to week End

  // Create Folder Handler
  const addFolderHandler = () => {
    // onClose();

    const rtitle = title.current.value;
    const rindustry = industry.current.value;
    const rclient = client.current.value;
    // const rcode = code.current.value;
    // const rvisibility = visibility.current.value;

    // if (rtitle && rindustry && rclient && rcode) {
    if (rtitle) {
      fetch({ Url }.Url + "/create_folder/", {
        method: "POST",
        body: JSON.stringify({
          title: rtitle,
          industry: rindustry,
          client_name: rclient,
          // project_code: rcode,
          // visibility: rvisibility,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            if (res.status < 300) {
              // console.log(res.data);
              toast({
                title: "Project created successfully!.",
                description: "Let's visit the new template page.",
                status: "success",
                duration: 2000,
                isClosable: true,
                onCloseComplete: () => {
                  window.location = "/folder/" + res.data.id;
                },
              });
            } else {
              // console.log(res.data);
              toast({
                title: "Project creation failed.",
                description: res.data.detail,
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          })
      );
    }
  };
  const [firstRank, setFirstRank] = useState();
  const [secondRank, setSecondRank] = useState();
  const [thirdRank, setThirdRank] = useState();

  // API GET request to query all the projects
  const getTemplates = () => {
    fetch({ Url }.Url + "/templates/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            // console.log(res.data);
            setTemplateInfo(res.data);
            setTemplateInfo2(res.data);

            // console.log(direction);
          } else {
            toast({
              title: "Oops, something went wrong!",
              description: res.data.detail,
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        })
    );
  };
  function findNextWeek(date) {
    const nextWeek = new Date(date);
    nextWeek.setDate(nextWeek.getDate() + 7); // Adding 7 days for the next week and 1 more day

    // Format the date as yyyy-mm-dd
    const year = nextWeek.getFullYear();
    const month = String(nextWeek.getMonth() + 1).padStart(2, "0");
    const day = String(nextWeek.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  function setRanks(data){
    console.log(data);
    const topRankUsers = data.filter(item => item.final_rank === 1 && item.user.name.trim() !== '');
    console.log(topRankUsers)
    console.log(topRankUsers.sort((a, b) => a.user.name.localeCompare(b.user.name)));
    const top3Names = topRankUsers.slice(0, 3).map(item => item.user.name);
    const topRankUsers2 = data.filter(item => item.final_rank === 2 && item.user.name.trim() !== '');
    topRankUsers2.sort((a, b) => a.user.name.localeCompare(b.user.name) );
    const top3Names2 = topRankUsers2.slice(0, 3).map(item => item.user.name);
    const topRankUsers3 = data.filter(item => item.final_rank === 3 && item.user.name.trim() !== '');
    topRankUsers3.sort((a, b) => a.user.name.localeCompare(b.user.name));
    const top3Names3 = topRankUsers3.slice(0, 3).map(item => item.user.name);
    console.log(top3Names, top3Names2, top3Names3);
    setSecondRank(top3Names2);
    setFirstRank(top3Names);
    setThirdRank(top3Names3);
  }
  const getLeaderBoard = (start) => {
    setLoading(true);
    console.log(start);
    const givenDate = new Date(start);

    const nextWeek = new Date(givenDate);
    nextWeek.setDate(nextWeek.getDate() + 7); // Adding 7 days for the next week and 1 more day

    // Format the date as yyyy-mm-dd
    const year = nextWeek.getFullYear();
    const month = String(nextWeek.getMonth() + 1).padStart(2, "0");
    const day = String(nextWeek.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    console.log(formattedDate); // Output: "2023-08-13"
    console.log();
    fetch(
      { Url }.Url +
        "/leaderboard/users/activity?start_date=" +
        start +
        "&end_date=" +
        formattedDate,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            // console.log(res.data);
            setLeaderBoardInfo(res.data.leaderboard);
            setRanks(res.data.leaderboard);
            // setTemplateInfo2(res.data);
            console.log(res.data.weeks, "show");
            setLoading(false);

            // console.log(direction);
          } else {
            toast({
              title: "Oops, something went wrong!",
              description: res.data.detail,
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        })
    );
  };


  // const getLeaderBoardRank = () => {
  //   fetch({ Url }.Url + "/leaderboard/users", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Token " + authCtx.token,
  //     },
  //   }).then((response) =>
  //     response
  //       .json()
  //       .then((data) => ({
  //         data: data,
  //         status: response.status,
  //       }))
  //       .then((res) => {
  //         if (res.status < 300) {
  //           setRankInfo(res.data.leaderboard);
  //           console.log(res.data.leaderboard);

  //           // console.log(direction);
  //         } else {
  //           toast({
  //             title: "Oops, something went wrong!",
  //             description: res.data.detail,
  //             status: "error",
  //             duration: 2000,
  //             isClosable: true,
  //           });
  //         }
  //       })
  //   );
  // };

  function getOrdinalSuffix(day) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  function formatDateToOrdinal(dateString) {
    var dateParts = dateString.split("-");
    var year = dateParts[0];
    var month = dateParts[1];
    var day = parseInt(dateParts[2], 10);

    var options = { month: "long" };
    var formattedDate =
      day +
      getOrdinalSuffix(day) +
      " " +
      new Date(year, month - 1, day).toLocaleString("en-US", options);

    return formattedDate;
  }

  function getFormattedDate(date) {
    var inputDate = date;
    var formattedDate = formatDateToOrdinal(inputDate);
    setCurrWeekMonday(formattedDate);
    // return formattedDate;
  }
  function searchValid(template) {
    return (
      template.title.toLowerCase().includes(search.toLowerCase()) ||
      template.project_owner.name
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      template.project_code.toLowerCase().includes(search.toLowerCase()) ||
      template.client_name.toLowerCase().includes(search.toLowerCase()) ||
      template.industry.toLowerCase().includes(search.toLowerCase())
    );
  }

  // runs on initial page load
  useEffect(() => {
    getTemplates();
    getLeaderBoard(getMonday());
    getCurrentWeekMonday();
    setIsClient(authCtx.role === "Client");
    setCurr(JSON.parse(window.localStorage.getItem("curr")));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getLeaderBoard(dateSlug);
    // getLeaderBoardRank();
  }, [dateSlug]);

  const [first, setFirst] = useState(0);

  const [direction, setDirection] = useState(
    JSON.parse(window.localStorage.getItem("direction"))
      ? -1 * JSON.parse(window.localStorage.getItem("direction"))
      : 1
  );

  useEffect(() => {
    // setDirection(-1*direction);
    // console.log(curr);
    window.localStorage.setItem("curr", curr);
    window.localStorage.setItem("direction", direction);

    // eslint-disable-next-line
  }, [bot]);

  // setting filters to local storage
  useEffect(() => {
    activeFilter && window.localStorage.setItem("activeFilter", activeFilter);
    // console.log(activeFilter);
  }, [activeFilter]);

  // const [charRemain, setCharRemain] = useState(30);
  const maxChar = 40;

  // useEffect(() => {
  //   setCharRemain( maxChar - title.current.value.length )
  // }, [title.current.value]);

  // // Save to local storage to persist state on reload
  // useEffect(() => {
  //   setDirection(JSON.parse(window.localStorage.getItem('direction')));
  //   setCurr(JSON.parse(window.localStorage.getItem('curr')));
  // }, []);

  // useEffect(() => {
  //   window.localStorage.setItem('direction', direction);
  //   window.localStorage.setItem('curr', curr);
  // }, [direction,curr]);

  // HTML element tree ̦— render function

  // shortcuts
  useHotkeys("alt+x", () => (window.location = "/library"));
  useHotkeys("option+x", () => (window.location = "/library"));

  const breadcrumbs = [
    { path: "/", breadcrumb: "Home" },
    { path: "/leaderboard", breadcrumb: "Leaderboard", end: true },
  ];

  return (
    <Flex
      backgroundColor="bg"
      flexDir="column"
      alignItems="center"
      minHeight="100vh"
      height="fit-content"
      width="100vw"
    >
      <Flex
        width="95%"
        height="fit-content"
        flexDir="column"
        justifyContent="space-between"
        px="10px"
        mb="20px"
      >
        <Flex
          color="white"
          fontSize="20px"
          fontWeight="400"
          justifyContent="space-between"
        >
          <Flex>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </Flex>
          <Flex color="main.white">
            <Flex>
              <Select
                color="main.white"
                ml="40%"
                mt={2}
                h="40px"
                pl={1}
                // paddingLeft={"3%"}
                border="1px solid"
                bg="main.plainWhite"
                variant="filled"
                width="400px"
                placeholder="Select Week"
                // onChange={()=>{setCurrWeekMonday(this.dateDisplay)}}
                // onChange={()=>{setCurrWeekMonday()}}

                ref={dateSlug}
              >
                {weeks.map((e, index) => (
                  <>
                    <option
                      onChange={() => {
                        setCurrWeekMonday(e.dateDisplay);
                      }}
                      style={{ backgroundColor: "main.plainWhite" }}
                      value={`${e.dateAPI}`}
                    >
                      {e.dateDisplay}
                    </option>
                  </>
                ))}
              </Select>
              <Button
                color="main.white"
                bg="background.yellow1"
                mt={2}
                ml={2}
                onClick={() => {
                  getLeaderBoard(dateSlug.current.value);
                  getFormattedDate(dateSlug.current.value);
                }}
              >
                Show
              </Button>
            </Flex>
          </Flex>
        </Flex>
        {/* <InputGroup width="80%" m="auto">
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.300" />}
          />
          <Input
            onChange={handleSearchChange}
            placeholder="Search projects by title, author, client, category, or template code"
            color="white"
          />
        </InputGroup> */}
        <Flex justifyContent={"space-between"} mb={10}>
          <Flex></Flex>

          <Flex flexDir={"row"} alignItems={"center"}>
            <Text
              style={{ display: "inline-block" }}
              textAlign={"center"}
              fontSize="24px"
              fontWeight="700"
              color={"#fff"}
            >
              Scroll Leaderboard:{" "}
              {currWeekMonday ? (
                <Text color="main.text" style={{ display: "inline-block" }}>
                  {" "}
                  Week of {currWeekMonday}
                </Text>
              ) : (
                <></>
              )}
              <Popover bg={"main.grey3"}>
  <PopoverTrigger>
  <Text style={{ display: "inline-block" }} > <HiOutlineInformationCircle style={{ display: "inline-block", paddingTop:'5px' }} size={"20px"} color="#A0AFBF"/></Text>
  </PopoverTrigger>
  <PopoverContent>
    <PopoverArrow />
    <PopoverCloseButton />
    <PopoverHeader fontSize="sm" bg={"main.grey3"}>How rank is calculated?</PopoverHeader>
    <PopoverBody fontSize="sm" bg={"main.grey3"}>Your ranks on three categories- Number of projects created, number of blocks created and number of days you've logged in to SCROLL are calculated and averaged to get your leaderboard rank</PopoverBody>
  </PopoverContent>

</Popover>
              &nbsp;
            </Text>
            <Text
              fontSize={"2xl"}
              style={{ display: "inline-block" }}
              color={"main.plainWhite"}
              mt={2}
              as="i"
            >
              <Text> </Text>
            </Text>
          </Flex>
          <Flex flexDir={"column"} alignItems="flex-end">
            <Flex></Flex>
          </Flex>
        </Flex>

        <Flex alignItems={"flex-end"} color="main.plainWhite" justify="center">
          <Flex flexDir="column" alignItems={"center"} justify="center">
            <Flex>
              <Text fontSize="xl" fontWeight="800">
                {leaderBoardInfo
                  ? console.log(
                      leaderBoardInfo.filter((e) => {
                        return e.final_rank === 2;
                      })
                    )
                  : null}
                  {secondRank ? (
                  <>
                    {}

                    {secondRank.map((e, index) => (
                        <>
                          
                              <Avatar
                                size="sm"
                                bg="white"
                                name={e}
                                src={e}
                                color="#2D3842"
                                fontWeight="700"
                                mr={2}
                                mb={2}
                              />
                              {e} <br />
                          
                        </>
                      ))}
                  </>
                ) : (
                  <>Loading...</>
                )}
              </Text>
            </Flex>
            <Flex>
              <Box
                borderRadius="lg"
                w={"250px"}
                color="main.plainWhite"
                h={"75px"}
                bg="background.silver"
                m={2}
              >
                <Flex align="center" h={"75px"} justify="center">
                  <Text fontSize="2xl" fontWeight="800" textAlign="center">
                    2nd 🏆
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </Flex>
          <Flex flexDir="column" alignItems={"center"} justify="center">
            <Flex>
              <Text fontSize="xl" fontWeight="800">
                {firstRank ? (
                  <>
                    {}

                    {firstRank.map((e, index) => (
                        <>
                          
                              <Avatar
                                size="sm"
                                bg="white"
                                name={e}
                                src={e}
                                color="#2D3842"
                                fontWeight="700"
                                mr={2}
                                mb={2}
                              />
                              {e} <br />
                          
                        </>
                      ))}
                  </>
                ) : (
                  <>Loading...</>
                )}
              </Text>
            </Flex>
            <Flex>
              <Box
                borderRadius="lg"
                w={"250px"}
                color="main.plainWhite"
                h={"100px"}
                bg="background.gold"
                m={2}
              >
                <Flex align="center" h={"100px"} justify="center">
                  <Text fontSize="2xl" fontWeight="800" textAlign="center">
                    1st 🏆
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </Flex>
          <Flex flexDir="column" alignItems={"center"} justify="center">
            <Flex>
              <Text fontSize="xl" fontWeight="800">
              {thirdRank ? (
                  <>
                    {}

                    {thirdRank.map((e, index) => (
                        <>
                          
                              <Avatar
                                size="sm"
                                bg="white"
                                name={e}
                                src={e}
                                color="#2D3842"
                                fontWeight="700"
                                mr={2}
                                mb={2}
                              />
                              {e} <br />
                          
                        </>
                      ))}
                  </>
                ) : (
                  <>Loading...</>
                )}
              </Text>
            </Flex>
            <Flex>
              <Box
                borderRadius="lg"
                w={"250px"}
                color="main.plainWhite"
                h={"50px"}
                bg="background.bronze"
                m={2}
              >
                <Flex align="center" h={"50px"} justify="center">
                  <Text fontSize="2xl" fontWeight="800" textAlign="center">
                    3rd 🏆
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <Flex width="50%" justify="center"></Flex>

        <Flex
          justify="center"
          // flexDir={"row"}
          // style={{
          //   // display: "grid",
          //   // gridTemplateColumns: "repeat(auto-fill, minmax(25rem, 1fr))",
          //   // gridGap: "1rem",
          // }}
          mt="5"
          mb={"100px"}
          cursor="pointer"
        >
          {loading ? (
            <>
              <Spinner />
            </>
          ) : (
            <>
              <Center>
                <Table variant="" size="md">
                  <Thead>
                    <Tr>
                      <Th
                        textAlign="center"
                        color="#FFFFFF45"
                        fontSize="24px"
                        cursor="pointer"
                        fontWeight="800"
                      >
                        {"  "}&nbsp; Rank
                      </Th>

                      <Th
                        textAlign="left"
                        color="#FFFFFF45"
                        fontSize="24px"
                        cursor="pointer"
                        fontWeight="800"
                        // onClick={() => {
                        //   if (sortby !== "title") setSortby("title");
                        //   setDirection((direction) => direction * -1);
                        // }}
                      >
                        Person{"  "}&nbsp;
                      </Th>
                      <Th
                        textAlign="center"
                        color="#FFFFFF45"
                        fontSize="24px"
                        cursor="pointer"
                        fontWeight="800"

                        // onClick={() => {
                        //   if (sortby !== "client_name") setSortby("client_name");
                        //   setDirection((direction) => direction * -1);
                        // }}
                      >
                        Active Days{"  "}&nbsp;
                      </Th>

                      <Th
                        textAlign="center"
                        color="#FFFFFF45"
                        fontSize="24px"
                        cursor="pointer"
                        fontWeight="800"
                        // onClick={() => {
                        //   if (sortby !== "last_modified")
                        //     setSortby("last_modified");
                        //   setDirection((direction) => direction * -1);
                        // }}
                      >
                        # of Blocks {"  "}&nbsp;
                      </Th>

                      <Th
                        textAlign="center"
                        color="#FFFFFF45"
                        fontSize="24px"
                        cursor="pointer"
                        fontWeight="800"
                        // onClick={() => {
                        //   if (sortby !== "project_owner__email")
                        //     setSortby("project_owner__email");
                        //   setDirection((direction) => direction * -1);
                        // }}
                      >
                        # of Projects{"  "}&nbsp;
                      </Th>
                      {/* <Th
                    textAlign="center"
                    color="#FFFFFF45"
                    fontSize="24px"
                    cursor="pointer"
                    fontWeight="800"
               
                  >
                    Approval{"  "}&nbsp;
                  </Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {leaderBoardInfo &&
                      leaderBoardInfo.map((item, index) => (
                        <>
                          {
                            <Tr
                              key={index}
                              bg="background.dark"
                              textAlign="center"
                              cursor="pointer"
                              border="1px solid"
                              borderColor="#FFFFFF45"
                              borderOpacity="0.27"
                            >
                              {" "}
                              <Td
                                color="white"
                                fontSize="14px"
                                textAlign="center"
                                pl="4"
                                ml="5"
                              >
                                {item.final_rank}
                              </Td>
                              <Td
                                color="white"
                                fontSize="14px"
                                textAlign="left"
                                // style={{
                                //   minWidth: "205px",
                                //   maxWidth: "205px",
                                //   width: "205px",
                                // }}
                                // onClick={() =>
                                //   (window.location = "/project/" + item.id)
                                // }
                              >
                                <Avatar
                                  mt={-1}
                                  size="sm"
                                  bg="white"
                                  name={item.user.name}
                                  src={item.user.name}
                                  color="#2D3842"
                                  fontWeight="700"
                                  mr={2}
                                />
                                <Text
                                  isTruncated
                                  display={"inline"}
                                  // style={{
                                  //   minWidth: "205px",
                                  //   maxWidth: "205px",
                                  //   width: "205px",
                                  // }}
                                >
                                  {item.user.name}
                                </Text>
                                {/* <Tag
                              fontSize={"10px"}
                              bg="background.grey4"
                              color="#FFF"
                              size="sm"
                              borderRadius="full"
                              ml="2"
                            >
                              {item.total_pages_edited}
                            </Tag> */}
                              </Td>
                              <Td
                                color="white"
                                fontSize="14px"
                                textAlign="center"
                                // style={{
                                //   minWidth: "200px",
                                //   maxWidth: "200px",
                                //   width: "200px",
                                // }}
                                // onClick={() =>
                                //   (window.location = "/project/" + item.id)
                                // }
                              >
                                {item.active_days}
                              </Td>
                              <Td
                                color="white"
                                fontSize="14px"
                                textAlign="center"
                                // style={{
                                //   minWidth: "245px",
                                //   maxWidth: "245px",
                                //   width: "245px",
                                // }}
                                // onClick={() =>
                                //   (window.location = "/project/" + item.id)
                                // }
                              >
                                {/* {item.last_modified.split("T")[0]} |{" "}
                            {item.last_modified
                              .split("T")[1]
                              .split(".")[0]
                              .slice(0, 5)}{" "} */}
                                {item.total_pages_created}
                              </Td>
                              <Td
                                color="white"
                                fontSize="14px"
                                textAlign="center"
                                // style={{
                                //   minWidth: "240px",
                                //   maxWidth: "240px",
                                //   width: "240px",
                                // }}
                                // onClick={() =>
                                //   (window.location = "/project/" + item.id)
                                // }
                              >
                                {/* {item.project_owner.name?.length > 30
                              ? item.project_owner.name.slice(0, 30) + "..."
                              : item.project_owner.name} */}
                                {item.total_projects_created}
                              </Td>
                              {/* <Td
                            color="white"
                            fontSize="14px"
                            textAlign="center"
                           
                          >
                            {item.total_approvals}
                          </Td> */}
                              {/* <Td
                            color="white"
                            fontSize="14px"
                            width="225px"
                            // style={{
                            //   minWidth: "150px",
                            //   maxWidth: "150px",
                            //   width: "150px",
                            // }}
                            onClick={() =>
                              (window.location = "/project/" + item.id)
                            }
                          >
                            {item.industry}
                          </Td> */}
                            </Tr>
                          }
                        </>
                      ))}
                  </Tbody>
                </Table>
              </Center>
            </>
          )}
        </Flex>
      </Flex>

      <>
        <Modal bg="#444d54" isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay BG="#2A3843" />
          <ModalContent bg="#444d54">
            <ModalHeader color="white">Add a New Project</ModalHeader>
            <ModalBody>
              <Flex flexDir="column" mb="10px">
                <Text
                  color="white"
                  fontWeight="700"
                  fontSize="18px"
                  mb="5px"
                  mt="15px"
                >
                  Project Title
                </Text>
                <Input
                  bg="#525C64"
                  color="white"
                  fontWeight="700"
                  placeholder="Add Project Title"
                  maxlength={maxChar}
                  ref={title}
                  // enable this function to print the number of characters left
                  // onChange={charRem}
                ></Input>
                {/* This line is for printing the characters left */}
                {/* <Text color="white"float="right">{maxChar - charRemain} characters remaining</Text> */}
              </Flex>
              <Flex flexDir="column">
                <Text color="white" fontWeight="700" fontSize="18px" mb="5px">
                  Industry
                </Text>
                <Select
                  bg="#525C64"
                  color="white"
                  ref={industry}
                  fontWeight="700"
                  placeholder="Select Industry"
                >
                  <option value="Financial Services">Financial Services</option>
                  <option value="Healthcare">Healthcare</option>
                  <option value="Retail">Retail</option>
                  <option value="Technology">Technology</option>
                  <option value="Government">Government</option>
                  <option value="Legal">Legal</option>
                  <option value="Other">Other</option>
                </Select>
              </Flex>
              <Flex flexDir="column">
                <Text
                  color="white"
                  fontWeight="700"
                  fontSize="18px"
                  mb="5px"
                  mt="15px"
                >
                  Name of the Client
                </Text>
                <Input
                  bg="#525C64"
                  color="white"
                  fontWeight="700"
                  placeholder="Enter Client Name"
                  ref={client}
                ></Input>
              </Flex>
              <Flex flexDir="column">
                <Text
                  color="white"
                  fontWeight="700"
                  fontSize="18px"
                  mb="5px"
                  mt="15px"
                >
                  Project Code
                </Text>
                <Input
                  bg="#525C64"
                  color="white"
                  fontWeight="700"
                  placeholder="Add Project Code"
                  ref={code}
                ></Input>
              </Flex>
              <Flex flexDir="column">
                <Text color="white" fontWeight="700" fontSize="18px" mb="5px">
                  Visibility
                </Text>
                <Select
                  bg="#525C64"
                  color="white"
                  ref={visibility}
                  fontWeight="700"
                  placeholder="Choose Visibility"
                >
                  <option value="public">public</option>
                  <option value="private">private</option>
                </Select>
              </Flex>
            </ModalBody>

            <ModalFooter mt="10px">
              <Button
                bg="background.yellow"
                color="white"
                mr={3}
                onClick={addProjectHandler}
              >
                Add Project to Library
              </Button>
              <Button color="white" variant="ghost" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal bg="#444d54" isOpen={isOpen2} onClose={onClose2} isCentered>
          <ModalOverlay BG="#2A3843" />
          <ModalContent bg="#444d54">
            <ModalHeader color="white">Add a New Folder</ModalHeader>
            <ModalBody>
              <Flex flexDir="column" mb="10px">
                <Text
                  color="white"
                  fontWeight="700"
                  fontSize="18px"
                  mb="5px"
                  mt="15px"
                >
                  Folder Title
                </Text>
                <Input
                  bg="#525C64"
                  color="white"
                  fontWeight="700"
                  placeholder="Add Project Title"
                  maxlength={maxChar}
                  ref={title}
                  // enable this function to print the number of characters left
                  // onChange={charRem}
                ></Input>
                {/* This line is for printing the characters left */}
                {/* <Text color="white"float="right">{maxChar - charRemain} characters remaining</Text> */}
              </Flex>

              <Flex flexDir="column">
                <Text color="white" fontWeight="700" fontSize="18px" mb="5px">
                  Industry
                </Text>
                <Select
                  bg="#525C64"
                  color="white"
                  ref={industry}
                  fontWeight="700"
                  placeholder="Select Industry"
                >
                  <option value="Financial Services">Financial Services</option>
                  <option value="Healthcare">Healthcare</option>
                  <option value="Retail">Retail</option>
                  <option value="Technology">Technology</option>
                  <option value="Government">Government</option>
                  <option value="Legal">Legal</option>
                  <option value="Other">Other</option>
                </Select>
              </Flex>
              <Flex flexDir="column">
                <Text
                  color="white"
                  fontWeight="700"
                  fontSize="18px"
                  mb="5px"
                  mt="15px"
                >
                  Name of the Client
                </Text>
                <Input
                  bg="#525C64"
                  color="white"
                  fontWeight="700"
                  placeholder="Enter Client Name"
                  ref={client}
                ></Input>
              </Flex>
            </ModalBody>

            <ModalFooter mt="10px">
              <Button
                bg="yellow"
                color="white"
                mr={3}
                onClick={addFolderHandler}
              >
                Add Folder to Library
              </Button>
              <Button color="white" variant="ghost" onClick={onClose2}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </Flex>
  );
};

export default LeaderBoard;
