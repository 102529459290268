import { useState, useEffect, useRef, useContext, useMemo } from "react";
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Select,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Spinner,
  Tag,
  Icon,
  Box,
  SimpleGrid,
  Heading,
  useColorModeValue,
  Stack,
  Avatar,
} from "@chakra-ui/react";
import { ChevronDownIcon, SearchIcon, StarIcon } from "@chakra-ui/icons";

import { useParams } from "react-router-dom";

import AuthContext from "../store/auth";
import swal from "sweetalert";
import { BsStar, BsStarFill, BsStarHalf, BsTrash } from "react-icons/bs";
import Pagination from "../components/Pagination";
import Rating from "../components/Rating";
import { useHotkeys } from "react-hotkeys-hook";
import Breadcrumbs from "../components/BreadCrumbs";
import { useToast } from "@chakra-ui/react";
import { Url } from '../store/global';

const Templates = () => {
  // authentication module
  const toast = useToast();
  const authCtx = useContext(AuthContext);

  // filter state
  let { projectSlug } = useParams();
  const [activeFilter, setActiveFilter] = useState(
    window.localStorage.getItem("activeFilter")
      ? projectSlug
        ? projectSlug
        : window.localStorage.getItem("activeFilter")
      : "all"
  );

  // new template modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const title = useRef();
  const [isClient, setIsClient] = useState(true);
  const industry = useRef();
  const client = useRef();
  const visibility = useRef();
  const code = useRef();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  // template list state
  const [templateInfo, setTemplateInfo] = useState(null);
  const [templateInfo2, setTemplateInfo2] = useState(null);

  const logoutHandler = () => {
    authCtx.logout();
  };

  const [bot, setBot] = useState(
    JSON.parse(window.localStorage.getItem("curr"))
      ? JSON.parse(window.localStorage.getItem("curr"))
      : 0
  );
  const [curr, setCurr] = useState(0);

  const [search, setSearch] = useState("");

  // const [currentPage, setCurrentPage] = useState(1);
  // // const [currentLength, setCurrentLength] = useState(templateInfo?templateInfo.length:0);
  // const currentLength = useRef(templateInfo ? templateInfo.length : 0);

  // useEffect(() => {
  //   setCurrentPage(1);
  // }, [search, activeFilter]);

  // useEffect(() => {
  //   if (templateInfo) currentLength.current = templateInfo.length;
  // }, [templateInfo]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  // POST request to create a new template
  const addProjectHandler = () => {
    // onClose();

    const rtitle = title.current.value;
    const rindustry = industry.current.value;
    const rclient = client.current.value;
    const rcode = code.current.value;
    const rvisibility = visibility.current.value;

    if (rtitle && rindustry && rclient && rcode) {
      fetch({Url}.Url+"/create_project/", {
        method: "POST",
        body: JSON.stringify({
          title: rtitle,
          industry: rindustry,
          client_name: rclient,
          project_code: rcode,
          visibility: rvisibility,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            if (res.status < 300) {
              // console.log(res.data);
              toast({
                title: "Project created successfully!",
                description: "Let's visit the new template page.",
                status: "success",
                duration: 2000,
                isClosable: true,
                onCloseComplete: () => {
                  window.location = "/template/" + res.data.project_id;
                },
              });
            } else {
              // console.log(res.data);
              toast({
                title: "Project created failed.",
                description: res.data.detail,
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          })
      );
    }
  };

  // Create Folder Handler
  const addFolderHandler = () => {
    // onClose();

    const rtitle = title.current.value;
    const rindustry = industry.current.value;
    const rclient = client.current.value;
    // const rcode = code.current.value;
    // const rvisibility = visibility.current.value;

    // if (rtitle && rindustry && rclient && rcode) {
    if (rtitle) {
      fetch({Url}.Url+"/create_folder/", {
        method: "POST",
        body: JSON.stringify({
          title: rtitle,
          industry: rindustry,
          client_name: rclient,
          // project_code: rcode,
          // visibility: rvisibility,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            if (res.status < 300) {
              // console.log(res.data);
              toast({
                title: "Project created successfully!.",
                description: "Let's visit the new template page.",
                status: "success",
                duration: 2000,
                isClosable: true,
                onCloseComplete: () => {
                  window.location = "/folder/" + res.data.id;
                },
              });
            } else {
              // console.log(res.data);
              toast({
                title: "Project creation failed.",
                description: res.data.detail,
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          })
      );
    }
  };

  // API GET request to query all the projects
  const getTemplates = () => {
    fetch({Url}.Url+"/templates/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            // console.log(res.data);
            setTemplateInfo(res.data);
            setTemplateInfo2(res.data);

            // console.log(direction);
          } else {
            toast({
              title: "Oops, something went wrong!",
              description: res.data.detail,
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        })
    );
  };

  function searchValid(template) {
    return (
      template.title.toLowerCase().includes(search.toLowerCase()) ||
      template.project_owner.name.toLowerCase().includes(search.toLowerCase()) ||
      template.project_code.toLowerCase().includes(search.toLowerCase()) ||
      template.client_name.toLowerCase().includes(search.toLowerCase()) ||
      template.industry.toLowerCase().includes(search.toLowerCase())
    );
  }

  // runs on initial page load
  useEffect(() => {
    getTemplates();
    setIsClient(authCtx.role === "Client");
    // projectSlug && setActiveFilter(projectSlug) ;

    // console.log(currentPage)
    setCurr(JSON.parse(window.localStorage.getItem("curr")));
    // eslint-disable-next-line
  }, []);

  const [first, setFirst] = useState(0);

  const [direction, setDirection] = useState(
    JSON.parse(window.localStorage.getItem("direction"))
      ? -1 * JSON.parse(window.localStorage.getItem("direction"))
      : 1
  );

  useEffect(() => {
    // setDirection(-1*direction);
    // console.log(curr);
    window.localStorage.setItem("curr", curr);
    window.localStorage.setItem("direction", direction);

    // eslint-disable-next-line
  }, [bot]);

  // setting filters to local storage
  useEffect(() => {
    activeFilter && window.localStorage.setItem("activeFilter", activeFilter);
    // console.log(activeFilter);
  }, [activeFilter]);

  // const [charRemain, setCharRemain] = useState(30);
  const maxChar = 40;

  // useEffect(() => {
  //   setCharRemain( maxChar - title.current.value.length )
  // }, [title.current.value]);

  // // Save to local storage to persist state on reload
  // useEffect(() => {
  //   setDirection(JSON.parse(window.localStorage.getItem('direction')));
  //   setCurr(JSON.parse(window.localStorage.getItem('curr')));
  // }, []);

  // useEffect(() => {
  //   window.localStorage.setItem('direction', direction);
  //   window.localStorage.setItem('curr', curr);
  // }, [direction,curr]);

  // HTML element tree ̦— render function

  // shortcuts
  useHotkeys("alt+x", () => (window.location = "/library"));
  useHotkeys("option+x", () => (window.location = "/library"));

  const breadcrumbs = [{ path: "/", breadcrumb: "Home", end: true }];

  return (
    <Flex
      backgroundColor="bg"
      flexDir="column"
      alignItems="center"
      minHeight="100vh"
      height="fit-content"
      width="100vw"
    >
      <Flex
        width="95%"
        height="fit-content"
        flexDir="column"
        justifyContent="space-between"
        px="10px"
        mb="20px"
      >
        <Flex color="white" fontSize="20px" fontWeight="400">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Flex>
        {/* <InputGroup width="80%" m="auto">
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.300" />}
          />
          <Input
            onChange={handleSearchChange}
            placeholder="Search projects by title, author, client, category, or template code"
            color="white"
          />
        </InputGroup> */}
        <Text fontSize={"2xl"} color={"gray.400"}>
          Here are some of our templates to help begin your project -
        </Text>

        <Flex
          justify="center"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(25rem, 1fr))",
            gridGap: "1rem",
          }}
          mt="5"
          mb={"100px"}
          cursor="pointer"
        >
          {templateInfo ? (
            templateInfo.map((template, id) => (
              <Box
                maxW={"440px"}
                w={"full"}
                boxShadow={"xl"}
                rounded={"md"}
                p={10}
                my={3}
                key={id}
                overflow={"hidden"}
                onClick={() => (window.location = "/template/" + template.id)}
              >
                <Stack>
                  <Heading
                    fontSize={"2xl"}
                    color={"main.text"}
                    pb={3}
                    fontFamily={"fonts.body"}
                  >
                    {template.title}
                  </Heading>
                  <Flex direction={"row"} justifyContent={"space-between"}>
                    <Text
                      color={"gray.400"}
                      textTransform={"uppercase"}
                      fontWeight={800}
                      fontSize={"sm"}
                      letterSpacing={1.1}
                    >
                      {template.industry}
                    </Text>
                    <Text color={"gray.400"} fontSize="lg">
                      <Rating
                        rating={template.rating}
                        displayReviewCount={false}
                      />
                    </Text>
                  </Flex>

                  <Text color={"gray.500"} mb={4}>
                    {template.description
                      ? template.description
                      : "No description"}
                  </Text>
                </Stack>
                <Stack mt={6} direction={"row"} spacing={4} align={"center"}>
                  <Avatar
                    name={template.project_owner.name}
                    bg={"gray.100"}
                    color={"gray.700"}
                    alt={"Author"}
                  />
                  <Stack direction={"column"} spacing={0} fontSize={"sm"}>
                    <Text fontWeight={600} color="gray.400">
                      {template.project_owner.name}
                    </Text>
                    <Text color={"gray.500"}>
                      Created on: {template.created_on.split("T")[0]} |{" "}
                      {template.created_on
                        .split("T")[1]
                        .split(".")[0]
                        .slice(0, 5)}{" "}
                    </Text>
                  </Stack>
                </Stack>
              </Box>
            ))
          ) : (
            <Spinner />
          )}
        </Flex>
      </Flex>

      <Flex
        height="fit-content"
        flexDir="row"
        justifyContent="space-between"
        p="20px"
        // mt="10px"
        width="95%"
        // mb="30px"
        pos="fixed"
        bottom="0"
        zIndex="1"
        bg="#2A3843"
      >
        <Flex>
          <Button
            bg="background.yellow1"
            width="250px"
            p="2px"
            mb={["20px", "20px", "20px", "0px", "0px"]}
            color="white"
            fontSize="16px"
            as="a"
            href="/dashboard"
            _hover={{
              background: "#F2DBA6",
            }}
            mr="10px"
          >
            Return to Dashboard
          </Button>
          {/*
          <Button
            bg="#444d54"
            width="140px"
            p="2px"
            mb={["20px", "20px", "20px", "0px", "0px"]}
            color="white"
            fontSize="16px"
            onClick={() => openInNewTab("/trash-projects")}
            _hover={{
              background: "#F2DBA6",
            }}
            mr="10px"
          >
            Trash
            <Icon as={BsTrash} color="white" ml="10px" />
          </Button> */}
        </Flex>
        {!isClient && (
          <Flex>
            <Button
              bg="#444d54"
              width="250px"
              p="2px"
              mb={["20px", "20px", "20px", "0px", "0px"]}
              color="white"
              fontSize="16px"
              onClick={onOpen}
              _hover={{
                background: "#F2DBA6",
              }}
              mr="10px"
            >
              Click to add a New Project
            </Button>
          </Flex>
        )}
      </Flex>
      <>
        <Modal bg="#444d54" isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay BG="#2A3843" />
          <ModalContent bg="#444d54">
            <ModalHeader color="white">Add a New Project</ModalHeader>
            <ModalBody>
              <Flex flexDir="column" mb="10px">
                <Text
                  color="white"
                  fontWeight="700"
                  fontSize="18px"
                  mb="5px"
                  mt="15px"
                >
                  Project Title
                </Text>
                <Input
                  bg="#525C64"
                  color="white"
                  fontWeight="700"
                  placeholder="Add Project Title"
                  maxlength={maxChar}
                  ref={title}
                  // enable this function to print the number of characters left
                  // onChange={charRem}
                ></Input>
                {/* This line is for printing the characters left */}
                {/* <Text color="white"float="right">{maxChar - charRemain} characters remaining</Text> */}
              </Flex>
              <Flex flexDir="column">
                <Text color="white" fontWeight="700" fontSize="18px" mb="5px">
                  Industry
                </Text>
                <Select
                  bg="#525C64"
                  color="white"
                  ref={industry}
                  fontWeight="700"
                  placeholder="Select Industry"
                >
                  <option value="Financial Services">Financial Services</option>
                  <option value="Healthcare">Healthcare</option>
                  <option value="Retail">Retail</option>
                  <option value="Technology">Technology</option>
                  <option value="Government">Government</option>
                  <option value="Legal">Legal</option>
                  <option value="Other">Other</option>
                </Select>
              </Flex>
              <Flex flexDir="column">
                <Text
                  color="white"
                  fontWeight="700"
                  fontSize="18px"
                  mb="5px"
                  mt="15px"
                >
                  Name of the Client
                </Text>
                <Input
                  bg="#525C64"
                  color="white"
                  fontWeight="700"
                  placeholder="Enter Client Name"
                  ref={client}
                ></Input>
              </Flex>
              <Flex flexDir="column">
                <Text
                  color="white"
                  fontWeight="700"
                  fontSize="18px"
                  mb="5px"
                  mt="15px"
                >
                  Project Code
                </Text>
                <Input
                  bg="#525C64"
                  color="white"
                  fontWeight="700"
                  placeholder="Add Project Code"
                  ref={code}
                ></Input>
              </Flex>
              <Flex flexDir="column">
                <Text color="white" fontWeight="700" fontSize="18px" mb="5px">
                  Visibility
                </Text>
                <Select
                  bg="#525C64"
                  color="white"
                  ref={visibility}
                  fontWeight="700"
                  placeholder="Choose Visibility"
                >
                  <option value="public">public</option>
                  <option value="private">private</option>
                </Select>
              </Flex>
            </ModalBody>

            <ModalFooter mt="10px">
              <Button
                bg="background.yellow"
                color="white"
                mr={3}
                onClick={addProjectHandler}
              >
                Add Project to Library
              </Button>
              <Button color="white" variant="ghost" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal bg="#444d54" isOpen={isOpen2} onClose={onClose2} isCentered>
          <ModalOverlay BG="#2A3843" />
          <ModalContent bg="#444d54">
            <ModalHeader color="white">Add a New Folder</ModalHeader>
            <ModalBody>
              <Flex flexDir="column" mb="10px">
                <Text
                  color="white"
                  fontWeight="700"
                  fontSize="18px"
                  mb="5px"
                  mt="15px"
                >
                  Folder Title
                </Text>
                <Input
                  bg="#525C64"
                  color="white"
                  fontWeight="700"
                  placeholder="Add Project Title"
                  maxlength={maxChar}
                  ref={title}
                  // enable this function to print the number of characters left
                  // onChange={charRem}
                ></Input>
                {/* This line is for printing the characters left */}
                {/* <Text color="white"float="right">{maxChar - charRemain} characters remaining</Text> */}
              </Flex>

              <Flex flexDir="column">
                <Text color="white" fontWeight="700" fontSize="18px" mb="5px">
                  Industry
                </Text>
                <Select
                  bg="#525C64"
                  color="white"
                  ref={industry}
                  fontWeight="700"
                  placeholder="Select Industry"
                >
                  <option value="Financial Services">Financial Services</option>
                  <option value="Healthcare">Healthcare</option>
                  <option value="Retail">Retail</option>
                  <option value="Technology">Technology</option>
                  <option value="Government">Government</option>
                  <option value="Legal">Legal</option>
                  <option value="Other">Other</option>
                </Select>
              </Flex>
              <Flex flexDir="column">
                <Text
                  color="white"
                  fontWeight="700"
                  fontSize="18px"
                  mb="5px"
                  mt="15px"
                >
                  Name of the Client
                </Text>
                <Input
                  bg="#525C64"
                  color="white"
                  fontWeight="700"
                  placeholder="Enter Client Name"
                  ref={client}
                ></Input>
              </Flex>
            </ModalBody>

            <ModalFooter mt="10px">
              <Button
                bg="yellow"
                color="white"
                mr={3}
                onClick={addFolderHandler}
              >
                Add Folder to Library
              </Button>
              <Button color="white" variant="ghost" onClick={onClose2}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </Flex>
  );
};

export default Templates;
