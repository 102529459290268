import { Heading } from "@chakra-ui/react";

const Error = () => {
  return (
    <Heading as="h4" size="md">
      This is a 404 Error Page.
    </Heading>
  );
};

export default Error;
