import {
  Flex,
  Heading,
  Image,
  FormControl,
  FormLabel,
  Input,
  Text,
  Button,
} from "@chakra-ui/react";
import { useRef } from "react";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { Url, UrlBase } from '../../store/global';

import onboardingImage from "../../resources/imgs/onboarding.png";
import beenextLogo from "../../resources/imgs/beenextLogo.png";

const ForgotConfirm = () => {
  const toast = useToast();
  const p2ref = useRef();
  const p3ref = useRef();

  let { uid, token } = useParams();

  function forgorPassword() {
    const p2 = p2ref.current.value;
    const p3 = p3ref.current.value;
    console.log({Url}.UrlBase)

    fetch({UrlBase}.UrlBase+"/rest-auth/password/reset/confirm/", {
      method: "POST",
      body: JSON.stringify({
        uid: uid,
        token: token,
        new_password1: p2,
        new_password2: p3,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            toast({
              title: "Password changed successfully!",
              description: "You may now login with these details!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
          } else {
            toast({
              title: "Password change failed.",
              description: res.data.detail,
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
          }
        })
    );
  }

  return (
    <div>
      <Flex
        flexDir={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
        ]}
        width="100vw"
        height={["100%", "100%", "100%", "100vh", "100vh"]}
        overflow="hidden"
        alignItems="center"
        background="bg"
      >
        <Flex
          flexDir="column"
          width={["95vw", "95vw", "95vw", "60vw", "60vw"]}
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Flex
            flexDir="column"
            width={["90%", "90%", "85%", "85%", "85%"]}
            mt={["50px", "50px", "50px", "0px", "0px"]}
            mb={["50px", "50px", "50px", "0px", "0px"]}
          >
            <Image
              src={beenextLogo}
              height="120px"
              width="200px"
              objectFit="contain"
            />

            <Heading fontSize="28px" color="white" fontFamily="fonts.primary">
              Forgot Password
            </Heading>

            <Text
              fontSize="18px"
              color="grey1"
              fontFamily="fonts.primary"
              mt="0px"
              fontWeight="700"
            >
              Need to change your password? Add the details here.
            </Text>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                forgorPassword();
              }}
            >
              <FormControl mt="20px">
                <FormLabel color="grey1" fontWeight="700" htmlFor="email">
                  Enter new password
                </FormLabel>
                <Input
                  id="email"
                  bg="grey3"
                  color="white"
                  fontWeight="600"
                  focusBorderColor="white"
                  border="none"
                  size="md"
                  type="password"
                  placeholder="Enter new password here"
                  autocomplete="off"
                  ref={p2ref}
                />
              </FormControl>

              <FormControl mt="20px">
                <FormLabel color="grey1" fontWeight="700" htmlFor="email">
                  Confirm new password
                </FormLabel>
                <Input
                  id="email"
                  bg="grey3"
                  color="white"
                  fontWeight="600"
                  focusBorderColor="white"
                  border="none"
                  size="md"
                  type="password"
                  placeholder="Confirm new password here"
                  autocomplete="off"
                  ref={p3ref}
                />

                <Flex flexDir="row" mt="30px" flexWrap="wrap">
                  <Button
                    bg="yellow"
                    width="250px"
                    p="2px"
                    mt={["0px", "0px", "0px", "60px", "60px"]}
                    mb={["20px", "20px", "20px", "0px", "0px"]}
                    color="white"
                    fontSize="16px"
                    onClick={forgorPassword}
                    _hover={{
                      background: "#F2DBA6",
                    }}
                    mr="10px"
                    type="submit"
                  >
                    Set my new password
                  </Button>

                  <Button
                    variant="outline"
                    colorScheme="gray"
                    color="white"
                    width="150px"
                    p="2px"
                    mt={["0px", "0px", "0px", "60px", "60px"]}
                    mb={["20px", "20px", "20px", "0px", "0px"]}
                    fontSize="16px"
                    as="a"
                    href="/"
                    _hover={{
                      background: "grey2",
                    }}
                  >
                    Back to Login
                  </Button>
                </Flex>
              </FormControl>
            </form>
          </Flex>
        </Flex>

        <Flex
          flexDir="column"
          width={["100vw", "100vw", "100vw", "45vw", "45vw"]}
          height="100%"
        >
          <Image
            src={onboardingImage}
            objectFit="cover"
            height={["200px", "200px", "200px", "100%", "100%"]}
            width="100%"
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default ForgotConfirm;
