import { extendTheme } from "@chakra-ui/react";

const theme = {
  colors: {
    yellow: "#FFC949",
    yellow1: "#C19630",
    white: "#FFFFFF",
    black: "#000000",
    bg: "#2D3842",
    grey0: "#3D454C",
    grey2: "#A6A6A6",
    grey1: "#A0AEC0",
    grey3: "#515d69",
  },
  fonts: {
    primary: "europa",
    secondary: "europa",
    heading: "europa",
    body: "europa",
  },
  config: {
    useSystemColorMode: false,
  },
  styles: {
    global: {
      h1: {
        fontSize: "3xl",
        fontWeight: "bold",
      },
      h2: {
        fontSize: "2xl",
        fontWeight: "bold",
      },
      h3: {
        fontSize: "xl",
        fontWeight: "bold",
      },
      h4: {
        fontSize: "lg",
        fontWeight: "bold",
      },
    },
  },
};

export default extendTheme(theme);
