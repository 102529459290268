import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { AuthContextProvider } from "./store/auth";
import { createRoot } from "react-dom/client";
import Hotjar from "@hotjar/browser";
import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'


import App from "./App";
import theme from "./resources/theme.js";

import "./index.css";
TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)
const siteId = 3533519;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ChakraProvider>
    </AuthContextProvider>
  </React.StrictMode>
);
