import { Text, Flex, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { BsHouse } from "react-icons/bs";

const Breadcrumbs = ({ breadcrumbs }) => {
  const [mobileSc] = useMediaQuery("(max-width: 768px)");
  return (
    <Flex my={2}>
      {breadcrumbs?.map(
        (breadcrumb) =>
          breadcrumb.path && (
            <span key={breadcrumb?.path}>
              <Text
                display={"inline-block"}
                onClick={() => (window.location = breadcrumb?.path)}
                color={breadcrumb?.end == true ? "main.text" : "white"}
                fontSize={{ base: "0.95rem", md: "20px" }}
                fontWeight="400"
                cursor="pointer"
              >
                {" "}
                {breadcrumb?.end === true ? (
                  <Text
                    // textDecoration={"underline"}
                    display={"inline"}
                    borderBottom={
                      breadcrumb?.end === true
                        ? "2px solid #FFC949"
                        : "2px solid white"
                    }
                  >
                    {breadcrumb?.breadcrumb === "Home" ? (
                      mobileSc ? (
                        <>
                          <svg
                            width="16"
                            height="15"
                            viewBox="0 0 25 23"
                            style={{ display: "inline" }}
                            xmlns="http://www.w3.org/2000/svg"
                            fill={"#FFC949"}
                          >
                            <path d="M23.9558 11.3148L13.7675 1.1318L13.0846 0.448891C12.929 0.294318 12.7186 0.207565 12.4992 0.207565C12.2799 0.207565 12.0695 0.294318 11.9139 0.448891L1.04269 11.3148C0.883248 11.4736 0.75724 11.6628 0.672102 11.8711C0.586964 12.0794 0.544421 12.3027 0.546984 12.5277C0.557531 13.4558 1.33009 14.1967 2.25821 14.1967H3.37882V22.7845H21.6196V14.1967H22.764C23.2149 14.1967 23.6394 14.0201 23.9584 13.701C24.1155 13.5444 24.24 13.3582 24.3246 13.1532C24.4092 12.9482 24.4524 12.7284 24.4515 12.5066C24.4515 12.0584 24.2748 11.6339 23.9558 11.3148ZM13.9758 20.8861H11.0227V15.5072H13.9758V20.8861ZM19.7212 12.2983V20.8861H15.6633V14.8744C15.6633 14.2917 15.1913 13.8197 14.6086 13.8197H10.3899C9.80714 13.8197 9.33517 14.2917 9.33517 14.8744V20.8861H5.27726V12.2983H2.74601L12.5019 2.55036L13.1109 3.15944L22.2551 12.2983H19.7212Z" />
                          </svg>
                          &nbsp;
                        </>
                      ) : (
                        <>
                          <svg
                            width="20"
                            height="18"
                            viewBox="0 0 25 23"
                            style={{ display: "inline" }}
                            xmlns="http://www.w3.org/2000/svg"
                            fill={"#FFC949"}
                          >
                            <path d="M23.9558 11.3148L13.7675 1.1318L13.0846 0.448891C12.929 0.294318 12.7186 0.207565 12.4992 0.207565C12.2799 0.207565 12.0695 0.294318 11.9139 0.448891L1.04269 11.3148C0.883248 11.4736 0.75724 11.6628 0.672102 11.8711C0.586964 12.0794 0.544421 12.3027 0.546984 12.5277C0.557531 13.4558 1.33009 14.1967 2.25821 14.1967H3.37882V22.7845H21.6196V14.1967H22.764C23.2149 14.1967 23.6394 14.0201 23.9584 13.701C24.1155 13.5444 24.24 13.3582 24.3246 13.1532C24.4092 12.9482 24.4524 12.7284 24.4515 12.5066C24.4515 12.0584 24.2748 11.6339 23.9558 11.3148ZM13.9758 20.8861H11.0227V15.5072H13.9758V20.8861ZM19.7212 12.2983V20.8861H15.6633V14.8744C15.6633 14.2917 15.1913 13.8197 14.6086 13.8197H10.3899C9.80714 13.8197 9.33517 14.2917 9.33517 14.8744V20.8861H5.27726V12.2983H2.74601L12.5019 2.55036L13.1109 3.15944L22.2551 12.2983H19.7212Z" />
                          </svg>
                          &nbsp;
                        </>
                      )
                    ) : (
                      ""
                    )}
                    {breadcrumb.breadcrumb}
                  </Text>
                ) : (
                  <>
                    <Text
                      style={{ display: "inline" }}
                      _hover={{
                        borderBottom:
                          breadcrumb?.end === true
                            ? "2px solid #FFC949"
                            : "2px solid white",
                      }}
                    >
                      {breadcrumb?.breadcrumb === "Home" ? (
                        mobileSc ? (
                          <>
                            <svg
                              width="16"
                              height="15"
                              viewBox="0 0 25 23"
                              style={{ display: "inline" }}
                              xmlns="http://www.w3.org/2000/svg"
                              fill={"white"}
                            >
                              <path d="M23.9558 11.3148L13.7675 1.1318L13.0846 0.448891C12.929 0.294318 12.7186 0.207565 12.4992 0.207565C12.2799 0.207565 12.0695 0.294318 11.9139 0.448891L1.04269 11.3148C0.883248 11.4736 0.75724 11.6628 0.672102 11.8711C0.586964 12.0794 0.544421 12.3027 0.546984 12.5277C0.557531 13.4558 1.33009 14.1967 2.25821 14.1967H3.37882V22.7845H21.6196V14.1967H22.764C23.2149 14.1967 23.6394 14.0201 23.9584 13.701C24.1155 13.5444 24.24 13.3582 24.3246 13.1532C24.4092 12.9482 24.4524 12.7284 24.4515 12.5066C24.4515 12.0584 24.2748 11.6339 23.9558 11.3148ZM13.9758 20.8861H11.0227V15.5072H13.9758V20.8861ZM19.7212 12.2983V20.8861H15.6633V14.8744C15.6633 14.2917 15.1913 13.8197 14.6086 13.8197H10.3899C9.80714 13.8197 9.33517 14.2917 9.33517 14.8744V20.8861H5.27726V12.2983H2.74601L12.5019 2.55036L13.1109 3.15944L22.2551 12.2983H19.7212Z" />
                            </svg>
                            &nbsp;
                          </>
                        ) : (
                          <>
                            <svg
                              width="20"
                              height="18"
                              viewBox="0 0 25 23"
                              style={{ display: "inline" }}
                              xmlns="http://www.w3.org/2000/svg"
                              fill={"white"}
                            >
                              <path d="M23.9558 11.3148L13.7675 1.1318L13.0846 0.448891C12.929 0.294318 12.7186 0.207565 12.4992 0.207565C12.2799 0.207565 12.0695 0.294318 11.9139 0.448891L1.04269 11.3148C0.883248 11.4736 0.75724 11.6628 0.672102 11.8711C0.586964 12.0794 0.544421 12.3027 0.546984 12.5277C0.557531 13.4558 1.33009 14.1967 2.25821 14.1967H3.37882V22.7845H21.6196V14.1967H22.764C23.2149 14.1967 23.6394 14.0201 23.9584 13.701C24.1155 13.5444 24.24 13.3582 24.3246 13.1532C24.4092 12.9482 24.4524 12.7284 24.4515 12.5066C24.4515 12.0584 24.2748 11.6339 23.9558 11.3148ZM13.9758 20.8861H11.0227V15.5072H13.9758V20.8861ZM19.7212 12.2983V20.8861H15.6633V14.8744C15.6633 14.2917 15.1913 13.8197 14.6086 13.8197H10.3899C9.80714 13.8197 9.33517 14.2917 9.33517 14.8744V20.8861H5.27726V12.2983H2.74601L12.5019 2.55036L13.1109 3.15944L22.2551 12.2983H19.7212Z" />
                            </svg>
                            &nbsp;
                          </>
                        )
                      ) : (
                        ""
                      )}{" "}
                      {breadcrumb.breadcrumb}
                    </Text>
                    {mobileSc ? (
                      <span>&nbsp;/&nbsp;</span>
                    ) : (
                      <span>&nbsp;/&nbsp;&nbsp;</span>
                    )}
                  </>
                )}
              </Text>
            </span>
          )
      )}
    </Flex>
  );
};

export default Breadcrumbs;
