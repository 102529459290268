import { Flex, Heading, Text } from "@chakra-ui/react";
import Breadcrumbs from "../components/BreadCrumbs";

const Privacy = () => {
  const breadcrumbs = [{ path: "/", breadcrumb: "Home", end: true }];

  return (
    <Flex
      width="100vw"
      minHeight="100vh"
      alignItems="center"
      bg="bg"
      flexDir="column"
    >
      <Flex flexDir="column" width="75vw" margin="auto" mx="50px">
        <Flex color="white" fontSize="20px" fontWeight="400" pb={2}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Flex>
        <Heading color="white">Privacy Policy</Heading>
        <Text color="white" mt="20px" mb="20px">
          Amet deserunt elit adipisicing. Consectetur anim eiusmod dolore ut
          dolore ad dolor ut exercitation elit laborum. Est sit culpa aliquip
          minim pariatur incididunt sit incididunt Lorem. Aliquip laboris
          voluptate culpa sunt dolore qui nulla aute reprehenderit aliqua. Anim
          Lorem proident mollit sit nulla laborum ullamco sunt id cillum
          incididunt. Culpa ea esse enim. Nisi commodo mollit enim ex deserunt
          dolor id proident excepteur aliquip. Velit in exercitation aliquip.
          Esse laborum fugiat ullamco non ex eiusmod. Fugiat et aliquip
          adipisicing et eu ullamco voluptate.
        </Text>

        <Text color="white" mt="20px" mb="20px">
          Amet deserunt elit adipisicing. Consectetur anim eiusmod dolore ut
          dolore ad dolor ut exercitation elit laborum. Est sit culpa aliquip
          minim pariatur incididunt sit incididunt Lorem. Aliquip laboris
          voluptate culpa sunt dolore qui nulla aute reprehenderit aliqua. Anim
          Lorem proident mollit sit nulla laborum ullamco sunt id cillum
          incididunt. Culpa ea esse enim. Nisi commodo mollit enim ex deserunt
          dolor id proident excepteur aliquip. Velit in exercitation aliquip.
          Esse laborum fugiat ullamco non ex eiusmod. Fugiat et aliquip
          adipisicing et eu ullamco voluptate.
        </Text>

        <Text color="white" mt="20px" mb="20px">
          Amet deserunt elit adipisicing. Consectetur anim eiusmod dolore ut
          dolore ad dolor ut exercitation elit laborum. Est sit culpa aliquip
          minim pariatur incididunt sit incididunt Lorem. Aliquip laboris
          voluptate culpa sunt dolore qui nulla aute reprehenderit aliqua. Anim
          Lorem proident mollit sit nulla laborum ullamco sunt id cillum
          incididunt. Culpa ea esse enim. Nisi commodo mollit enim ex deserunt
          dolor id proident excepteur aliquip. Velit in exercitation aliquip.
          Esse laborum fugiat ullamco non ex eiusmod. Fugiat et aliquip
          adipisicing et eu ullamco voluptate.
        </Text>
      </Flex>
    </Flex>
  );
};

export default Privacy;
