// react imports
import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Flex } from "@chakra-ui/react";

// screen imports
import Dashboard from "./screens/Dashboard";
import Login from "./screens/Onboarding/Login";
import Error from "./screens/Error";
import ForgotPassword from "./screens/Onboarding/ForgotPassword";
import Register from "./screens/Onboarding/Register";

// resource imports
import "./index.css";
import { Url, themeDefault, namesOfModes } from './store/global';
import { Workbook } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css"
import AuthContext from "./store/auth";
import Onboarding from "./screens/Onboarding/Onboarding";
import HelpDesk from "./screens/HelpDesk";
import Library from "./screens/Library";
import Project from "./screens/Project";
import NewPage from "./screens/Pages/NewPage";
import Page from "./screens/Pages/Page";
import LinkChild from "./screens/Pages/LinkChild";
import LinkParent from "./screens/Pages/LinkParent";
import Approval from "./screens/Approval";
import EditPage from "./screens/Pages/EditPage";
import Terms from "./screens/Terms";
import Privacy from "./screens/Privacy";
import ResetPassword from "./screens/Onboarding/ResetPassword";
import ForgotConfirm from "./screens/Onboarding/ForgotConfirm";
import TrashProjects from "./screens/Pages/TrashProjects";
import TrashBlocks from "./screens/Pages/TrashBlocks";
import Folder from "./screens/Pages/Folder";
import Templates from "./screens/Templates";
import LeaderBoard from "./screens/LeaderBoard";
import Template from "./screens/Template";
import Shortcuts from "./screens/Pages/Shortcuts";
import { useHotkeys } from "react-hotkeys-hook";
import { Navbar } from "./components/Navbar";
import Notifications from "./screens/Pages/Notifications";
import GoogleLoginRedirect from "./screens/Onboarding/GoogleLoginRedirect";
import theme from './store/theme.js';
import { ChakraProvider } from '@chakra-ui/react'


const App = () => {
  const authCtx = useContext(AuthContext);
  const [projectSlug, setProjectSlug] = React.useState(null);
  const loginStatus = authCtx.isLoggedIn;
  useHotkeys("alt+l", (e) => {
    e.preventDefault();
    window.location.pathname !== "/library" &&
      (window.location.href = "/library");
  });

  useHotkeys("option+l", (e) => {
    e.preventDefault();
    window.location.pathname !== "/library" &&
      (window.location.href = "/library");
  });
  useHotkeys("alt+h", (e) => {
    e.preventDefault();
    window.location.pathname !== "/dashboard" &&
      (window.location.href = "/dashboard");
  });
  useHotkeys("option+h", (e) => {
    e.preventDefault();
    window.location.pathname !== "/dashboard" &&
      (window.location.href = "/dashboard");
  });

  const fetchPageSlug = (pageId) => {
    fetch(
      "https://fj.orangeyak.xyz/api/core/get_project_by_page/" + pageId + "/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            // console.log(res.data)
            setProjectSlug(res.data.project_id);
          } else {
            // console.log(res.detail.error)
          }
        })
    );
  };
  const getProjectSlug = () => {
    const path = window?.location?.pathname?.split("/")[1];
    const path2 = window?.location?.pathname?.split("/")[2];
    if (path === "project") {
      setProjectSlug(path2);
    } else if (
      path === "page" ||
      path === "approval" ||
      path === "edit-page" ||
      path === "new-page"
    ) {
      fetchPageSlug(path2);
    }
  };

  React.useEffect(() => {
    getProjectSlug();
  }, [window?.location?.pathname]);

  return (
    <ChakraProvider theme={theme}>
    <div
      style={{
        backgroundColor: "#2D3842",
        minHeight: "100vh",
        paddingBottom: "1rem",
      }}
    >
      {loginStatus && <Navbar projectSlug={projectSlug} />}

      <Flex pt={loginStatus ? "55px" : "0"}>
        <Routes>
          {/* DEFAULT INDEX ROUTE */}
          <Route index element={loginStatus ? <Dashboard /> : <Login />} />

          <Route
            path="dashboard"
            element={loginStatus ? <Dashboard /> : <Navigate to="/" />}
          />
          <Route
            path="notifications"
            element={loginStatus ? <Notifications /> : <Navigate to="/" />}
          />

          <Route
            path="help"
            element={loginStatus ? <HelpDesk /> : <Navigate to="/" />}
          />

          <Route
            path="library"
            element={loginStatus ? <Library /> : <Navigate to="/" />}
          />
          {authCtx.role !== "Client" && (
            <Route
              path="templates"
              element={loginStatus ? <Templates /> : <Navigate to="/" />}
            />
          )}
          <Route
            path="shortcuts"
            element={loginStatus ? <Shortcuts /> : <Navigate to="/" />}
          />
           <Route
            path="leaderboard"
            element={loginStatus ? <LeaderBoard /> : <Navigate to="/" />}
          />
          <Route
            path="link-child/:projectSlug/:pageSlug"
            element={loginStatus ? <LinkChild /> : <Navigate to="/" />}
          />

          <Route
            path="approval/:pageSlug"
            element={loginStatus ? <Approval /> : <Navigate to="/" />}
          />

          <Route
            path="link-parent/:projectSlug/:pageSlug"
            element={loginStatus ? <LinkParent /> : <Navigate to="/" />}
          />

          <Route
            path="project"
            element={loginStatus ? <Project /> : <Navigate to="/" />}
          />
          <Route
            path="folder"
            element={loginStatus ? <Folder /> : <Navigate to="/" />}
          />
          <Route
            path="folder/:folderSlug"
            element={loginStatus ? <Folder /> : <Navigate to="/" />}
          />

          <Route
            path="page/:pageSlug"
            element={loginStatus ? <Page /> : <Navigate to="/" />}
          />

          <Route
            path="new-page/:pageSlug"
            element={loginStatus ? <NewPage /> : <Navigate to="/" />}
          />

          <Route
            path="forgot-confirm/:uid/:token"
            element={<ForgotConfirm />}
          />

          <Route path="terms" element={<Terms />} />

          <Route path="privacy" element={<Privacy />} />

          <Route path="reset-password" element={<ResetPassword />} />

          <Route
            path="edit-page/:pageSlug"
            element={loginStatus ? <EditPage /> : <Navigate to="/" />}
          />

          <Route
            path="project/:projectSlug"
            element={loginStatus ? <Project /> : <Navigate to="/" />}
          />
          <Route
            path="template/:templateSlug"
            element={loginStatus ? <Template /> : <Navigate to="/" />}
          />

          <Route
            path="library/:projectSlug"
            element={loginStatus ? <Library /> : <Navigate to="/" />}
          />

          {/* LOGIN / AUTH ROUTES */}
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="onboarding" element={<Onboarding />} />
          <Route path="google-login-loader" element={<GoogleLoginRedirect />} />

          {/* Trash Route */}
          <Route path="trash-projects" element={<TrashProjects />} />
          <Route path="trash-blocks/:projectSlug" element={<TrashBlocks />} />
          {/* 404 ROUTE */}
          <Route path="*" element={<Error />} />
        </Routes>
      </Flex>
    </div>
    </ChakraProvider>
  );
};

export default App;
