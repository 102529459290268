import {
  Flex,
  Heading,
  Image,
  FormControl,
  FormLabel,
  Input,
  Text,
  FormHelperText,
  Button,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import { useRef, useContext, useState } from "react";
import swal from "sweetalert";
import { useToast } from "@chakra-ui/react";

import onboardingImage from "../../resources/imgs/onboarding.png";
import beenextLogo from "../../resources/imgs/beenextLogo.png";

import AuthContext from "../../store/auth";
import { UrlBase } from '../../store/global';

const Register = () => {
  const toast = useToast();
  const authCtx = useContext(AuthContext);
  const fnameInputRef = useRef();
  const lnameInputRef = useRef();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const cpasswordInputRef = useRef();

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  function createAccount() {
    const rname =
      fnameInputRef.current.value + " " + lnameInputRef.current.value;
    const remail = emailInputRef.current.value;
    const rp = passwordInputRef.current.value;
    const rcp = cpasswordInputRef.current.value;

    if (rname && remail && rp && rcp) {
      fetch({UrlBase}.UrlBase+"/api/users/register/", {
        method: "POST",
        body: JSON.stringify({
          username: remail,
          email: remail,
          password1: rp,
          password2: rcp,
          name: rname,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            if (res.status < 300) {
              // console.log(res.data);
              authCtx.login(res.data.key);
              authCtx.updateUserInfo(res.data.user.name);
              authCtx.updateRole(res.data.user.role);
              window.location = "/dashboard";
            } else {
              if (res.data.non_field_errors) {
                toast({
                  title: "Registration failed.",
                  description: res.data.non_field_errors[0],
                  status: "error",
                  position: "top-right",
                  duration: 2000,
                  isClosable: true,
                  
                });
              } else {
                toast({
                  title: "Registration failed.",
                  description: res.data.detail,
                  status: "error",
                  position: "top-right",
                  duration: 2000,
                  isClosable: true,
                 
                });
              }
            }
          })
      );
    }
  }

  return (
    <div>
      <Flex
        flexDir={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
        ]}
        width="100vw"
        height={["100%", "100%", "100%", "100vh", "100vh"]}
        // overflow="hidden"
        overflowX="hidden"
        alignItems="center"
        background="bg"
      >
        <Flex
          flexDir="column"
          width={["95vw", "95vw", "95vw", "55vw", "55vw"]}
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Flex
            flexDir="column"
            width={["90%", "90%", "90%", "650px", "650px"]}
            mt={["50px", "50px", "50px", "0px", "0px"]}
            mb={["50px", "50px", "50px", "0px", "0px"]}
          >
            <Image
              src={beenextLogo}
              height="80px"
              width="150px"
              objectFit="contain"
              mb="0"
            />

            <Heading
              fontSize="24px"
              color="white"
              fontFamily="fonts.primary"
              mt="0"
            >
              Register
            </Heading>

            <Text
              fontSize="16px"
              color="#A0AEC0"
              fontFamily="fonts.primary"
              mt="5px"
              lineHeight="1.2"
              width="80%"
              fontWeight="700"
              mb="10px"
            >
              Please help us with some details to create an account.
            </Text>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                createAccount();
              }}
            >
              <FormControl mt="10px">
                <FormLabel color="white" fontSize="14px" fontWeight="700">
                  First Name
                </FormLabel>
                <Input
                  id="name"
                  bg="grey3"
                  color="white"
                  fontWeight="600"
                  focusBorderColor="white"
                  border="none"
                  size="sm"
                  type="text"
                  placeholder="Enter your firstname here"
                  autocomplete="off"
                  ref={fnameInputRef}
                />
              </FormControl>
              <FormControl mt="10px">
                <FormLabel color="white" fontSize="14px" fontWeight="700">
                  Last Name
                </FormLabel>
                <Input
                  id="name"
                  bg="grey3"
                  color="white"
                  fontWeight="600"
                  focusBorderColor="white"
                  border="none"
                  size="sm"
                  type="text"
                  placeholder="Enter your lastname here"
                  autocomplete="off"
                  ref={lnameInputRef}
                />
              </FormControl>

              <FormControl mt="10px">
                <FormLabel color="white" fontSize="14px" fontWeight="700">
                  Email Address
                </FormLabel>
                <Input
                  id="email"
                  bg="grey3"
                  color="white"
                  fontWeight="600"
                  focusBorderColor="white"
                  border="none"
                  size="sm"
                  type="email"
                  placeholder="johndoe@fischerjordan.com"
                  autocomplete="off"
                  ref={emailInputRef}
                />
              </FormControl>

              <FormControl mt="10px">
                <FormLabel color="white" fontSize="14px" fontWeight="700">
                  Password
                </FormLabel>

                <InputGroup bg="grey3">
                  <Input
                    id="password"
                    bg="grey3"
                    color="white"
                    fontWeight="600"
                    focusBorderColor="white"
                    border="none"
                    type={show ? "text" : "password"}
                    placeholder="Enter your password"
                    autocomplete="off"
                    ref={passwordInputRef}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                  <InputRightElement width="4.5rem" bg="grey3">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => setShow(!show)}
                    >
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>

                <FormHelperText>
                  The following platform contains extremely critical and
                  confidential data. Please set a secure password — ensure that
                  you include capital letters, lowercase letters, a number, a
                  special symbol, and that the password is greater than 6
                  characters.
                </FormHelperText>
              </FormControl>

              <FormControl mt="20px">
                <FormLabel color="white" fontSize="14px" fontWeight="700">
                  Confirm Password
                </FormLabel>
                <InputGroup>
                  <Input
                    id="password"
                    bg="grey3"
                    color="white"
                    fontWeight="600"
                    focusBorderColor="white"
                    border="none"
                    type={show2 ? "text" : "password"}
                    placeholder="Confirm your password"
                    autocomplete="off"
                    ref={cpasswordInputRef}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                  <InputRightElement width="4.5rem" bg="grey3">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => setShow2(!show2)}
                    >
                      {show2 ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button
                bg="#EBB435"
                width="200px"
                p="2px"
                mt={["10px", "10px", "10px", "20px", "20px"]}
                mb={["20px", "20px", "20px", "0px", "0px"]}
                color="white"
                fontSize="16px"
                onClick={createAccount}
                _hover={{
                  background: "yellow",
                }}
                type="submit"
              >
                Create your account
              </Button>
            </form>
          </Flex>
        </Flex>

        <Flex
          flexDir="column"
          width={["100vw", "100vw", "100vw", "45vw", "45vw"]}
          height="100%"
        >
          <Image
            src={onboardingImage}
            objectFit="cover"
            height={["200px", "200px", "200px", "100%", "100%"]}
            width="100%"
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default Register;
