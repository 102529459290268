import { useState, useEffect, useRef, useContext } from "react";
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Select,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  TableContainer,
  Spinner,
  Tag,
  Icon,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Popover,
  PopoverTrigger,
} from "@chakra-ui/react";
import { ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";

import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

import AuthContext from "../../store/auth";
import swal from "sweetalert";
import { BsTrash } from "react-icons/bs";
import { useHotkeys } from "react-hotkeys-hook";
import Breadcrumbs from "../../components/BreadCrumbs";
import { Url } from '../../store/global';

const TrashBlocks = () => {
  // authentication module
  const toast = useToast();
  const authCtx = useContext(AuthContext);

  // filter state
  const [activeFilter, setActiveFilter] = useState("all");
  let { projectSlug } = useParams();

  // new page modal
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const title = useRef();
  const industry = useRef();
  const client = useRef();
  const visibility = useRef();
  const code = useRef();
  // const pageID = useRef();

  // pages list object state
  const [delPageInfo, setDelPageInfo] = useState();
  const [delPageInfo2, setDelPageInfo2] = useState();

  const logoutHandler = () => {
    authCtx.logout();
  };

  const [bot, setBot] = useState(0);
  const [curr, setCurr] = useState(0);

  const [search, setSearch] = useState("");

  // POST request to create a new page

  // API GET request to query all the pages

  const getDelPages = () => {
    fetch({Url}.Url+"/deleted_pages/" + projectSlug, {
      method: "GET",

      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setDelPageInfo(res.data);
            setDelPageInfo2(res.data);
          } else {
            toast({
              title: "Oops, something went wrong!",
              description: res.data.detail,
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });

            // swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };
  const sortLol = (direction) => {
    let sortedpages = delPageInfo2;
    if (bot === 1) {
      if (curr !== 1) {
        setCurr(1);
        setDirection(-1);
      } else setDirection(-1 * direction);
      sortedpages = delPageInfo2.sort((a, b) => {
        if (a.title < b.title)
          return curr !== 1 ? -1 : direction === 1 ? -1 : 1;
        if (a.title > b.title) return curr !== 1 ? 1 : direction === 1 ? 1 : -1;
        return 0;
      });
    }
    if (bot === 2) {
      if (curr !== 2) {
        setCurr(2);
        setDirection(-1);
      } else setDirection(-1 * direction);
      sortedpages = delPageInfo2.sort((a, b) => {
        if (a.created_on < b.created_on)
          return curr !== 2 ? -1 : direction === 1 ? -1 : 1;
        if (a.created_on > b.created_on)
          return curr !== 2 ? 1 : direction === 1 ? 1 : -1;
        return 0;
      });
    }
    if (bot === 3) {
      if (curr !== 3) {
        setCurr(3);
        setDirection(-1);
      } else setDirection(-1 * direction);
      sortedpages = delPageInfo2.sort((a, b) => {
        if (a.page_owner < b.page_owner)
          return curr !== 3 ? -1 : direction === 1 ? -1 : 1;
        if (a.page_owner > b.page_owner)
          return curr !== 3 ? 1 : direction === 1 ? 1 : -1;
        return 0;
      });
    }
    if (bot === 4) {
      if (curr !== 4) {
        setCurr(4);
        setDirection(-1);
      } else setDirection(-1 * direction);
      sortedpages = delPageInfo2.sort((a, b) => {
        if (a.industry < b.industry)
          return curr !== 4 ? -1 : direction === 1 ? -1 : 1;
        if (a.industry > b.industry)
          return curr !== 4 ? 1 : direction === 1 ? 1 : -1;
        return 0;
      });
    }
    if (bot === 5) {
      if (curr !== 5) {
        setCurr(5);
        setDirection(-1);
      } else setDirection(-1 * direction);
      sortedpages = delPageInfo2.sort((a, b) => {
        if (a.role < b.role) return curr !== 5 ? -1 : direction === 1 ? -1 : 1;
        if (a.role > b.role) return curr !== 5 ? 1 : direction === 1 ? 1 : -1;
        return 0;
      });
    }
    if (bot === 6) {
      if (curr !== 6) {
        setCurr(6);
        setDirection(-1);
      } else setDirection(-1 * direction);
      sortedpages = delPageInfo2.sort((a, b) => {
        if (a.client_name < b.client_name)
          return curr !== 6 ? -1 : direction === 1 ? -1 : 1;
        if (a.client_name > b.client_name)
          return curr !== 6 ? 1 : direction === 1 ? 1 : -1;
        return 0;
      });
    }
    if (bot === 7) {
      if (curr !== 7) {
        setCurr(7);
        setDirection(-1);
      } else setDirection(-1 * direction);
      sortedpages = delPageInfo2.sort((a, b) => {
        if (a.last_modified < b.last_modified)
          return curr !== 7 ? -1 : direction === 1 ? -1 : 1;
        if (a.last_modified > b.last_modified)
          return curr !== 7 ? 1 : direction === 1 ? 1 : -1;
        return 0;
      });
    }
    setBot(0);
    setDelPageInfo(sortedpages);
  };

  function deleteHandler(ids) {
    // console.log(ids);
    if (delPageInfo?.length === 0) {
      toast({
        title: "Oops, something went wrong!",
        description: "No pages to delete",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      // swal("Oops, something went wrong!", "No pages to delete", "error");
      return;
    }
    fetch(
      {Url}.Url+"/deleted_pages/" + projectSlug + "/",
      {
        method: "DELETE",
        body: JSON.stringify({
          page_id: ids,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            // console.log(res.data);
            toast({
              title: "Deletion confirmed.",
              description: "Let's head back to trash.",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top-right",
              onCloseComplete: () => {
                getDelPages();
              },
            });

            // swal(
            //   "Deletion confirmed.",
            //   "Let's head back to trash.",
            //   "success"
            // ).then(() => {
            //   // (window.location = "/project/" + projectSlug)
            //   getDelPages();
            // });
          } else {
            // console.log(res.data);
            toast({
              title: "page editing failed.",
              description: res.data.detail,
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
            // swal("page editing failed.", res.data.detail, "error");
          }
        })
    );
  }
  // runs on initial page load
  useEffect(() => {
    getDelPages();
    projectSlug ? setActiveFilter(projectSlug) : setActiveFilter("all");
    // console.log(pageID);
    // eslint-disable-next-line
  }, []);

  const [first, setFirst] = useState(0);

  const [direction, setDirection] = useState(-1);

  useEffect(() => {
    first !== 0 ? sortLol(direction) : setFirst(1);
    // setDirection(-1*direction);
    // eslint-disable-next-line
  }, [bot]);
  // const [charRemain, setCharRemain] = useState(30);

  function restoreHandler(pageID) {
    // console.log(pageID);
    fetch(
      {Url}.Url+"/deleted_pages/" + projectSlug + "/",
      {
        method: "POST",
        body: JSON.stringify({
          page_id: pageID,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            // console.log(res.data);
            toast({
              title: "page restored successfully!",
              description: "Let's get back to the page page.",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top-right",
              onCloseComplete: () => {
                window.location = "/page/" + pageID + "/";
              },
            });
            // swal(
            //   "page restored successfully!.",
            //   "Let's get back to the page page.",
            //   "success"
            // ).then(() => {
            //   window.location = "/page/" + pageID + "/";
            // });
          } else {
            // console.log(res.data);
            toast({
              title: "page editing failed.",
              description: res.data.detail,
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
            // swal("page editing failed.", res.data.detail, "error");
          }
        })
    );
  }

  useHotkeys("alt+x", () => (window.location = "/project/" + projectSlug));
  // console.log(delPageInfo);
  const breadcrumbs = [
    { path: "/", breadcrumb: "Home" },
    { path: "/library", breadcrumb: "Library" },
    {
      path: "/project/" + projectSlug,
      breadcrumb: delPageInfo ? delPageInfo[0]?.project : "Project",
      end: true,
    },
  ];

  return (
    <Flex
      backgroundColor="bg"
      flexDir="column"
      alignItems="center"
      minHeight="100vh"
      width="100vw"
      pb="40px"
    >
      <Flex
        width="95%"
        height="fit-content"
        flexDir="column"
        justifyContent="space-between"
        px="10px"
      >
        {delPageInfo && (
          <>
            <Flex w="100%" color="white" fontSize="20px" fontWeight="400">
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </Flex>
            <Flex flexDir="row" alignItems="baseline">
              {/* <Text color="white" fontSize="24px" fontWeight="700">
              Fischer Jordan&nbsp;
            </Text> */}
              <Text color="yellow" fontSize="24px" fontWeight="700">
                Trash Blocks &nbsp;
              </Text>

              <Text color="red" fontSize="18px" fontWeight="700">
                (Blocks in trash are deleted forever after 7 days)
              </Text>
            </Flex>
            <Text color="grey1" fontSize="18px" fontWeight="700">
              You can Restore blocks here, if accidently Deleted.
            </Text>
          </>
        )}
        <Flex
          width="100%"
          height="fit-content"
          flexDir="column"
          justifyContent="space-between"
          p="10px"
          mt="20px"
          mb="20px"
        >
          {delPageInfo ? (
            <TableContainer
              width="100%"
              border="1px solid rgba(255, 255, 255, 0.42)"
              borderRadius="5px"
              padding="1px"
              mt={4}
              mb={10}
            >
              <Table variant="simple" size="md" bg="#3D454C">
                <Thead tex="center">
                  <Tr>
                    <Th color="white" fontSize="14px">
                      PAGE NO.
                    </Th>
                    <Th
                      color="white"
                      fontSize="14px"
                      onClick={() => {
                        setBot(1);
                      }}
                      cursor="pointer"
                    >
                      TITLE {curr === 1 ? (direction === -1 ? "↓" : "↑") : ""}
                    </Th>
                    <Th
                      color="white"
                      fontSize="14px"
                      onClick={() => {
                        setBot(2);
                      }}
                      cursor="pointer"
                    >
                      Deleted On{" "}
                      {curr === 2 ? (direction === -1 ? "↓" : "↑") : ""}
                    </Th>

                    {/* <Th
                    color="white"
                    fontSize="14px"
                    onClick={() => {
                      setBot(6);
                    }}
                  >
                    CLIENT {curr===6?direction===-1?'↓':'↑':""}
                  </Th> */}

                    <Th color="white" fontSize="14px" textAlign="center">
                      REMOVE
                    </Th>
                    <Th color="white" fontSize="14px" textAlign="center">
                      RESTORE
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {delPageInfo.map((page, id) => (
                    <>
                      <Tr key={id} bg={id % 2 === 1 ? "#3D454C" : "#434E58"}>
                        <Td color="white" fontSize="14px">
                          {/* {page.created_on.split('T')[0] } | {page.created_on.split('T')[1].split('.')[0].slice(0,5) }  */}
                          {page.id + 1}
                        </Td>
                        <Td color="white" fontWeight="bold" fontSize="14px">
                          {page.title}
                        </Td>

                        <Td color="white" fontWeight="bold" fontSize="14px">
                          {/*  */}
                          {/* {page.created_on} */}
                          {page.created_on.split("T")[0]} |{" "}
                          {page.created_on
                            .split("T")[1]
                            .split(".")[0]
                            .slice(0, 5)}
                          {/*  */}
                        </Td>

                        <Td>
                          <Popover>
                            <PopoverTrigger>
                              <Button
                                bg="RED"
                                color="white"
                                fontSize="14px"
                                width="100%"
                                as="a"
                              >
                                Delete
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent
                              bg="grey3"
                              color="white"
                              display="block"
                            >
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <PopoverHeader>Confirmation!</PopoverHeader>
                              <PopoverBody display="block">
                                <Text>This action cannot be undone!</Text>
                                <Button
                                  colorScheme="red"
                                  width="170px"
                                  height="40px"
                                  p="10px"
                                  fontSize="16px"
                                  mr="10px"
                                  mt="20px"
                                  onClick={() => deleteHandler(page.id)}
                                >
                                  Delete forever?
                                </Button>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </Td>
                        <Td>
                          <Button
                            bg="yellow1"
                            width="100%"
                            color="white"
                            fontSize="14px"
                            as="a"
                            onClick={() => restoreHandler(page.id)}

                            // href={"/page/" + projectSlug}
                          >
                            RESTORE
                          </Button>
                        </Td>
                      </Tr>
                    </>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            <Spinner />
          )}
        </Flex>
      </Flex>

      <Flex
        height="fit-content"
        flexDir="row"
        justifyContent="space-between"
        p="20px"
        // mt="10px"
        width="95%"
        // mb="30px"
        pos="fixed"
        bottom="0"
        zIndex="1"
        bg="#2A3843"
      >
        <Flex>
          <Button
            bg="yellow1"
            width="250px"
            p="2px"
            mb={["20px", "20px", "20px", "0px", "0px"]}
            color="white"
            fontSize="16px"
            as="a"
            href="/dashboard"
            _hover={{
              background: "#F2DBA6",
            }}
            mr="10px"
          >
            Return to Dashboard
          </Button>
        </Flex>
        <Popover>
          <PopoverTrigger>
            <Button bg="RED" color="white" fontSize="14px" width="200px" as="a">
              Empty Trash
            </Button>
          </PopoverTrigger>
          <PopoverContent bg="grey3" color="white" display="block">
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Confirmation!</PopoverHeader>
            <PopoverBody display="block">
              <Text>This action cannot be undone!</Text>
              <Button
                colorScheme="red"
                width="200px"
                height="40px"
                p="10px"
                fontSize="16px"
                mr="10px"
                mt="20px"
                onClick={() =>
                  deleteHandler(delPageInfo.map((page) => page.id).join(","))
                }
              >
                Delete forever?
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    </Flex>
  );
};

export default TrashBlocks;
