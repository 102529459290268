import { useState, useEffect, useContext, useRef } from "react";
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Spinner,
  Box,
  Avatar,
  useDisclosure,
  ModalFooter,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  Grid,
  Tag,
} from "@chakra-ui/react";
import {
  BsFillFolderFill,
  BsPencilFill,
  BsSearch,
  BsHouse,
  BsTrash,
  BsStar,
  BsFillStarFill,
} from "react-icons/bs";
import { MdOpenInNew } from "react-icons/md";
import AuthContext from "../store/auth";
import swal from "sweetalert";
import Breadcrumbs from "../components/BreadCrumbs";
import { useToast } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Url } from "../store/global";

const Dashboard = () => {
  const toast = useToast();
  const authCtx = useContext(AuthContext);

  const [notif, setNotif] = useState();
  const [currWeekMonday, setCurrWeekMonday] = useState();
  const [leaderBoardInfo, setLeaderBoardInfo] = useState();
  const [rankfirst, setRankFirst] = useState();
  const [ranksecond, setRankSecond] = useState();

  const [rankthird, setRankThird] = useState();

  const [recentlyAccessed, setRecentlyAccessed] = useState();
  const [favs, setFavs] = useState();
  const [folders, setFolders] = useState();
  const [suggestions, setSuggestions] = useState();
  const [fav, setFav] = useState();
  const [colorFont, setColorFont] = useState();

  // bot here represents the column currently being sorted
  const [bot, setBot] = useState(0);

  // direction here represents the direction of sorting
  const [direction, setDirection] = useState(
    JSON.parse(window.localStorage.getItem("direction"))
      ? JSON.parse(window.localStorage.getItem("direction"))
      : 1
  );

  // sortingIndex represents the column that was last sorted
  const [sortingIndex, setCurrSortingIndex] = useState(
    JSON.parse(window.localStorage.getItem("sortingIndex"))
      ? JSON.parse(window.localStorage.getItem("sortingIndex"))
      : 0
  );
  // console.log(window.localStorage.getItem('sortingIndex'))
  const breadcrumbs = [{ path: "/", breadcrumb: "Home", end: true }];
  const maxChar = 40;

  // modals
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  // userefs
  const industry = useRef();
  const client = useRef();
  const title = useRef();

  const sortLol = (direction, data, setFunction) => {
    if (data) {
      let sortedData = data;
      // console.log(recentlyAccessed)
      // console.log(sortedData)
      if (bot === 1) {
        if (sortingIndex !== 1) {
          setCurrSortingIndex(1);
          setDirection(-1);
        } else setDirection(-1 * direction);
        sortedData = data.sort((a, b) => {
          if (a.project_code.toLowerCase() < b.project_code.toLowerCase())
            return sortingIndex !== 1 ? -1 : direction === 1 ? -1 : 1;
          if (a.project_code.toLowerCase() > b.project_code.toLowerCase())
            return sortingIndex !== 1 ? 1 : direction === 1 ? 1 : -1;
          return 0;
        });
      }
      if (bot === 2) {
        if (sortingIndex !== 2) {
          setCurrSortingIndex(2);
          setDirection(-1);
        } else setDirection(-1 * direction);
        sortedData = data.sort((a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase())
            return sortingIndex !== 2 ? -1 : direction === 1 ? -1 : 1;
          if (a.title.toLowerCase() > b.title.toLowerCase())
            return sortingIndex !== 2 ? 1 : direction === 1 ? 1 : -1;
          return 0;
        });
      }
      if (bot === 3) {
        if (sortingIndex !== 3) {
          setCurrSortingIndex(3);
          setDirection(-1);
        } else setDirection(-1 * direction);
        sortedData = data.sort((a, b) => {
          if (a.client_name.toLowerCase() < b.client_name.toLowerCase())
            return sortingIndex !== 3 ? -1 : direction === 1 ? -1 : 1;
          if (a.client_name.toLowerCase() > b.client_name.toLowerCase())
            return sortingIndex !== 3 ? 1 : direction === 1 ? 1 : -1;
          return 0;
        });
      }

      if (bot === 4) {
        if (sortingIndex !== 4) {
          setCurrSortingIndex(4);
          setDirection(-1);
        } else setDirection(-1 * direction);
        sortedData = data.sort((a, b) => {
          if (a.industry < b.industry)
            return sortingIndex !== 4 ? -1 : direction === 1 ? -1 : 1;
          if (a.industry > b.industry)
            return sortingIndex !== 4 ? 1 : direction === 1 ? 1 : -1;
          return 0;
        });
      }
      if (bot === 5) {
        if (sortingIndex !== 5) {
          setCurrSortingIndex(5);
          setDirection(-1);
        } else setDirection(-1 * direction);
        sortedData = data.sort((a, b) => {
          if (a.project_owner.email < b.project_owner.email)
            return sortingIndex !== 5 ? -1 : direction === 1 ? -1 : 1;
          if (a.project_owner.email > b.project_owner.email)
            return sortingIndex !== 5 ? 1 : direction === 1 ? 1 : -1;
          return 0;
        });
      }
      if (bot === 6) {
        if (sortingIndex !== 6) {
          setCurrSortingIndex(6);
          setDirection(-1);
        } else setDirection(-1 * direction);
        sortedData = data.sort((a, b) => {
          if (a.last_modified < b.last_modified)
            return sortingIndex !== 6 ? -1 : direction === 1 ? -1 : 1;
          if (a.last_modified > b.last_modified)
            return sortingIndex !== 6 ? 1 : direction === 1 ? 1 : -1;
          return 0;
        });
      }
      if (bot === 7) {
        if (sortingIndex !== 7) {
          setCurrSortingIndex(7);
          setDirection(-1);
        } else setDirection(-1 * direction);
        sortedData = data.sort((a, b) => {
          if (a.role < b.role)
            return sortingIndex !== 7 ? -1 : direction === 1 ? -1 : 1;
          if (a.role > b.role)
            return sortingIndex !== 7 ? 1 : direction === 1 ? 1 : -1;
          return 0;
        });
      }

      setFunction(sortedData);
    }
    setBot(0);
  };

  // useEffect()
  // useEffect(() => {
  //   getRecentlyAccessed();
  //   // console.log(direction, sortby);
  // }, [fav]);

  const getRecentlyAccessed = () => {
    // setIsLoading(true);

    fetch({ Url }.Url + "/recent_access/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            // console.log(res.data)
            setRecentlyAccessed(res.data.recently_accessed);
            console.log(recentlyAccessed);
            sortLol(direction, recentlyAccessed, setRecentlyAccessed);
          } else {
            // console.log('not gg')
          }
        })
    );
  };

  const getFolders = () => {
    fetch({ Url }.Url + "/get_folder_titles/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setFolders(res.data);
            console.log(res.data);
          } else {
            toast({
              title: "Failed to fetch folders",
              description: "Something went wrong.",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
            // swal('Failed to fetch folders', 'Something went wrong.', 'error');
          }
        })
    );
  };

  const setFavorites = (item) => {
    console.log(item.favorite);
    if (item.favorite) {
      fetch({ Url }.Url + "/remove_project_from_favorite/" + item.id + "/", {
        method: "POST",
        body: JSON.stringify({
          //  version_id: activeVersion,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            console.log(res);
            //  toggleColorMode();
            // setFav(projectInfo.favorite)
            setFav(false);
            console.log(item.favorite, "change");
            setColorFont("white");
            toast({
              title: "Removed from Favorites!",
              description: "Done!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top-right",
              onCloseComplete: () => {
                window.location.reload();
              },
            });
          })
      );
    } else {
      fetch({ Url }.Url + "/add_project_to_favorite/" + item.id + "/", {
        method: "POST",
        body: JSON.stringify({
          //  version_id: activeVersion,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            console.log(res);
            //  toggleColorMode();
            setFav(true);
            console.log(item.favorite, "change");
            setColorFont("yellow");
            toast({
              title: "Added to Favorites!",
              description: "Done!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top-right",
              onCloseComplete: () => {
                window.location.reload();
              },
            });
          })
      );
    }
  };
  useEffect(() => {
    getRecentlyAccessed();
    // getLeaderBoard();
    // if(fav)
    // setColorFont('yellow');
    // else
    // setColorFont('green');
  }, [fav]);

  useEffect(() => {
    getCurrentWeekMonday();
    getLeaderBoard(getMonday());
    // if(fav)
    // setColorFont('yellow');
    // else
    // setColorFont('green');
  }, []);
  const getSuggestions = () => {
    fetch({ Url }.Url + "/suggested_projects/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setSuggestions(res.data.suggested);
            // console.log(res.data.suggested)
          } else {
            toast({
              title: "Failed to fetch suggestions",
              description: "Something went wrong.",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });

            // swal('Failed to fetch suggestions', 'Something went wrong.', 'error');
          }
        })
    );
  };

  // Create Folder Handler
  const getFavorites = () => {
    fetch({ Url }.Url + "/favorites/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          console.log(res.data, "data");
          setFavs(res.data);
          // if (res.status < 300) {
          //   // console.log(res.data)
          //   setRecentlyAccessed(res.data.recently_accessed);
          //   sortLol(direction, recentlyAccessed, setRecentlyAccessed);
          // } else {
          //   // console.log('not gg')
          // }
        })
    );
  };

  function getCurrentWeekMonday() {
    var currentDate = new Date();

    // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    var currentDayOfWeek = currentDate.getDay();

    // Calculate the number of days to subtract to get to Monday (assuming Sunday as the first day of the week)
    var daysToSubtract = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;

    // Calculate the date of the previous Monday
    var previousMonday = new Date(currentDate);
    previousMonday.setDate(currentDate.getDate() - daysToSubtract);

    // Now 'previousMonday' contains the date of the current week's Monday
    console.log(previousMonday);
    function getOrdinalSuffix(day) {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    var options = { month: "long" };
    var dayOfMonth = previousMonday.getDate();
    var formattedDate =
      dayOfMonth +
      getOrdinalSuffix(dayOfMonth) +
      " " +
      previousMonday.toLocaleString("en-US", options);
    setCurrWeekMonday(formattedDate);
  }

  function getMonday() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    
    // Calculate the number of days to subtract to get to Monday (assuming Monday is the first day of the week)
    const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    
    // Calculate the date of the current week's Monday
    const monday = new Date(today);
    monday.setDate(today.getDate() - daysToSubtract);
    
    // Format the date as yyyy-mm-dd
    const year = monday.getFullYear();
    const month = (monday.getMonth() + 1).toString().padStart(2, '0');
    const day = monday.getDate().toString().padStart(2, '0');
    console.log(`${year}-${month}-${day}`)
    
    return `${year}-${month}-${day}`;
  }

  const getLeaderBoard = (start) => {
    // setLoading(true);
    console.log(start);
    const givenDate = new Date(start);

    const nextWeek = new Date(givenDate);
    nextWeek.setDate(nextWeek.getDate() + 7); // Adding 7 days for the next week and 1 more day

    // Format the date as yyyy-mm-dd
    const year = nextWeek.getFullYear();
    const month = String(nextWeek.getMonth() + 1).padStart(2, "0");
    const day = String(nextWeek.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    console.log(formattedDate); // Output: "2023-08-13"
    console.log();
    fetch(
      { Url }.Url +
        "/leaderboard/users/activity?start_date=" +
        start +
        "&end_date=" +
        formattedDate,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            // console.log(res.data);
            setLeaderBoardInfo(res.data.leaderboard);
            // setTemplateInfo2(res.data);
            console.log(res.data.weeks, "show");
            // setLoading(false);

            // console.log(direction);
          } else {
            toast({
              title: "Oops, something went wrong!",
              description: res.data.detail,
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        })
    );
  };
  const addFolderHandler = () => {
    // onClose();

    const rtitle = title.current.value;
    const rindustry = industry.current.value;
    const rclient = client.current.value;
    // const rcode = code.current.value;
    // const rvisibility = visibility.current.value;

    // if (rtitle && rindustry && rclient && rcode) {
    if (rtitle) {
      fetch({ Url }.Url + "/create_folder/", {
        method: "POST",
        body: JSON.stringify({
          title: rtitle,
          industry: rindustry,
          client_name: rclient,
          // project_code: rcode,
          // visibility: rvisibility,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            if (res.status < 300) {
              // console.log(res.data);
              toast({
                title: "Folder created successfully!.",
                description: "Let's head back to library.",
                status: "success",
                position: "top-right",
                duration: 2000,
                isClosable: true,
                onCloseComplete: () => {
                  getFolders();
                  onClose2();
                },
              }).then(() => {
                getFolders();
                // window.location = "/folder/" + ;
                onClose2();
              });
            } else {
              // console.log(res.data);
              toast({
                title: "Project creation failed.",
                description: res.data.detail,
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top-right",
              });
              // swal('Project creation failed.', res.data.detail, 'error');
            }
          })
      );
    }
  };

  // console.log(suggestions);
  // place to store all the refs updates
  // runs on initial page load
  useEffect(() => {
    getRecentlyAccessed();
    getFavorites();
    getFolders();
    getSuggestions();
  }, [fav]);

  useEffect(() => {
    sortLol(direction, recentlyAccessed, setRecentlyAccessed);
    // setDirection(-1*direction);
    window.localStorage.setItem("sortingIndex", sortingIndex);
    window.localStorage.setItem("direction", direction);

    // eslint-disable-next-line
  }, [bot, recentlyAccessed]);

  return (
    <Flex
      backgroundColor="bg"
      flexDir="column"
      alignItems="center"
      minHeight="100vh"
      width="100%"
    >
      <Flex
        width={{ base: "100%", md: "95%" }}
        height="fit-content"
        flexDir="column"
        justifyContent="space-between"
        px="10px"
      >
        <Flex
          flexDir="row"
          justifyContent="space-between"
          w="100%"
          color="white"
          fontSize="20px"
          fontWeight="400"
          alignItems={"center"}
        ></Flex>

        {/* recently accessed */}
        <Flex flexDir={"row"} justifyoContent={"space-between"}></Flex>
        {/* make a stripped table in react with chakra ui */}
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Flex flexDir={"column"}>
            <Flex alignItems="flex-end" height={"100px"}>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </Flex>
            <Flex 
            alignItems="flex-end"
                 height={"50px"}>
              <Text
                color="white"
                fontSize="24px"
                // mt={{ md: "20px" }}
                
                fontWeight="700"
              >
                Recently Accessed
              </Text>
            </Flex>
          </Flex>
          <Flex>
            <Flex
              mt={3}
              p={3}
              border="1px solid"
              borderRadius={"10px"}
              borderColor="background.grey3"
              flexDir={"column"}
            >
              <a href="/leaderboard">
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  color="main.plainWhite"
                >
                  <Text fontSize="md" fontWeight={"800"} mb={2}>Leaderboard: {currWeekMonday ? (
                <Text color="main.text" style={{ display: "inline-block" }}>
                  {" "}
                  Week of {currWeekMonday}
                </Text>
              ) : (
                <></>
              )} </Text>{" "}
                  &nbsp;
                </Flex>
                <Flex
                  alignItems={"flex-end"}
                  color="main.plainWhite"
                  justify="center"
                >
                  <Flex
                    flexDir="column"
                    alignItems={"center"}
                    // m={1}
                    justify="center"
                  >
                    <Flex>
                      <Text fontSize="sm" fontWeight="400">
                        {leaderBoardInfo
                          ? console.log(
                              leaderBoardInfo.filter((e) => {
                                return e.final_rank === 2;
                              })
                            )
                          : null}
                        {leaderBoardInfo ? (
                          <>
                            {}

                            {leaderBoardInfo
                              .filter((e) => {
                                return e.final_rank === 2;
                              })
                              .map((e, index) => (
                                <>
                                  {index < 1 ? (
                                    <>
                                      {/* <Avatar
                      size="xs"
                      bg="white"
                      name={e.user.name}
                      src={e.user.name}
                      color="#2D3842"
                      fontWeight="700"
                      mr={2}
                      mb={2}
                    /> */}
                                      {e.user.name} <br />
                                    </>
                                  ) : null}
                                </>
                              ))}
                          </>
                        ) : (
                          <>Loading...</>
                        )}
                      </Text>
                    </Flex>
                    <Flex>
                      <Box
                        borderRadius="lg"
                        w={"100px"}
                        color="main.plainWhite"
                        h={"30px"}
                        bg="background.silver"
                        mr={2}
                      >
                        <Flex align="center" h={"30px"} justify="center">
                          <Text
                            fontSize="sm"
                            fontWeight="800"
                            textAlign="center"
                          >
                            2nd 🏆
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                  </Flex>
                  <Flex
                    flexDir="column"
                    alignItems={"center"}
                    // m={1}
                    justify="center"
                  >
                    <Flex>
                      <Text fontSize="sm" fontWeight="400">
                        {leaderBoardInfo ? (
                          <>
                            {}

                            {leaderBoardInfo
                              .filter((e) => {
                                return e.final_rank === 1;
                              })
                              .map((e, index) => (
                                <>
                                  {index < 1 ? (
                                    <>
                                   
                                      {e.user.name} <br />
                                    </>
                                  ) : null}
                                </>
                              ))}
                          </>
                        ) : (
                          <>Loading...</>
                        )}
                      </Text>
                    </Flex>
                    <Flex>
                      <Box
                        borderRadius="lg"
                        w={"100px"}
                        color="main.plainWhite"
                        h={"37.5px"}
                        bg="background.gold"
                        // m={2}
                        mr={2}
                      >
                        <Flex align="center" h={"37.5px"} justify="center">
                          <Text
                            fontSize="sm"
                            fontWeight="800"
                            textAlign="center"
                          >
                            1st 🏆
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                  </Flex>
                  <Flex
                    flexDir="column"
                    alignItems={"center"}
                    // m={1}
                    justify="center"
                  >
                    <Flex>
                      <Text width={'100px'} textAlign="center"  fontSize="sm" fontWeight="400">
                        {leaderBoardInfo ? (
                          <>
                            {}

                            {leaderBoardInfo
                              .filter((e) => {
                                return e.final_rank === 3;
                              })
                              .map((e, index) => (
                                <>
                                  {index < 1 ? (
                                    <>
                                      {/* <Avatar
                      size="xs"
                      bg="white"
                      name={e.user.name}
                      src={e.user.name}
                      color="#2D3842"
                      fontWeight="700"
                      mr={2}
                      mb={2}
                    /> */}
                                      {e.user.name} <br />
                                    </>
                                  ) : null}
                                </>
                              ))}
                          </>
                        ) : (
                          <>Loading...</>
                        )}
                      </Text>
                    </Flex>
                    <Flex>
                      <Box
                        borderRadius="lg"
                        w={"100px"}
                        color="main.plainWhite"
                        h={"25px"}
                        bg="background.bronze"

                        // m={2}
                      >
                        <Flex align="center" h={"25px"} justify="center">
                          <Text
                            fontSize="sm"
                            fontWeight="800"
                            textAlign="center"
                          >
                            3rd 🏆
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </a>
            </Flex>
          </Flex>
        </Flex>

        <TableContainer
          width="100%"
          border="1px solid rgba(255, 255, 255, 0.42)"
          borderRadius="5px"
          padding="1px"
          mt={4}
          mb={10}
        >
          <Table variant="simple" size="sm" bg="#3D454C">
            <Thead>
              <Tr>
                {/* <Th
                  color="white"
                  fontSize="14px"
                  onClick={() => {
                    setBot(1);
                  }}
                >
                  Code{"  "}&nbsp;
                  <Text
                    size="xs"
                    display={"inline"}
                    cursor="pointer"
                    color={sortingIndex === 1 ? "yellow" : "white"}
                  >
                    {sortingIndex === 1 ? (direction === -1 ? "▲" : "▼") : "▼"}
                  </Text>
                </Th> */}
                <Th color="white" fontSize="14px" cursor="pointer">
                  {"  "}&nbsp;
                  <Text size="xs" display={"inline-block"}>
                    {/* <BsFillStarFill color='#C1962F' /> */}
                    {/* {checkProjects.length} Projects<br/> selected */}
                  </Text>
                </Th>
                <Th
                  color="white"
                  fontSize="14px"
                  onClick={() => {
                    setBot(2);
                  }}
                >
                  Project Title{"  "}&nbsp;
                  <Text
                    size="xs"
                    display={"inline"}
                    cursor="pointer"
                    color={sortingIndex === 2 ? "main.text" : "white"}
                  >
                    {sortingIndex === 2 ? (direction === -1 ? "▲" : "▼") : "▼"}
                  </Text>
                </Th>
                <Th
                  color="white"
                  fontSize="14px"
                  onClick={() => {
                    setBot(3);
                  }}
                >
                  Client{"  "}&nbsp;
                  <Text
                    size="xs"
                    display={"inline"}
                    cursor="pointer"
                    color={sortingIndex === 3 ? "main.text" : "white"}
                  >
                    {sortingIndex === 3 ? (direction === -1 ? "▲" : "▼") : "▼"}
                  </Text>
                </Th>
                <Th
                  color="white"
                  fontSize="14px"
                  onClick={() => {
                    setBot(6);
                  }}
                >
                  Last Modified{"  "}&nbsp;
                  <Text
                    size="xs"
                    display={"inline"}
                    cursor="pointer"
                    color={sortingIndex === 6 ? "main.text" : "white"}
                  >
                    {sortingIndex === 6 ? (direction === -1 ? "▲" : "▼") : "▼"}
                  </Text>
                </Th>

                <Th
                  color="white"
                  fontSize="14px"
                  onClick={() => {
                    setBot(5);
                  }}
                >
                  Owner{"  "}&nbsp;
                  <Text
                    size="xs"
                    display={"inline"}
                    cursor="pointer"
                    color={sortingIndex === 5 ? "main.text" : "white"}
                  >
                    {sortingIndex === 5 ? (direction === -1 ? "▲" : "▼") : "▼"}
                  </Text>{" "}
                </Th>
                <Th
                  color="white"
                  fontSize="14px"
                  onClick={() => {
                    setBot(7);
                  }}
                >
                  Access{"  "}&nbsp;
                  <Text
                    size="xs"
                    display={"inline"}
                    cursor="pointer"
                    color={sortingIndex === 7 ? "main.text" : "white"}
                  >
                    {sortingIndex === 7 ? (direction === -1 ? "▲" : "▼") : "▼"}
                  </Text>
                </Th>
                <Th
                  color="white"
                  fontSize="14px"
                  onClick={() => {
                    setBot(4);
                  }}
                >
                  Category{"  "}&nbsp;
                  <Text
                    size="xs"
                    display={"inline"}
                    cursor="pointer"
                    color={sortingIndex === 4 ? "main.text" : "white"}
                  >
                    {sortingIndex === 4 ? (direction === -1 ? "▲" : "▼") : "▼"}
                  </Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {recentlyAccessed !== undefined &&
                recentlyAccessed?.map(
                  (item, index) =>
                    index < 10 && (
                      <>
                        {/* <Link href={`/project/` + item.id}> */}
                        {/* <a href={`/project/+ ${item.id}`}> */}
                        <Tr
                          key={index}
                          bg={index % 2 === 1 ? "#3D454C" : "#434E58"}
                          // onClick={() =>
                          //   (window.location.href = "/project/" + item.id)
                          // }
                          cursor="pointer"
                        >
                          {" "}
                          {/* <Td
                          color="white"
                          fontSize="14px"
                          style={{
                            minWidth: "180px",
                            maxWidth: "180px",
                            width: "180px",
                          }}
                        >
                          {item.project_code?.length > 24
                            ? item.project_code.slice(0, 24) + "..."
                            : item.project_code}
                        </Td> */}
                          <Td
                            color="white"
                            fontSize="14px"
                            textAlign="left"
                            pl="4"
                            ml="5"
                            style={{
                              minWidth: "40px",
                              maxWidth: "40px",
                              width: "40px",
                            }}
                            // onClick={() =>
                            //   (window.location = "/project/" + item.id)
                            // }
                          >
                            {/* {item.favorite?<>true</>:<>false</>} */}
                            {/* {console.log(item)} */}

                            <Button
                              bg="transparent"
                              // justifyContent={"left"}
                              // color="white"
                              // fontSize="16px"
                              _hover={{
                                background: "transparent",
                              }}
                              pl={1}
                              mr={1}
                              // mr="10px"
                              height="1.9rem"
                              size="sm"
                              onClick={() => {
                                setFavorites(item);
                              }}
                            >
                              &nbsp;
                              {item.favorite ? (
                                <>
                                  <BsFillStarFill color="#C1962F" />
                                </>
                              ) : (
                                <>
                                  <BsStar color="#fff" />
                                </>
                              )}
                            </Button>
                          </Td>
                          <Td
                            color="white"
                            fontSize="14px"
                            style={{
                              minWidth: "405px",
                              maxWidth: "405px",
                              width: "405px",
                            }}
                            pt={3}
                            pb={3}
                            onClick={() =>
                              (window.location = "/project/" + item.id)
                            }
                          >
                            <Text
                              isTruncated
                              display={"inline"}
                              style={{
                                minWidth: "205px",
                                maxWidth: "205px",
                                width: "205px",
                              }}
                            >
                              {item.title?.length > 38
                                ? item.title.slice(0, 38) + "..."
                                : item.title}
                            </Text>
                            <Tag
                              fontSize={"10px"}
                              bg="background.grey4"
                              color="#FFF"
                              size="sm"
                              borderRadius="full"
                              ml="2"
                            >
                              {item.project_code?.length > 24
                                ? item.project_code.slice(0, 24) + "..."
                                : item.project_code}
                            </Tag>
                          </Td>
                          <Td
                            color="white"
                            fontSize="14px"
                            style={{
                              minWidth: "200px",
                              maxWidth: "200px",
                              width: "200px",
                            }}
                            onClick={() =>
                              (window.location = "/project/" + item.id)
                            }
                          >
                            {item.client_name?.length > 24
                              ? item.client_name.slice(0, 24) + "..."
                              : item.client_name}
                          </Td>
                          <Td
                            color="white"
                            fontSize="14px"
                            style={{
                              minWidth: "180px",
                              maxWidth: "180px",
                              width: "180px",
                            }}
                            onClick={() =>
                              (window.location = "/project/" + item.id)
                            }
                          >
                            {item.last_modified.split("T")[0]} |{" "}
                            {item.last_modified
                              .split("T")[1]
                              .split(".")[0]
                              .slice(0, 5)}{" "}
                          </Td>
                          <Td
                            color="white"
                            fontSize="14px"
                            style={{
                              minWidth: "140px",
                              maxWidth: "140px",
                              width: "140px",
                            }}
                            onClick={() =>
                              (window.location = "/project/" + item.id)
                            }
                          >
                            {item.project_owner.name?.length > 30
                              ? item.project_owner.name.slice(0, 30) + "..."
                              : item.project_owner.name}
                          </Td>
                          <Td
                            color="white"
                            fontSize="14px"
                            style={{
                              minWidth: "120px",
                              maxWidth: "120px",
                              width: "120px",
                            }}
                            onClick={() =>
                              (window.location = "/project/" + item.id)
                            }
                          >
                            {item.role}
                          </Td>
                          <Td
                            color="white"
                            fontSize="14px"
                            width="225px"
                            style={{
                              minWidth: "150px",
                              maxWidth: "150px",
                              width: "150px",
                            }}
                            onClick={() =>
                              (window.location = "/project/" + item.id)
                            }
                          >
                            {item.industry}
                          </Td>
                        </Tr>
                        {/* </a> */}
                        {/* </Link> */}
                      </>
                    )
                )}
            </Tbody>
          </Table>
        </TableContainer>

        <Text
          color="white"
          fontSize="24px"
          mt={{ md: "20px" }}
          fontWeight="700"
        >
          Favorites
        </Text>

        <TableContainer
          width="100%"
          border="1px solid rgba(255, 255, 255, 0.42)"
          borderRadius="5px"
          padding="1px"
          mt={4}
          mb={10}
        >
          <Table variant="simple" size="sm" bg="#3D454C">
            <Thead>
              <Tr>
                {/* <Th
                  color="white"
                  fontSize="14px"
                  onClick={() => {
                    setBot(1);
                  }}
                >
                  Code{"  "}&nbsp;
                  <Text
                    size="xs"
                    display={"inline"}
                    cursor="pointer"
                    color={sortingIndex === 1 ? "yellow" : "white"}
                  >
                    {sortingIndex === 1 ? (direction === -1 ? "▲" : "▼") : "▼"}
                  </Text>
                </Th> */}

                <Th color="white" fontSize="14px" cursor="pointer">
                  {"  "}&nbsp;
                  <Text size="xs" display={"inline-block"}>
                    {/* <BsFillStarFill color='#C1962F' /> */}
                    {/* {checkProjects.length} Projects<br/> selected */}
                  </Text>
                </Th>

                <Th
                  color="white"
                  fontSize="14px"
                  fontWeight="400"
                  onClick={() => {
                    setBot(2);
                  }}
                >
                  Project Title{"  "}&nbsp;
                  <Text
                    size="xs"
                    display={"inline"}
                    cursor="pointer"
                    color={sortingIndex === 2 ? "main.text" : "white"}
                  >
                    {sortingIndex === 2 ? (direction === -1 ? "▲" : "▼") : "▼"}
                  </Text>
                </Th>
                <Th
                  color="white"
                  fontSize="14px"
                  onClick={() => {
                    setBot(3);
                  }}
                >
                  Client{"  "}&nbsp;
                  <Text
                    size="xs"
                    display={"inline"}
                    cursor="pointer"
                    color={sortingIndex === 3 ? "main.text" : "white"}
                  >
                    {sortingIndex === 3 ? (direction === -1 ? "▲" : "▼") : "▼"}
                  </Text>
                </Th>
                <Th
                  color="white"
                  fontSize="14px"
                  onClick={() => {
                    setBot(6);
                  }}
                >
                  Last Modified{"  "}&nbsp;
                  <Text
                    size="xs"
                    display={"inline"}
                    cursor="pointer"
                    color={sortingIndex === 6 ? "main.text" : "white"}
                  >
                    {sortingIndex === 6 ? (direction === -1 ? "▲" : "▼") : "▼"}
                  </Text>
                </Th>

                <Th
                  color="white"
                  fontSize="14px"
                  onClick={() => {
                    setBot(5);
                  }}
                >
                  Owner{"  "}&nbsp;
                  <Text
                    size="xs"
                    display={"inline"}
                    cursor="pointer"
                    color={sortingIndex === 5 ? "main.text" : "white"}
                  >
                    {sortingIndex === 5 ? (direction === -1 ? "▲" : "▼") : "▼"}
                  </Text>{" "}
                </Th>

                <Th
                  color="white"
                  fontSize="14px"
                  onClick={() => {
                    setBot(7);
                  }}
                >
                  Access{"  "}&nbsp;
                  <Text
                    size="xs"
                    display={"inline"}
                    cursor="pointer"
                    color={sortingIndex === 7 ? "main.text" : "white"}
                  >
                    {sortingIndex === 7 ? (direction === -1 ? "▲" : "▼") : "▼"}
                  </Text>
                </Th>
                <Th
                  color="white"
                  fontSize="14px"
                  onClick={() => {
                    setBot(4);
                  }}
                >
                  Category{"  "}&nbsp;
                  <Text
                    size="xs"
                    display={"inline"}
                    cursor="pointer"
                    color={sortingIndex === 4 ? "main.text" : "white"}
                  >
                    {sortingIndex === 4 ? (direction === -1 ? "▲" : "▼") : "▼"}
                  </Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {favs !== undefined &&
                favs?.map(
                  (item, index) =>
                    index < 10 && (
                      <Tr
                        key={index}
                        bg={index % 2 === 1 ? "#3D454C" : "#434E58"}
                        cursor="pointer"
                      >
                        {" "}
                        {/* <Td
                          color="white"
                          fontSize="14px"
                          style={{
                            minWidth: "180px",
                            maxWidth: "180px",
                            width: "180px",
                            
                          }}
                          pt={3}
                          pb={3}
                        >
                          {item.project_code?.length > 24
                            ? item.project_code.slice(0, 24) + "..."
                            : item.project_code}
                        </Td> */}
                        <Td
                          color="white"
                          fontSize="14px"
                          textAlign="left"
                          pl="4"
                          ml="5"
                          style={{
                            minWidth: "40px",
                            maxWidth: "40px",
                            width: "40px",
                          }}
                          // onClick={() =>
                          //   (window.location = "/project/" + item.id)
                          // }
                        >
                          {/* {item.favorite?<>true</>:<>false</>} */}
                          {/* {console.log(item)} */}

                          <Button
                            bg="transparent"
                            // justifyContent={"left"}
                            // color="white"
                            // fontSize="16px"
                            _hover={{
                              background: "transparent",
                            }}
                            pl={1}
                            mr={1}
                            // mr="10px"
                            height="1.9rem"
                            size="sm"
                            onClick={() => {
                              setFavorites(item);
                            }}
                          >
                            &nbsp;
                            {item.favorite ? (
                              <>
                                <BsFillStarFill color="#C1962F" />
                              </>
                            ) : (
                              <>
                                <BsStar color="#fff" />
                              </>
                            )}
                          </Button>
                        </Td>
                        <Td
                          color="white"
                          fontSize="14px"
                          style={{
                            minWidth: "405px",
                            maxWidth: "405px",
                            width: "405px",
                          }}
                          pt={3}
                          pb={3}
                          onClick={() =>
                            (window.location = "/project/" + item.id)
                          }
                        >
                          <Text
                            isTruncated
                            display={"inline"}
                            style={{
                              minWidth: "205px",
                              maxWidth: "205px",
                              width: "205px",
                            }}
                          >
                            {item.title?.length > 38
                              ? item.title.slice(0, 38) + "..."
                              : item.title}
                          </Text>
                          <Tag
                            fontSize={"10px"}
                            bg="background.grey4"
                            color="#FFF"
                            size="sm"
                            borderRadius="full"
                            ml="2"
                          >
                            {item.project_code?.length > 24
                              ? item.project_code.slice(0, 24) + "..."
                              : item.project_code}
                          </Tag>
                          {/* {item.title?.length > 38
                            ? item.title.slice(0, 38) + "..."
                            : item.title} */}
                        </Td>
                        <Td
                          color="white"
                          fontSize="14px"
                          style={{
                            minWidth: "200px",
                            maxWidth: "200px",
                            width: "200px",
                          }}
                          onClick={() =>
                            (window.location = "/project/" + item.id)
                          }
                        >
                          {item.client_name?.length > 24
                            ? item.client_name.slice(0, 24) + "..."
                            : item.client_name}
                        </Td>
                        <Td
                          color="white"
                          fontSize="14px"
                          style={{
                            minWidth: "180px",
                            maxWidth: "180px",
                            width: "180px",
                          }}
                          onClick={() =>
                            (window.location = "/project/" + item.id)
                          }
                        >
                          {item.last_modified.split("T")[0]} |{" "}
                          {item.last_modified
                            .split("T")[1]
                            .split(".")[0]
                            .slice(0, 5)}{" "}
                        </Td>
                        <Td
                          color="white"
                          fontSize="14px"
                          style={{
                            minWidth: "140px",
                            maxWidth: "140px",
                            width: "140px",
                          }}
                          onClick={() =>
                            (window.location = "/project/" + item.id)
                          }
                        >
                          {item.project_owner.name?.length > 30
                            ? item.project_owner.name.slice(0, 30) + "..."
                            : item.project_owner.name}
                        </Td>
                        <Td
                          color="white"
                          fontSize="14px"
                          style={{
                            minWidth: "120px",
                            maxWidth: "120px",
                            width: "120px",
                          }}
                          onClick={() =>
                            (window.location = "/project/" + item.id)
                          }
                        >
                          {item.role}
                        </Td>
                        <Td
                          color="white"
                          fontSize="14px"
                          width="225px"
                          style={{
                            minWidth: "150px",
                            maxWidth: "150px",
                            width: "150px",
                          }}
                          onClick={() =>
                            (window.location = "/project/" + item.id)
                          }
                        >
                          {item.industry}
                        </Td>
                      </Tr>
                    )
                )}
            </Tbody>
          </Table>
        </TableContainer>

        {/* {folders?.length > 0 && ( */}
        <Flex mt={4} justifyContent={"space-between"} alignItems={"center"}>
          <Text color="white" fontSize="24px" fontWeight="700">
            My Folders
            {authCtx.role !== "Client" && (
              <Text
                ml={5}
                display="inline-flex"
                color="white"
                fontSize="16px"
                onClick={onOpen2}
                cursor="pointer"
                bg="#3D454C"
                p={2}
                borderRadius="5px"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.29688 0.5625H9.70312C9.82812 0.5625 9.89062 0.625 9.89062 0.75V17.25C9.89062 17.375 9.82812 17.4375 9.70312 17.4375H8.29688C8.17187 17.4375 8.10938 17.375 8.10938 17.25V0.75C8.10938 0.625 8.17187 0.5625 8.29688 0.5625Z"
                    fill="white"
                  />
                  <path
                    d="M1.125 8.10938H16.875C17 8.10938 17.0625 8.17187 17.0625 8.29688V9.70312C17.0625 9.82813 17 9.89062 16.875 9.89062H1.125C1 9.89062 0.9375 9.82813 0.9375 9.70312V8.29688C0.9375 8.17187 1 8.10938 1.125 8.10938Z"
                    fill="white"
                  />
                </svg>
              </Text>
            )}
          </Text>
        </Flex>
        {/* )} */}

        <Flex
          justify="center"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(14rem, 1fr))",
            gridGap: "1rem",
          }}
          mt={3}
          mb={5}
        >
          {folders?.map((folder, id) => (
            <>
              <Box
                bg="background.grey3"
                flexDir="ROW"
                color="white"
                p="14px"
                borderRadius="7PX"
                my="8px"
                pl="20px"
                alignItems="center"
                justifyContent="space-between"
                ml="0px"
                maxWidth={{ base: "100%", md: "340px" }}
                key={id}
                onClick={() => (window.location = "/folder/" + folder.id)}
              >
                <Flex color="white" fontSize="20px" fontWeight="700">
                  <Flex
                    flexDir="row"
                    cursor="pointer"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text color="white" pr={6}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 22 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.0625 3.85856H11.2461L8.03867 0.617744C7.99786 0.577387 7.94418 0.554706 7.88828 0.554199H0.9375C0.453516 0.554199 0.0625 0.967244 0.0625 1.47849V18.578C0.0625 19.0892 0.453516 19.5023 0.9375 19.5023H21.0625C21.5465 19.5023 21.9375 19.0892 21.9375 18.578V4.78285C21.9375 4.2716 21.5465 3.85856 21.0625 3.85856ZM19.9688 17.4226H2.03125V2.63386H7.18555L10.4559 5.93822H19.9688V17.4226Z"
                          fill="white"
                        />
                      </svg>
                    </Text>
                    <Text>{folder.title}</Text>
                  </Flex>
                </Flex>
              </Box>
            </>
          ))}
        </Flex>

        {/* <Text color="white" fontSize="24px" mt="20px" fontWeight="700">
          Suggestions
        </Text>
        <Grid
          justify="center"
          gridGap="1rem"
          display="grid"
          gridTemplateColumns="repeat(auto-fill, minmax(14rem, 1fr))"
          justifyItems="start"
          mt={3}
          mb={5}
        >
          {suggestions?.map((sug, id) => (
            <>
              <Box
                onClick={() => (window.location = "/project/" + sug.id)}
                cursor="pointer"
                flexDir="ROW"
                color="white"
                my="8px"
                alignItems="center"
                justifyContent="space-between"
                maxWidth={{ base: "100%", md: "340px" }}
                width="100%"
                key={id}
              >
                <Flex
                  color="white"
                  bg="#676E74"
                  fontSize="25px"
                  flexDir={"column"}
                  fontWeight="700"
                  borderTopRadius="7px"
                  py={6}
                  px={4}
                >
             
                  <Text>
                    {" "}
                    {sug.title?.length > 23
                      ? sug.title.slice(0, 23) + "..."
                      : sug.title}
                  </Text>
                </Flex>
                <Flex
                  bg="grey3"
                  cursor="pointer"
                  px={3}
                  py={2}
                  flexDir="column"
                  borderBottomRadius="7px"
                >
                  <Flex fontSize={"12px"} textTransform={"uppercase"}>
                    <Text p={1} px={2} mr={3} bg="bg" rounded={3}>
                      {sug.visibility}
                    </Text>
                    <Text p={1} px={2} bg="bg" rounded={3}>
                      Fischer jordan
                    </Text>
                  </Flex>
                  <Text fontSize={"3xl"} pt={4}></Text>
                  <Flex alignItems={"center"} justifyContent="space-between">
                    <Text></Text>
                    {sug.collaborators?.map(
                      (avatar, id) =>
                        id < 4 && (
                          <Avatar
                            size="sm"
                            bg="white"
                            key={id}
                            name={avatar.name}
                            src={avatar.email}
                            color="#2D3842"
                            fontWeight="700"
                            position="absolute"
                            ml={13 * id + "px"}
                            zIndex={id}
                            border="1px solid #2D3842"
                          />
                        )
                    )}
                    <Text color="rgba(255, 255, 255, 0.6)" py={4}>
                      {" " +
                        sug.last_modified
                          .split("T")[1]
                          .split(".")[0]
                          .slice(0, 5)}{" "}
                      | {sug.last_modified.split("T")[0]}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </>
          ))}
        </Grid> */}
      </Flex>
      <>
        <Modal bg="#444d54" isOpen={isOpen2} onClose={onClose2} isCentered>
          <ModalOverlay BG="#2A3843" />
          <ModalContent bg="#444d54">
            <ModalHeader color="white">Add a New Folder</ModalHeader>
            <ModalBody>
              <Flex flexDir="column" mb="10px">
                <Text
                  color="white"
                  fontWeight="700"
                  fontSize="18px"
                  mb="5px"
                  mt="15px"
                >
                  Folder Title
                </Text>
                <Input
                  bg="#525C64"
                  color="white"
                  fontWeight="700"
                  placeholder="Add Folder Title"
                  maxlength={maxChar}
                  ref={title}
                  // enable this function to print the number of characters left
                  // onChange={charRem}
                ></Input>
                {/* This line is for printing the characters left */}
                {/* <Text color="white"float="right">{maxChar - charRemain} characters remaining</Text> */}
              </Flex>

              <Flex flexDir="column">
                <Text color="white" fontWeight="700" fontSize="18px" mb="5px">
                  Industry
                </Text>
                <Select
                  bg="#525C64"
                  color="white"
                  ref={industry}
                  fontWeight="700"
                  placeholder="Select Industry"
                >
                  <option value="Financial Services">Financial Services</option>
                  <option value="Healthcare">Healthcare</option>
                  <option value="Retail">Retail</option>
                  <option value="Technology">Technology</option>
                  <option value="Government">Government</option>
                  <option value="Legal">Legal</option>
                  <option value="Other">Other</option>
                </Select>
              </Flex>
              <Flex flexDir="column">
                <Text
                  color="white"
                  fontWeight="700"
                  fontSize="18px"
                  mb="5px"
                  mt="15px"
                >
                  Name of the Client
                </Text>
                <Input
                  bg="#525C64"
                  color="white"
                  fontWeight="700"
                  placeholder="Enter Client Name"
                  ref={client}
                ></Input>
              </Flex>
              <Flex flexDir="column">
                {/* <Text
                  color="white"
                  fontWeight="700"
                  fontSize="18px"
                  mb="5px"
                  mt="15px"
                >
                  Project Code
                </Text>
                <Input
                  bg="#525C64"
                  color="white"
                  fontWeight="700"
                  placeholder="Add Project Code"
                  ref={code}
                ></Input>
              </Flex>
              <Flex flexDir="column">
                <Text color="white" fontWeight="700" fontSize="18px" mb="5px">
                  Visibility
                </Text>
                <Select
                  bg="#525C64"
                  color="white"
                  ref={visibility}
                  fontWeight="700"
                  placeholder="Choose Visibility"
                >
                  <option value="public">public</option>
                  <option value="private">private</option>
                </Select> */}
              </Flex>
            </ModalBody>

            <ModalFooter mt="10px">
              <Button
                bg="background.yellow1"
                color="white"
                mr={3}
                onClick={() => addFolderHandler()}
              >
                Add Folder to Library
              </Button>
              <Button color="white" variant="ghost" onClick={onClose2}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </Flex>
  );
};

export default Dashboard;
