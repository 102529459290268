import { useState, useEffect, useContext } from "react";
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Spinner,
  Box,
} from "@chakra-ui/react";
import { Url } from '../../store/global';

import AuthContext from "../../store/auth";
import swal from "sweetalert";
import moment from "moment";
import Breadcrumbs from "../../components/BreadCrumbs";
import { useToast } from "@chakra-ui/react";

export default function Notifications() {
  const toast = useToast();

  const authCtx = useContext(AuthContext);

  const [notif, setNotif] = useState();

  const getNotifications = () => {
    fetch({Url}.Url+"/get_notifications/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setNotif(res.data);
          } else {
            // console.log("not gg");
          }
        })
    );
  };
  const openNotif = (id) => {
    fetch({Url}.Url+"/notification_opened/" + id + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            window.location = res.data.redirect;
          } else {
            toast({
              title: "Registration failed.",
              description: "Something went wrong.",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
            // swal('Registration failed.', 'Something went wrong.', 'error');
          }
        })
    );
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const breadcrumbs = [{ path: "/", breadcrumb: "Home", end: true }];

  return (
    <Flex
      backgroundColor="bg"
      flexDir="column"
      alignItems="center"
      minHeight="100vh"
      width="100vw"
      pb="40px"
    >
      <Flex
        width="95%"
        height="fit-content"
        flexDir="column"
        justifyContent="space-between"
        px="10px"
      >
        <Flex w="100%" color="white" fontSize="20px" fontWeight="400">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Flex>
        {notif ? (
          <>
            <Text color="white" fontSize="24px" my="30px" fontWeight="700">
              Notifications
            </Text>

            <TableContainer
              width="95%"
              border="1px #5D6C77 solid"
              borderRadius="5px"
              padding="10px"
            >
              <Table variant="simple" size="md" colorScheme="gray">
                <TableCaption color="white">MY NOTIFICATIONS</TableCaption>
                <Thead>
                  <Tr>
                    <Th color="white" fontSize="16px">
                      FROM
                    </Th>
                    <Th color="white" fontSize="16px">
                      NOTIFICATION
                    </Th>
                    <Th color="white" fontSize="16px">
                      WHEN
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {notif.notifications.map((nft) => (
                    <Tr
                      bg={!nft.read ? "background.grey3" : "transparent"}
                      cursor="pointer"
                      onClick={() => openNotif(nft.id)}
                    >
                      <Td color="white" fontSize="16px">
                        {nft.from_user}
                      </Td>
                      <Td color="white" fontWeight="700" fontSize="16px">
                        {nft.message}
                      </Td>
                      <Td color="white" fontSize="16px">
                        {moment(nft.created_on).calendar()}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Spinner color="white" />
        )}
      </Flex>
    </Flex>
  );
}
