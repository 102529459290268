import {
  Flex,
  Heading,
  Image,
  FormControl,
  FormLabel,
  Input,
  Text,
  Button,
} from "@chakra-ui/react";
import { useContext, useRef } from "react";
import swal from "sweetalert";
import { useToast } from "@chakra-ui/react";
import { UrlBase } from '../../store/global';

import onboardingImage from "../../resources/imgs/onboarding.png";
import beenextLogo from "../../resources/imgs/beenextLogo.png";
import AuthContext from "../../store/auth";

const ResetPassword = () => {
  const toast = useToast();
  const p1ref = useRef();
  const p2ref = useRef();
  const p3ref = useRef();
  const authCtx = useContext(AuthContext);

  function forgorPassword() {
    const p2 = p2ref.current.value;
    const p3 = p3ref.current.value;

    fetch({UrlBase}.UrlBase+"/rest-auth/password/change/", {
      method: "POST",
      body: JSON.stringify({
        new_password1: p2,
        new_password2: p3,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            toast({
              title: "Success!",
              description: "Password reset successfully!",
              status: "success",
              position: "top-right",
              duration: 2000,
              isClosable: true,
             
            });
          } else {
            toast({
              title: "Something went wrong.",
              description: res.data.detail,
              status: "error",
              position: "top-right",
              duration: 2000,
              isClosable: true,
             
            });
          }
        })
    );
  }

  return (
    <div>
      {/* <Navbar /> */}
      <Flex
        flexDir={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
        ]}
        width="100vw"
        height={["100%", "100%", "100%", "100vh", "100vh"]}
        overflow="hidden"
        alignItems="center"
        background="bg"
      >
        <Flex
          flexDir="column"
          width={["95vw", "95vw", "95vw", "60vw", "60vw"]}
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Flex
            flexDir="column"
            width={["90%", "90%", "85%", "85%", "85%"]}
            mt={["50px", "50px", "50px", "0px", "0px"]}
            mb={["50px", "50px", "50px", "0px", "0px"]}
          >
            <Image
              src={beenextLogo}
              height="120px"
              width="200px"
              objectFit="contain"
            />

            <Heading fontSize="28px" color="white" fontFamily="fonts.primary">
              Reset Password
            </Heading>

            <Text
              fontSize="18px"
              color="grey1"
              fontFamily="fonts.primary"
              mt="0px"
              fontWeight="700"
            >
              Need to change your password? Reset it here.
            </Text>
            <FormControl mt="20px">
              <FormLabel color="grey1" fontWeight="700" htmlFor="email">
                Enter new password
              </FormLabel>
              <Input
                id="email"
                bg="grey3"
                color="white"
                fontWeight="600"
                focusBorderColor="white"
                border="none"
                size="md"
                type="password"
                placeholder="Enter new password here"
                autocomplete="off"
                ref={p2ref}
              />
            </FormControl>

            <FormControl mt="20px">
              <FormLabel color="grey1" fontWeight="700" htmlFor="email">
                Confirm new password
              </FormLabel>
              <Input
                id="email"
                bg="grey3"
                color="white"
                fontWeight="600"
                focusBorderColor="white"
                border="none"
                size="md"
                type="password"
                placeholder="Confirm new password here"
                autocomplete="off"
                ref={p3ref}
              />
            </FormControl>

            <Flex flexDir="row" mt="30px" flexWrap="wrap">
              <Button
                bg="yellow"
                width="250px"
                p="2px"
                mt={["0px", "0px", "0px", "60px", "60px"]}
                mb={["20px", "20px", "20px", "0px", "0px"]}
                color="white"
                fontSize="16px"
                onClick={forgorPassword}
                _hover={{
                  background: "#F2DBA6",
                }}
                mr="10px"
              >
                Reset my new password
              </Button>

              <Button
                variant="outline"
                colorScheme="gray"
                color="white"
                width="150px"
                p="2px"
                mt={["0px", "0px", "0px", "60px", "60px"]}
                mb={["20px", "20px", "20px", "0px", "0px"]}
                fontSize="16px"
                as="a"
                href="/"
                _hover={{
                  background: "grey2",
                }}
              >
                Back to Login
              </Button>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          flexDir="column"
          width={["100vw", "100vw", "100vw", "45vw", "45vw"]}
          height="100%"
        >
          <Image
            src={onboardingImage}
            objectFit="cover"
            height={["200px", "200px", "200px", "100%", "100%"]}
            width="100%"
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default ResetPassword;
