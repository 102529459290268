import {
  Flex,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import Breadcrumbs from "../../components/BreadCrumbs";
import { TitleCompany } from '../../store/global';

const shortCutData = [
  { key: " N", function: "Create new project on library page" },
  { key: " N", function: "Create a new block in the project" },
  { key: " N", function: "Upload a new file on edit block page" },
  { key: " S", function: "Save project on library page in" },
  { key: " S", function: "Save the edited block" },
  { key: " L", function: "Redirects the user to the Library" },
  { key: " H", function: "Redirects the user to the Dashboard (home)" },
  { key: " P", function: "Go to Approval Page of a block" },
  { key: " C", function: "Copy a project on the project page" },
  { key: " C", function: "Copy Block to another Project on the block page" },
  { key: " <", function: "Navigate to the previously opened page on the left" },
  {
    key: " >",
    function: "Navigate to the previously opened page on the right",
  },
];

const Shortcuts = () => {
  useHotkeys("alt+x", () => (window.location = "/dashboard"));
  useHotkeys("option+x", () => (window.location = "/dashboard"));
  const breadcrumbs = [{ path: "/", breadcrumb: "Home", end: true }];
  return (
    <Flex
      backgroundColor="bg"
      flexDir="column"
      alignItems="center"
      minHeight="100vh"
      width="100vw"
      pb="40px"
    >
      <Flex
        width="95%"
        height="fit-content"
        flexDir="column"
        justifyContent="space-between"
        px="10px"
      >
        <Flex w="100%" color="white" fontSize="20px" fontWeight="400">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Flex>
        <Flex flexDir="column">
          <Flex flexDir="row">
            <Text color="white" fontSize="24px" fontWeight="700">
              {TitleCompany}&nbsp;
            </Text>
            <Text color="main.text" fontSize="24px" fontWeight="700">
              Shortcuts
            </Text>
          </Flex>
          <Text color="main.grey1" fontSize="18px" fontWeight="700">
            Welcome to the Knowledge Management System.
          </Text>
        </Flex>
        <TableContainer
          mt="30px"
          maxWidth={"750px"}
          border="1px #5D6C77 solid"
          borderRadius="20px"
          padding="10px"
        >
          <Table variant="simple" size="md" colorScheme="gray">
            <TableCaption color="white">KMS SHORTCUTS</TableCaption>
            <Thead>
              <Tr>
                <Th color="white" fontSize="16px" w="20%">
                  KEY_WIN
                </Th>
                <Th color="white" fontSize="16px" w="20%">
                  KEY_MAC
                </Th>
                <Th color="white" fontSize="16px">
                  FUNCTION
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {shortCutData.map((nft) => (
                <Tr>
                  <Td color="gray.200" fontSize="16px">
                    ALT +{nft.key}
                  </Td>
                  <Td color="gray.200" fontSize="16px">
                    OPTION +{nft.key}
                  </Td>
                  <Td color="gray.300" fontWeight="700" fontSize="16px">
                    {nft.function}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </Flex>
  );
};

export default Shortcuts;
