import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./UsePagination";
import "./pagination.css";
import { Text } from "@chakra-ui/react";
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 0,
    currentPage,
    pageSize,
    className,
    onNext,
    onPrevious,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  // const onNext = () => {
  //   onPageChange(currentPage + 1);
  // };

  // const onPrevious = () => {
  //   onPageChange(currentPage - 1);
  // };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames("pagination-container", { [className]: className })}
    >
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        {/* arrow left */}
        <Text bg="#434E58" p={2} borderRadius="2px">
          <svg
            width="13"
            height="13"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.7345 7.18359H3.16394L10.6878 0.652344C10.8081 0.54707 10.735 0.351562 10.5761 0.351562H8.67469C8.5909 0.351562 8.51141 0.38164 8.4491 0.435351L0.330155 7.48008C0.255795 7.54453 0.196159 7.62422 0.155287 7.71374C0.114415 7.80326 0.0932617 7.90052 0.0932617 7.99893C0.0932617 8.09733 0.114415 8.19459 0.155287 8.28411C0.196159 8.37363 0.255795 8.45332 0.330155 8.51777L8.49637 15.6055C8.52859 15.6334 8.56726 15.6484 8.60808 15.6484H10.5739C10.7329 15.6484 10.8059 15.4508 10.6856 15.3477L3.16394 8.81641H15.7345C15.829 8.81641 15.9063 8.73906 15.9063 8.64453V7.35547C15.9063 7.26094 15.829 7.18359 15.7345 7.18359Z"
              fill="white"
            />
          </svg>
        </Text>
      </li>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li
            className={classnames("pagination-item", {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        {/* arrow right */}

        <Text bg="#434E58" p={2} borderRadius="2px">
          <svg
            width="13"
            height="13"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.265549 8.8164L12.8361 8.81641L5.31223 15.3477C5.19192 15.4529 5.26496 15.6484 5.42395 15.6484L7.32531 15.6484C7.4091 15.6484 7.48859 15.6184 7.5509 15.5646L15.6698 8.51992C15.7442 8.45547 15.8038 8.37578 15.8447 8.28626C15.8856 8.19674 15.9067 8.09948 15.9067 8.00107C15.9067 7.90267 15.8856 7.80541 15.8447 7.71589C15.8038 7.62637 15.7442 7.54668 15.6698 7.48223L7.50364 0.394531C7.47141 0.366601 7.43274 0.351562 7.39192 0.351562L5.4261 0.351562C5.26711 0.351562 5.19407 0.549218 5.31438 0.652343L12.8361 7.18359L0.265549 7.18359C0.171018 7.18359 0.0936738 7.26094 0.0936738 7.35547L0.0936737 8.64453C0.0936737 8.73906 0.171017 8.8164 0.265549 8.8164Z"
              fill="white"
            />
          </svg>
        </Text>
      </li>
    </ul>
  );
};

export default Pagination;
