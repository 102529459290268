import { Box } from "@chakra-ui/react";
import { useState } from "react";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";

export default function Rating({
  rating,
  numReviews,
  displayReviewCount,
  updateRating,
  setRating,
}) {
  const [rating2, setRating2] = useState(rating);
  function ratingHandler(rat) {
    setRating2(rat);
    setRating(rat);
  }
  if (updateRating) {
    // console.log(rating);
    return (
      <Box display="flex" alignItems="baseline">
        {Array(5)
          .fill("")
          .map((_, i) => {
            const roundedRating = Math.round(rating2 * 2) / 2;
            if (roundedRating - i >= 1) {
              return (
                <BsStarFill
                  key={i}
                  style={{ marginLeft: "1" }}
                  onClick={() => ratingHandler(i + 1)}
                />
              );
            }
            return (
              <BsStar
                key={i}
                style={{ marginLeft: "1" }}
                onClick={() => ratingHandler(i + 1)}
              />
            );
          })}
      </Box>
    );
  } else {
    return (
      <Box display="flex" alignItems="baseline">
        {Array(5)
          .fill("")
          .map((_, i) => {
            const roundedRating = Math.round(rating * 2) / 2;
            if (roundedRating - i >= 1) {
              return <BsStarFill key={i} style={{ marginLeft: "1" }} />;
            }
            if (roundedRating - i === 0.5) {
              return <BsStarHalf key={i} style={{ marginLeft: "1" }} />;
            }
            return <BsStar key={i} style={{ marginLeft: "1" }} />;
          })}
        {displayReviewCount && (
          <Box as="span" ml="2" color="gray.600" fontSize="17px">
            {numReviews} review{numReviews > 1 && "s"}
          </Box>
        )}
      </Box>
    );
  }
}
