import { Flex, Heading, Image, Text, Button } from "@chakra-ui/react";

import onboardingImage from "../../resources/imgs/onboarding.png";
import beenextLogo from "../../resources/imgs/beenextLogo.png";

import icon1 from "../../resources/imgs/icon1.png";
import icon2 from "../../resources/imgs/icon2.png";
import icon3 from "../../resources/imgs/icon3.png";

const Onboarding = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <Flex
        flexDir={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
        ]}
        width="100vw"
        height={["100%", "100%", "100%", "100vh", "100vh"]}
        overflow="hidden"
        alignItems="center"
        background="bg"
      >
        <Flex
          flexDir="column"
          width={["95vw", "95vw", "95vw", "60vw", "60vw"]}
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Flex
            flexDir="column"
            width={["90%", "90%", "85%", "600px", "600px"]}
            mt={["50px", "50px", "50px", "0px", "0px"]}
            mb={["50px", "50px", "50px", "0px", "0px"]}
          >
            <Image
              src={beenextLogo}
              height="120px"
              width="200px"
              objectFit="contain"
            />

            <Heading fontSize="28px" color="white" fontFamily="fonts.primary">
              How is this KMS useful to me?
            </Heading>

            <Flex
              backgroundColor="grey3"
              width="100%"
              padding="20px"
              borderRadius="10px"
              flexDir="row"
              mt="20px"
              mb="10px"
              alignItems="center"
            >
              <Image
                src={icon1}
                height="40px"
                width="40px"
                objectFit="contain"
                m="20px"
              />
              <Text color="white" fontSize="20px" fontWeight="700" mr="20px">
                Manage project workflow, track progress, and share documents and
                data analysis!
              </Text>
            </Flex>

            <Flex
              backgroundColor="grey3"
              width="100%"
              padding="20px"
              borderRadius="10px"
              flexDir="row"
              mt="10px"
              mb="10px"
              alignItems="center"
            >
              <Image
                src={icon2}
                height="40px"
                width="40px"
                objectFit="contain"
                m="20px"
              />
              <Text color="white" fontSize="20px" fontWeight="700" mr="20px">
                Share documents, files, and collaborate with teammates and
                visualise the evolution of a project.
              </Text>
            </Flex>

            <Flex
              backgroundColor="grey3"
              width="100%"
              padding="20px"
              borderRadius="10px"
              flexDir="row"
              mt="10px"
              mb="20px"
              alignItems="center"
            >
              <Image
                src={icon3}
                height="40px"
                width="40px"
                objectFit="contain"
                m="20px"
              />
              <Text color="white" fontSize="20px" fontWeight="700" mr="20px">
                Preserve the logical progression of a project in the form of a
                sequence of established findings backed by a tiered approval
                system.
              </Text>
            </Flex>

            <Button
              bg="yellow"
              width="250px"
              height="50px"
              p="2px"
              as="a"
              href="/register"
              mt={["0px", "0px", "0px", "20px", "20px"]}
              mb={["20px", "20px", "20px", "0px", "0px"]}
              color="white"
              fontSize="16px"
              _hover={{
                background: "#F2DBA6",
              }}
              mr="10px"
            >
              Click here to continue
            </Button>
          </Flex>
        </Flex>

        <Flex
          flexDir="column"
          width={["100vw", "100vw", "100vw", "45vw", "45vw"]}
          height="100%"
        >
          <Image
            src={onboardingImage}
            objectFit="cover"
            height={["200px", "200px", "200px", "100%", "100%"]}
            width="100%"
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default Onboarding;
