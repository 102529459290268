import { extendTheme } from '@chakra-ui/react';

const theme = {
   fonts: {
    heading: `'Open Sans', sans-serif`,
    body: `'Raleway', sans-serif`,
  },
   colors: {
       main:{
        text:'#FFC949',
        searchArea:'#C1962F',
        grey1:'#A0AFBF',
        plainWhite:'#fff',
        white:'#000',
        grey3:'#505E69',
       },
       background:{
        main:'#2D3842',
        grey3:'#505E69',
        yellow1:'#C1962F',
        grey1:'#A0AFBF',
        yellow:'#FFC949',
        dark:'#2D3842',
        grey0:'#3D454C',
        grey2:'#646B72',
        grey5:'#3B454D',
        grey4:'#949494',
        gold:'#B99000',
        silver:'#A2A2A2',
        bronze:'#945532'
       }
   },

}

export default extendTheme(theme);