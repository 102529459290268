import { useState, useEffect, useContext } from "react";
import ReactFlow, { Background, Controls, MiniMap } from "reactflow";
import "reactflow/dist/style.css";
import {
  Flex,
  Menu,
  MenuButton,
  NumberDecrementStepper,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInput,
  MenuList,
  MenuItem,
  Button,
  Text,
  Tag,
  Spinner,
  Slider,
  SliderTrack,
  Box,
  SliderFilledTrack,
  Tooltip,
  SliderThumb,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react";
import { Url } from '../../store/global';

import AuthContext from "../../store/auth";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import Breadcrumbs from "../../components/BreadCrumbs";

const initialNodes = [];
const initialEdges = [];

const LinkParent = () => {
  const toast = useToast();

  const authCtx = useContext(AuthContext);
  let { projectSlug, pageSlug } = useParams();
  const [level, setLevel] = useState(1);

  const logoutHandler = () => {
    authCtx.logout();
  };

  const [captureElementClick] = useState(true);

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [projectInfo, setProjectInfo] = useState();

  const [sliderValue, setSliderValue] = useState(
    JSON.parse(window.localStorage.getItem("zoomSlider"))
      ? JSON.parse(window.localStorage.getItem("zoomSlider"))
      : 0
  );
  const [showTooltip, setShowTooltip] = useState(false);

  const onNodeClick = (event, node) => {
    linkPage(node.id);
  };

  const getProjectInformation = () => {
    fetch({Url}.Url+"/flowchart/" + projectSlug, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            // console.log(res.data);
            setProjectInfo(res.data.project);
            setNodes(res.data.initialNodes);
            setEdges(res.data.initialEdges);
          } else {
            toast({
              title: "Oops, something went wrong!",
              description: res.data.detail,
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
            // swal('Oops, something went wrong!', res.data.detail, 'error');
          }
        })
    );
  };

  const linkPage = (child) => {
    fetch({Url}.Url+"/link_pages/", {
      method: "POST",
      body: JSON.stringify({
        page_id: child,
        depends_on: pageSlug,
        level: level,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            window.location = "/page/" + pageSlug;
            toast({
              title: "Page Linked Successfully!",
              description: "Let's head back to the project.",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
            // swal('Page Linked Successfully!', "Let's head back to the project.", 'success');
          } else {
            toast({
              title: "Registration failed.",
              description: "Something went wrong.",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
            // swal('Registration failed.', 'Something went wrong.', 'error');
          }
        })
    );
  };

  const nodeColor = (node) => {
    switch (node.style.backgroundColor) {
      case "#984444":
        return "#ff0072";
      case "#2C6D3E":
        return "#6ede87";
      default:
        return "#5c6e7a ";
    }
  };

  const handleSlider = (value) => {
    // console.log(value);
    setSliderValue(value);
    switch (value) {
      case 0: {
        fontChangeHandler(150, 10);
        break;
      }
      case 20: {
        fontChangeHandler(167, 14);

        break;
      }
      case -20: {
        fontChangeHandler(150, 9);
        break;
      }
      case 40: {
        fontChangeHandler(182, 17);
        break;
      }
      case -40: {
        fontChangeHandler(150, 7);
        break;
      }
      case 60: {
        fontChangeHandler(195, 20);
        break;
      }
      default: {
        break;
      }
    }
  };

  const fontChangeHandler = (width, value) => {
    if (nodes && nodes[0].style) {
      setNodes(
        nodes.map((node) => {
          node.style = {
            ...node.style,
            fontSize: value,
            width: width,
          };
          return node;
        })
      );
    }
  };

  useEffect(() => {
    window.localStorage.setItem("zoomSlider", JSON.stringify(sliderValue));
    // console.log(sliderValue);
  }, [sliderValue]);
  useEffect(() => {
    nodes.length > 0 && handleSlider(sliderValue);
  }, [edges]);
  // runs on initial page load
  useEffect(() => {
    getProjectInformation();
    // eslint-disable-next-line
  }, []);

  const breadcrumbs = [
    { path: "/", breadcrumb: "Home" },
    { path: "/library", breadcrumb: "Library" },
    { path: "/project/" + projectSlug, breadcrumb: projectInfo?.title },
    {
      path: "link-parent/" + projectSlug + "/" + pageSlug,
      breadcrumb: "Link Parent",
      end: true,
    },
  ];

  return (
    <Flex flexDir="column" alignItems="center" width="100%">
      {projectInfo ? (
        <Flex
          width={{ md: "93%" }}
          height="fit-content"
          flexDir="column"
          justifyContent="space-between"
          px="10px"
        >
          <Breadcrumbs breadcrumbs={breadcrumbs} />

          <Flex
            width="100%"
            height="fit-content"
            flexDir="column"
            justifyContent="space-between"
            p="10px"
            mt="20px"
          >
            <Text color="white" fontWeight="700" fontSize="20px">
              Project Pyramid
            </Text>
            <Flex alignItems="center">
              <Text color="main.grey1" fontWeight="500" fontSize="16px">
                Fix block to level: &nbsp;
              </Text>
              <Text color="white">
                <NumberInput
                  size="sm"
                  maxW={24}
                  onChange={(e) => setLevel(e)}
                  defaultValue={1}
                  min={0}
                  borderColor="grey1"
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper color="grey1" />
                    <NumberDecrementStepper color="grey1" />
                  </NumberInputStepper>
                </NumberInput>
              </Text>
            </Flex>
            <Flex
              height="520px"
              // width="1050px"
              flexDir="row"
              justifyContent="space-between"
              py="10px"
              mt="10px"
            >
              <ReactFlow
                nodes={nodes}
                edges={edges}
                proOptions={{ hideAttribution: true }}
                onNodeClick={captureElementClick ? onNodeClick : undefined}
                fitView
                panOnScroll={true}
              >
                <Flex mt="2" padding="12px" width="100px">
                  <Slider
                    defaultValue={sliderValue}
                    min={-40}
                    max={60}
                    step={20}
                    zIndex="1000"
                    aria-label="slider-ex-3"
                    orientation="vertical"
                    minH="40"
                    onChange={handleSlider}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                  >
                    <SliderTrack>
                      <Box position="relative" right={10} />
                      <SliderFilledTrack bg="#C3962C" />
                    </SliderTrack>
                    <Tooltip
                      hasArrow
                      bg="#444d54"
                      color="white"
                      placement="right"
                      isOpen={showTooltip}
                      label={`${100 + sliderValue}%`}
                    >
                      <SliderThumb boxSize={5} bg="#C3962C" />
                    </Tooltip>
                  </Slider>
                </Flex>

                {/* <MiniMap
                  display="none"
                  style={{ height: '120', width: '200', zIndex: '1000' }}
                  nodeStrokeWidth={3}
                  zoomable
                  nodeBorderRadius={5}
                  nodeColor={nodeColor}
                  pannable
                  maskStrokeColor="rgb(0,0,0, 0.4)"
                  maskColor="rgb(0,0,0, 0.1)"
                /> */}

                <Background />

                <Controls />
              </ReactFlow>
            </Flex>
          </Flex>

          <Flex
            height="fit-content"
            flexDir="row"
            justifyContent="end"
            p="10px"
            mt="10px"
            width="95%"
          >
            <Button
              bg="#444d54"
              width="250px"
              p="2px"
              mb={["20px", "20px", "20px", "10px", "10px"]}
              color="white"
              fontSize="16px"
              _hover={{
                background: "#F2DBA6",
              }}
              mr="10px"
              as="a"
              href={"/new-page/" + projectSlug}
            >
              Click to add a new block
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Spinner />
      )}
    </Flex>
  );
};

export default LinkParent;
