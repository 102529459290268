import React, { useContext } from "react";
import AuthContext from "../../store/auth";

const GoogleLoginRedirect = () => {
  const authCtx = useContext(AuthContext);
  const searchParams = new URLSearchParams(window.location.search);
  const data = {};
  Array.from(searchParams.entries()).forEach(([key, value]) => {
    data[key] = value;
  });
  console.log(data);
  authCtx.login(data.key);
  authCtx.updateUserInfo(data.name);
  authCtx.updateRole(data.role);
  window.location = "/dashboard";
  return <div></div>;
};

export default GoogleLoginRedirect;
