import React, { useState } from "react";
import { useEffect, useRef, useContext } from "react";
import S3FileUpload from "react-s3";
import { Buffer } from "buffer";
import { Switch, useToast } from "@chakra-ui/react";
import { Url } from "../../store/global";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";

import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";

import AuthContext from "../../store/auth";
import swal from "sweetalert";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-full";

const S3_BUCKET = "kms-assets";
const REGION = "us-east-1";
const ACCESS_KEY = "AKIAQTH52T33TY6UHF7L";
const SECRET_ACCESS_KEY = "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";

const NewPage = () => {
  const toast = useToast();
  const collapseRef = useRef("none");

  // authentication module
  const authCtx = useContext(AuthContext);

  const [value, setValue] = useState(
    "<p><strong><u>Strategy Template</u></strong></p><p>&nbsp;</p><p>Key Questions to be answered:&nbsp;</p><p>1. &lt;binary question&gt;&nbsp;</p><p>2. &lt;binary question&gt;&nbsp;</p><p>3. &lt;binary question&gt;&nbsp;</p><p>&nbsp;</p><p>Approach:&nbsp;</p><p>1. &lt;...&gt;&nbsp;</p><p>2. &lt;...&gt;&nbsp;</p><p>3. &lt;...&gt;&nbsp;</p><p>&nbsp;</p><p>Conclusions:&nbsp;</p><p>1. &lt;...&gt;&nbsp;</p><p>2. &lt;...&gt;&nbsp;</p><p>3. &lt;...&gt;</p><p>&nbsp;</p><hr><p><strong><u>PMO Template</u></strong></p><p>&nbsp;</p><p>Background</p><p>&nbsp;</p><p>Scope</p><p>&nbsp;</p><p>Key People</p><p>&nbsp;</p><p>Deliverables &amp; Dates</p>"
  );
  const [data, setData] = useState();

  const editorHandler = (e, editor) => {
    // console.log(editor.getData());
    setValue(editor.getData());
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  window.Buffer = Buffer;

  const [filel, setFilel] = useState(false);

  const logoutHandler = () => {
    authCtx.logout();
  };

  const [fiti, setFiti] = useState(null);

  let { pageSlug } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [textToggle, setTextToggle] = useState(false);
  const [sheetToggle, setSheetToggle] = useState(false);
  const [collapse, setCollapse] = useState("both");

  const [sheetData, setSheetData] = useState([
    {
      name: "Cell",
      id: "0",
      zoomRatio: 1,
      order: "0",
      column: 18,
      addRows: 70,
      row: 36,
      status: 1,
      celldata: [
        {
          r: 0,
          c: 0,
          v: {
            v: "",
            ct: {
              fa: "General",
              t: "g",
            },
            m: "",
          },
        },
      ],
      ch_width: 2321,
      rh_height: 936,
      luckysheet_select_save: [
        {
          left: 741,
          width: 138,
          top: 796,
          height: 19,
          left_move: 741,
          width_move: 138,
          top_move: 796,
          height_move: 19,
          row: [33, 33],
          column: [6, 6],
          row_focus: 33,
          column_focus: 6,
        },
      ],
      calcChain: [
        {
          r: 0,
          c: 3,
          id: "0",
          func: [true, 3, "=Formula!A1+Formula!B1"],
          color: "w",
          parent: null,
          chidren: {},
          times: 0,
        },
      ],
      scrollLeft: 0,
      scrollTop: 0,
    },
  ]);
  const [fileName, setFileName] = useState("");

  const onCloseHandler = () => {
    setFileName("");
    onClose();
  };

  function handleDragOver(event) {
    event.preventDefault();
    setIsDragging(true);
  }

  function handleDragLeave(event) {
    event.preventDefault();
    setIsDragging(false);
  }

  function handleFileDrop(event) {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    handleFileInput(file);
  }

  const onFileChange = (file) => {
    const config = {
      bucketName: S3_BUCKET,
      dirName: "media/public/uploads",
      region: REGION,
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        // console.log(data);
        // console.log(data.key);
        let a = data.key.lastIndexOf("/") + 1;
        let b = data.key.substring(a);
        // console.log(b);
        setFiti(b);
        setFilel(false);
        setFileURL(data.location);
      })
      .catch((err) => {
        alert(err);
        return null;
      });
  };

  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) setInitialRender(false);
    else {
      onFileChange(selectedFile);
      setFilel(true);
    }
    // eslint-disable-next-line
  }, [selectedFile]);
  // useEffect(() => {
  //  setCollapseValue();

  // }, [textToggle, sheetToggle]);

  // new project modal
  const title = useRef();

  const [files, setFiles] = useState([]);

  const handleNewFile = () => {
    const prev = files;
    prev.push({
      title: fiti,
      description: descRef.current.value,
      file: fileURL,
    });
    setFiles(prev);
    setFileURL(null);
    onClose();
  };

  const descRef = useRef();
  const [fileURL, setFileURL] = useState(null);

  const handleFileInput = (e) => {
    setFileName(e.name);

    setSelectedFile(e);

    // console.log(e);
  };

  // function setCollapseValue(){
  //   if(textToggle===true && sheetToggle===true)
  //   setCollapse("none");
  //   else if(textToggle===false && sheetToggle===true)
  //   setCollapse("document")
  //   else if(textToggle===true && sheetToggle===false)
  //   setCollapse("spreadsheet")
  //   else if(textToggle===false && sheetToggle===false)
  //   setCollapse("both")

  //   console.log(collapse);
  // }

  function createPage() {
    const rtitle = title.current.value;
    if (rtitle) {
      fetch({ Url }.Url + "/create_page/", {
        method: "POST",
        body: JSON.stringify({
          project_id: pageSlug,
          title: rtitle,
          key_findings: value.toString("html"),
          files: files,
          key_findings_spreadsheet: data,
          collapse_value: collapse,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            if (res.status < 300) {
              toast({
                title: "Block Created Successfully!",
                description: "Let's head over to the new block.",
                status: "success",
                duration: 2000,
                isClosable: true,
                position: "top-right",
                onCloseComplete: () => {
                  window.location = "/page/" + res.data.page_id;
                },
              });
              // swal('Block Created Successfully!', "Let's head over to the new block.", 'success');
              // window.location = '/page/' + res.data.page_id;
            } else {
              toast({
                title: "Creation failed.",
                description: "Something went wrong.",
                status: res.detail,
                duration: 2000,
                isClosable: true,
                position: "top-right",
              });

              // swal('Creation failed.', 'Something went wrong.', res.detail);
            }
          })
      );
    } else {
      toast({
        title: "Oops, something went wrong!",
        description: "Title not specified",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      // swal('Oops, something went wrong!', 'Title not specified', 'error');
    }
  }

  const descRef2 = useRef();
  const fiTitle = useRef();
  const fileRef = useRef();

  function onChangeSheet(d) {
    // {d?setData(d.current.value):<></>};
    if (d) setData(d);
    console.log(data);
    // console.log(data[0].data)/
    // const newd = data[0].data
    // console.log(convert2DTo1D(newd))
  }
  const getTextToggle = () => {
    setTextToggle(!textToggle);
    setCollapse(calculateData(!textToggle, sheetToggle));
    console.log(textToggle, sheetToggle, collapse);
  };

  const getSheetToggle = () => {
    setSheetToggle(!sheetToggle);
    setCollapse(calculateData(textToggle, !sheetToggle));
    console.log(textToggle, sheetToggle, collapse);
  };

  const calculateData = (buttonAState, buttonBState) => {
    if (buttonAState && buttonBState) {
      return "none";
    } else if (buttonAState) {
      return "spreadsheet";
    } else if (buttonBState) {
      return "document";
    } else {
      return "both";
    }
  };

  // useEffect(() => {
  //   // Update toggleData based on buttonA and buttonB state

  //   if (textToggle && sheetToggle) {
  //     setCollapse('none');
  //   } else if (textToggle) {
  //     setCollapse('A');
  //   } else if (sheetToggle) {
  //     setCollapse('B');
  //   } else {
  //     setCollapse('both');
  //   }
  //   console.log(textToggle, sheetToggle, collapse)
  // }, [textToggle, sheetToggle]);
  // function getTextToggle(){
  //   if(textToggle===false)
  //   {
  //     setTextToggle(true);
  //     setCollapseValue();
  //   }
  //   else
  //   {
  //     setTextToggle(false);
  //     setCollapseValue();
  //   }
  // console.log(textToggle)
  // }

  // function getSheetToggle(){

  //   if(sheetToggle===false){
  //   setSheetToggle(true);
  //   setCollapseValue();
  //   }
  //   else{
  //   setSheetToggle(false)
  //   setCollapseValue();
  // }
  // console.log(sheetToggle)

  // }

  function sendGoogleDoc() {
    const abc = fileRef.current.value;
    const def = descRef2.current.value;
    const xyz = fiTitle.current.value;

    // fetch({Url}.Url+"/add_google_doc" + "/", {
    //   method: "POST",
    //   body: JSON.stringify({
    //     page_id: pageSlug,
    //     file: abc,
    //     description: def,
    //     title: xyz,
    //   }),
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Token " + authCtx.token,
    //   },
    // }).then((response) =>
    //   response
    //     .json()
    //     .then((data) => ({
    //       data: data,
    //       status: response.status,
    //     }))
    //     .then((res) => {
    //       if (res.status < 300) {
    //         swal(
    //           "Doc added Successfully!",
    //           "Let's head back to editing..",
    //           "success"
    //         );
    //         window.location.reload();
    //       } else {
    //         swal("Adding file failed.", res.data.detail, "error");
    //       }
    //     })
    // );
    const prev = files;
    prev.push({
      title: xyz,
      description: def,
      file: abc,
    });
    setFiles(prev);
    onClose2();
  }
  // HTML element tree ̦— render function
  return (
    <Flex flexDir="column" alignItems="center" width="100%">
      <Flex
        width={{ base: "100%", lg: "95%" }}
        height="fit-content"
        flexDir="column"
        justifyContent="space-between"
        px="10px"
      >
        <Flex flexDir="row" justifyContent="space-between" mt="10px">
          <Flex flexDir="column">
            <Flex flexDir="row">
              <Text color="white" fontSize="24px" fontWeight="700">
                Create a&nbsp;
              </Text>
              <Text color="main.text" fontSize="24px" fontWeight="700">
                New Base Block
              </Text>
            </Flex>
            <Text color="main.grey1" fontSize="18px" fontWeight="700">
              Please add critical information before adding this new base block.
            </Text>
          </Flex>
        </Flex>

        <Flex flexDir={{ base: "column", xl: "row" }}>
          <Flex flexDir="column">
            <Flex justifyContent="space-between" alignItems="center">
              <Flex width="80%">
                <FormControl mt="20px">
                  <FormLabel color="white" fontSize="18px" fontWeight="700">
                    Title of the Base Block
                  </FormLabel>
                  <Input
                    id="name"
                    bg="background.grey3"
                    color="white"
                    fontWeight="600"
                    focusBorderColor="white"
                    width={{ base: "100%", xl: "80%" }}
                    border="none"
                    size="lg"
                    type="text"
                    placeholder="Enter block name here"
                    autoComplete="off"
                    ref={title}
                    maxLength="40"
                  />
                </FormControl>
              </Flex>

              {/* <Flex>
                <Select
                  width="170px"
                  color="main.plainWhite"
                  mt="12"
                  bg={"background.grey3"}
                  mr="20vw"
                  placeholder="Collapse Option"
                  ref={collapseRef}
                >
                  <option value="spreadsheet">spreadsheet</option>
                  <option value="document">document</option>
                  <option value="none">none</option>
                </Select>
              </Flex> */}
            </Flex>
            <Flex>
              <Flex>
                <FormControl mt="20px" className="editPageForm">
                  <FormLabel color="white" fontSize="18px" fontWeight="700">
                    Key Findings
                  </FormLabel>
                  {/* <Textarea
              id="name"
              bg="grey3"
              color="white"
              fontWeight="600"
              focusBorderColor="white"
              border="none"
              size="lg"
              height="250px"
              width="80%"
              type="text"
              placeholder="Enter your Key Findings here"
              autocomplete="off"
              ref={keyFindings}
            /> */}
                  <Flex className="ckparent">
                    <CKEditor
                      data={value}
                      editor={ClassicEditor}
                      config={{
                        typing: {
                          transformations: {
                            remove: [
                              "symbols",
                              "quotes",
                              "arrowLeft",
                              "arrowRight",
                              "mathematical",
                              "typography",
                            ],
                          },
                        },

                        toolbar: {
                          items: [
                            "heading",
                            "|",
                            "fontfamily",
                            "fontsize",
                            "fontColor",
                            "fontBackgroundColor",
                            "|",
                            "alignment",
                            "insertTable",
                            "|",
                            "bold",
                            "italic",
                            "strikethrough",
                            "underline",
                            "subscript",
                            "superscript",
                            "|",
                            "link",
                            "|",
                            "bulletedList",
                            "numberedList",
                            "todoList",
                            "undo",
                            "redo",
                            "highlight",
                            "|",
                            "horizontalLine",
                            "removeFormat",
                            "|",
                          ],
                        },
                      }}
                      onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                          writer.setAttribute(
                            "spellcheck",
                            "false",
                            editor.editing.view.document.getRoot()
                          );
                          writer.setAttribute(
                            "autocomplete",
                            "off",
                            editor.editing.view.document.getRoot()
                          );
                          writer.setAttribute(
                            "autocorrect",
                            "off",
                            editor.editing.view.document.getRoot()
                          );
                        });
                      }}
                      onChange={(event, editor) => {
                        editorHandler(event, editor);
                      }}
                    />
                  </Flex>
                </FormControl>
              </Flex>
            </Flex>
            <Flex>
              <Flex>
                {" "}
                {/*  */}
                {/* <FormControl
                  mt={"20px"}
                  style={{ width: "55vw", height: "70vh" }}
                >
                  <div
                    style={{ width: "100%", height: "70vh" }}
                    className="sheet-editor2"
                  >
                    <Workbook
                      data={sheetData}
                      onChange={(data) => {
                        onChangeSheet(data);
                      }}
                    />
                  </div>
                </FormControl> */}
              </Flex>
            </Flex>
          </Flex>
          {/* Spreadsheet Commit */}
          {/* <Flex flexDir="column" justifyContent={"space-evenly"}>
            <Flex
              ml={"-90px"}
              mt={"-40vh"}
              color="main.plainWhite"
              justifyContent="space-between"
              alignItems="center"
            >
              <Switch isChecked={textToggle} onChange={getTextToggle} />
            </Flex>
            <Flex
              ml={"-90px"}
              mt={"10vh"}
              color="main.plainWhite"
              justifyContent="space-between"
              alignItems="center"
            >
              <Switch isChecked={sheetToggle} onChange={getSheetToggle} />
            </Flex>
          </Flex> */}
          <Flex flexDir="column">
            <Text color="white" fontSize="18px" fontWeight="700" mb="10px">
              Add Files
            </Text>

            {files
              ? files.map((file, id) => (
                  <>
                    <Flex
                      flexDir="column"
                      as="a"
                      href={file.file}
                      target="_blank"
                      rel="noreferrer"
                      bg="grey3"
                      p="20px"
                      borderRadius="10px"
                      mb="10px"
                    >
                      <Flex flexDir="row">
                        <Text
                          color="grey1"
                          fontSize="18px"
                          lineHeight="1.3"
                          fontWeight="700"
                        >
                          {id + 1}. Name:&nbsp;
                        </Text>
                        <Text
                          color="white"
                          fontSize="18px"
                          lineHeight="1.3"
                          fontWeight="700"
                        >
                          {file.title}
                        </Text>
                      </Flex>
                      <Flex flexDir="row">
                        <Text
                          color="grey1"
                          fontSize="18px"
                          lineHeight="1.3"
                          fontWeight="700"
                        >
                          Description:&nbsp;
                        </Text>
                        <Text
                          color="white"
                          fontSize="18px"
                          lineHeight="1.3"
                          fontWeight="700"
                        >
                          {file.description}
                        </Text>
                      </Flex>
                    </Flex>
                  </>
                ))
              : null}
            <Flex
              flexDir={{ base: "column", sm: "row" }}
              justifyContent="space-between"
            >
              <Button
                mt="10px"
                mr={{ sm: "10px" }}
                bg="background.grey3"
                color="white"
                onClick={onOpen}
              >
                Upload New File
              </Button>
              <Button
                mt="10px"
                bg="background.grey3"
                color="white"
                onClick={onOpen2}
              >
                Add Link to new File
              </Button>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          height="fit-content"
          flexDir={{ base: "column", sm: "row" }}
          justifyContent="space-between"
          mt="10px"
          width="100%"
        >
          <Button
            bg="background.yellow1"
            px={3}
            py={1}
            color="white"
            fontSize="16px"
            onClick={createPage}
            _hover={{
              background: "#F2DBA6",
            }}
          >
            Add this block
          </Button>
        </Flex>
      </Flex>
      <>
        <Modal bg="#444d54" isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay BG="#2A3843" />
          <ModalContent bg="#444d54" color="white">
            <ModalHeader>Add File</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontWeight="700" fontSize="18px" mb="5px">
                Upload File
              </Text>
              <label
                for="file_upload"
                style={{
                  borderColor: "#6c757d",
                  display: "block",
                  width: "100%",
                  borderWidth: "2px",
                  borderStyle: "dashed",
                  borderRadius: "5px",
                  padding: "10px",
                  textAlign: "center",
                  fontSize: "1rem",
                  cursor: "pointer",
                  color: "#6c757d",
                }}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleFileDrop}
              >
                {fileName
                  ? "File uploaded: " + fileName
                  : "Select or drag a file"}
              </label>{" "}
              <Input
                id="file_upload"
                type="file"
                onChange={(e) => handleFileInput(e.target.files[0])}
              />
              <Text fontWeight="700" fontSize="18px" mb="5px">
                Enter file description
              </Text>
              <Input placeholder="Enter file description" ref={descRef} />
            </ModalBody>

            <ModalFooter>
              <Button
                bg="yellow1"
                mr={3}
                isDisabled={fileURL === null}
                onClick={handleNewFile}
              >
                {filel ? "Uploading..." : "Submit"}
              </Button>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
      <>
        <Modal isOpen={isOpen2} onClose={onClose2} isCentered>
          <ModalOverlay />
          <ModalContent bg="grey3" color="white">
            <ModalHeader>Link to file</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontWeight="700" fontSize="18px" mb="5px" mt="10px">
                Enter file title
              </Text>
              <Input
                placeholder="Enter file title"
                ref={fiTitle}
                maxLength="40"
              />
              <Text fontWeight="700" fontSize="18px" mb="5px" mt="10px">
                Link to file
              </Text>
              <Input placeholder="Enter file link" ref={fileRef} />
              <Text fontWeight="700" fontSize="18px" mb="5px" mt="10px">
                Enter file description
              </Text>
              <Input placeholder="Enter file description" ref={descRef2} />
            </ModalBody>

            <ModalFooter>
              <Button bg="yellow1" mr={3} onClick={sendGoogleDoc}>
                Submit
              </Button>
              <Button variant="ghost" onClick={onClose2}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </Flex>
  );
};

export default NewPage;
