import {
  Flex,
  Heading,
  Image,
  FormControl,
  FormLabel,
  Input,
  Text,
  Button,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { Form, Link } from "react-router-dom";
import { useRef, useContext, useState, useCallback } from "react";
import swal from "sweetalert";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import GoogleButton from "react-google-button";
import { useToast } from "@chakra-ui/react";

import onboardingImage from "../../resources/imgs/onboarding.png";
import beenextLogo from "../../resources/imgs/beenextLogo.png";
import line from "../../resources/imgs/line.png";
import { UrlBase } from '../../store/global';

import AuthContext from "../../store/auth";

const Login = () => {
  const toast = useToast();
  const REACT_APP_GOOGLE_CLIENT_ID =
    "718425404135-9eb423mghh0lsds44e3prpeo863goji7.apps.googleusercontent.com";
  const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    const redirectUri = "api/users/google-oauth2-callback/";
    const REACT_APP_BASE_BACKEND_URL = {UrlBase}.UrlBase;

    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/drive",
      "https://www.googleapis.com/auth/drive.file",
      "https://www.googleapis.com/auth/drive.metadata",
      "https://www.googleapis.com/auth/drive.metadata.readonly",
    ].join(" ");

    const params = {
      response_type: "code",
      client_id: REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: `${REACT_APP_BASE_BACKEND_URL}${redirectUri}`,
      prompt: "consent", // "consent" will ensure a refresh token is returned
      access_type: "offline", // "offline" means a refresh token will be included in the auth response
      scope,
    };

    const urlParams = new URLSearchParams(params).toString();

    window.location = `${googleAuthUrl}?${urlParams}`;
  }, []);

  const authCtx = useContext(AuthContext);
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const [show, setShow] = useState(false);

  function loginHandler() {
    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    if (enteredEmail && enteredPassword) {
      fetch({UrlBase}.UrlBase+"/api/users/login/", {
        method: "POST",
        body: JSON.stringify({
          email: enteredEmail,
          password: enteredPassword,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            if (res.data.status) {
              // console.log(res.data);
              authCtx.login(res.data.key);
              authCtx.updateUserInfo(res.data.user.name);
              authCtx.updateRole(res.data.user.role);
              window.location = "/dashboard";
            } else {
              toast({
                title: "Login failed.",
                description: res.data.detail,
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top-right",
              });
            }
          })
      );
    }
  }

  function GoogleLoginHandler(cr) {
    let access_token = cr.credential;
    // console.log(access_token);
    fetch({UrlBase}.UrlBase+"/api/users/oauth/google/", {
      method: "POST",
      body: JSON.stringify({
        id_token: access_token,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            // console.log(res.data);
            authCtx.login(res.data.key);
            authCtx.updateUserInfo(res.data.user.name);
            authCtx.updateRole(res.data.user.role);
            window.location = "/dashboard";
          } else {
            toast({
              title: "Login failed.",
              description: res.data.detail,
              status: "error",
              position: "top-right",
              duration: 2000,
              isClosable: true,
            });
          }
        })
    );
  }

  const onGoogleLoginSuccess = useCallback((response) => {
    const idToken = response.tokenId;
    //   const data = {
    //     email: response.profileObj.email,
    //     first_name: response.profileObj.givenName,
    //     last_name: response.profileObj.familyName
    //   };
    // console.log(response);

    //     .then(handleUserInit)
    //     .catch(notifyError);
  }, []);

  return (
    <div>
      <Flex
        flexDir={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
          "row",
        ]}
        width="100vw"
        height={["100%", "100%", "100%", "100vh", "100vh"]}
        overflow="hidden"
        alignItems="center"
        background="bg"
      >
        <Flex
          flexDir="column"
          width={["95vw", "95vw", "95vw", "60vw", "60vw"]}
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Flex
            flexDir="column"
            width={["90%", "90%", "90%", "600px", "600px"]}
            mt={["50px", "50px", "50px", "0px", "0px"]}
            mb={["50px", "50px", "50px", "0px", "0px"]}
          >
            <Image
              src={beenextLogo}
              height="150px"
              width="300px"
              objectFit="contain"
            />

            <Heading fontSize="28px" color="white" fontFamily="fonts.primary">
              Login
            </Heading>

            <Text
              fontSize="18px"
              color="#A0AEC0"
              fontFamily="fonts.primary"
              mt="10px"
              mb="25px"
              fontWeight="700"
            >
              Welcome to the FJ Knowledge Management System.
            </Text>

            {/* <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
                <GoogleLogin
                    buttonText="Sign in with Google"
                    onSuccess={onGoogleLoginSuccess}
                    onError={() => {
                      // console.log('Login Failed');
                    }}
                />;
              </GoogleOAuthProvider> */}
            <GoogleButton
              onClick={openGoogleLoginPage}
              label="Sign in with Google"
              disabled={!REACT_APP_GOOGLE_CLIENT_ID}
            />

            {/* <GoogleLogin
        render={renderProps => <GoogleButton {...renderProps} />}
        // clientId={REACT_APP_GOOGLE_CLIENT_ID}
        buttonText="Sign in with Google"
        onSuccess={onGoogleLoginSuccess}
        // onFailure={({ details }) => notifyError(details)}
      /> */}
            <Flex
              flexDir="row"
              justifyContent="space-between"
              alignItems="center"
              width="99%"
              mt="25px"
            >
              <Image
                src={line}
                objectFit="cover"
                width={["20%", "20%", "35%", "35%"]}
                opacity="50%"
              />
              <Text
                fontSize="14px"
                fontWeight="700"
                color="#A0AEC0"
                fontFamily="fonts.primary"
              >
                or Sign In with Email
              </Text>
              <Image
                src={line}
                objectFit="cover"
                width={["20%", "20%", "35%", "35%"]}
                opacity="50%"
              />
            </Flex>
            <FormControl mt="20px">
              <FormLabel color="main.grey1" fontWeight="700" htmlFor="email">
                Email address*
              </FormLabel>
              <Input
                id="email"
                bg="background.grey3"
                color="white"
                fontWeight="600"
                focusBorderColor="white"
                border="none"
                type="email"
                placeholder="john@fischerjordan.com"
                autocomplete="off"
                ref={emailInputRef}
              />
            </FormControl>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                loginHandler();
              }}
            >
              <FormControl mt="10px">
                <FormLabel color="main.grey1" fontWeight="700" htmlFor="email">
                  Password*
                </FormLabel>
                <InputGroup>
                  <Input
                    id="password"
                    bg="background.grey3"
                    color="white"
                    focusBorderColor="white"
                    fontWeight="600"
                    border="none"
                    placeholder="Minimum 6 characters"
                    autocomplete="off"
                    type={show ? "text" : "password"}
                    ref={passwordInputRef}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                  <InputRightElement width="4.5rem" bg="grey3">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => setShow(!show)}
                    >
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>

                <Flex
                  flexDir="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mt="25px"
                  mb={["50px", "50px", "50px", "0px", "0px"]}
                >
                  <Link to="/onboarding">
                    <Flex flexDir="row">
                      <Text
                        fontSize="16px"
                        color="white"
                        fontWeight="700"
                        fontFamily="fonts.primary"
                        mr="5px"
                      >
                        Create a new account here!
                      </Text>
                    </Flex>
                  </Link>

                  <Link to="/forgot-password">
                    <Text
                      fontSize="16px"
                      color="main.text"
                      fontWeight="700"
                      fontFamily="fonts.primary"
                    >
                      Forgot password?
                    </Text>
                  </Link>
                </Flex>

                <Button
                  bg="background.yellow1"
                  width="230px"
                  p="2px"
                  mt={["0px", "0px", "60px", "60px"]}
                  mb={["20px", "20px", "0px", "0px"]}
                  color="white"
                  fontSize="16px"
                  onClick={loginHandler}
                  _hover={{
                    background: "#F2DBA6",
                  }}
                  type="submit"
                >
                  Login to your Dashboard
                </Button>
              </FormControl>
            </form>
          </Flex>
        </Flex>

        <Flex
          flexDir="column"
          width={["100vw", "100vw", "100vw", "45vw", "45vw"]}
          padding="30px"
          height="100%"
        >
          <Image
            src={onboardingImage}
            objectFit="cover"
            borderRadius="15px"
            height={["200px", "200px", "200px", "100%", "100%"]}
            width="100%"
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default Login;
