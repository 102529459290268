import {
  ChevronDownIcon,
  CloseIcon,
  HamburgerIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Title_NavBar, UrlBase } from "../store/global";
import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../store/auth";


export const Navbar = (props) => {
  console.log({UrlBase}.UrlBase)
  const toast = useToast();
  const isProjectPage = props.projectSlug ? true : false;
  const authCtx = useContext(AuthContext);
  const logoutHandler = () => {
    authCtx.logout();
  };
  const [activeButton, setActiveButton] = useState(
    window.location.pathname.split("/")[1]
  );
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const searchRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);

  function toggleMenu() {
    setIsOpen(!isOpen);
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const onEscape = (e) => {
    if (
      e.key === "Escape" ||
      (searchRef.current && !searchRef.current.contains(e.target))
    ) {
      searchRef?.current?.blur();
      setSearch("");
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onEscape);
    document.addEventListener("click", onEscape);

    return () => {
      document.removeEventListener("keydown", onEscape);
      document.removeEventListener("click", onEscape);
    };
  }, []);

  const searchHandler = (value) => {
    let url =
      {UrlBase}.UrlBase+"api/core/search/?size=10&q=" +
      value +
      "&type=project";
    if (isProjectPage)
      url =
        {UrlBase}.UrlBase+"api/core/search/?q=" +
        value +
        "&type=page&project=" +
        props?.projectSlug;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            // function sortByDate(jsonArray, dateKey) {
            //   return jsonArray.sort(function(a, b) {
            //     var dateA = new Date(a[dateKey]).getTime();
            //     var dateB = new Date(b[dateKey]).getTime();
            //     return dateA > dateB ? -1 : 1;
            //   });
            // }
            // sort by result by last modified date
            // var lol = sortByDate(res?.data?.results, "last_modified");
            // console.log(lol);
            setSearchResults(res?.data?.results);
          } else {
            toast({
              title: "Oops, something went wrong!",
              description: res.data.detail,
              status: "error",
              position: "top-right",
              duration: 2000,
              isClosable: true,
            });
          }
        })
    );
  };

  useEffect(() => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (search !== "") {
      setTypingTimeout(
        setTimeout(() => {
          searchHandler(search);
          if (search === "") setSearchResults([]);
        }, 100)
      );
    }
  }, [search]);

  useEffect(() => {
    if (search === "") setSearchResults([]);
  }, [search]);

  return (
    <Flex
      // width={{lg:"100%"}}
      flexDir={"column"}
    >
      {/* mobile */}
      <Flex
        background="grey0"
        position="fixed"
        flexDir={"column"}
        width="100%"
        px="2%"
        display={{ base: "flex", lg: "none" }}
        zIndex={"100"}
      >
        <Flex
          py={1}
          height={{ base: "", lg: "55px" }}
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
         
        >
          <Flex flexDir={{ base: "column", lg: "row" }} alignItems="left">
            <Menu isLazy>
              <MenuButton
                color="white"
                variant="ghost"
                onClick={() => (window.location = "/")}
                fontWeight="700"
                fontSize={"1.2rem"}
                display={{ base: "flex", lg: "none" }}
              >
                <Flex
                  flexDir={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  mr={2}
                >
                  <Text mr={2}>
                    {/* <svg
                      width="39"
                      height="39"
                      viewBox="0 0 39 39"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="39" height="39" rx="19.5" fill="#2D3842" />
                      <path
                        d="M19.5683 8.37869L23.941 17.8038L8.5946 30.7297L19.5683 8.37869Z"
                        fill="#BC9842"
                      />
                      <path
                        d="M19.4711 8.35135L15.0984 17.7765L30.4448 30.7024L19.4711 8.35135Z"
                        fill="#BC9842"
                      />
                    </svg> */}
                    
                  </Text>
                  <Text>{Title_NavBar}</Text>
                </Flex>
              </MenuButton>
            </Menu>
          </Flex>
          <Flex>
            <IconButton
              onClick={toggleMenu}
              colorScheme="light"
              style={{ color: "#white" }}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={
                isOpen ? "Close navigation menu" : "Open navigation menu"
              }
              size="lg"
            />
          </Flex>
        </Flex>
        {isOpen && (
          <>
            <Flex flexDir={"column"} alignItems={"baseline "}>
              <Menu isLazy py={{ base: "1rem", lg: "0" }} alignItems={"left"}>
                <MenuButton
                  variant="ghost"
                  py={1}
                  color={
                    window.location.pathname === "/" ||
                    activeButton === "dashboard"
                      ? "main.text"
                      : "white"
                  }
                  onClick={() => (window.location = "/")}
                  px={3}
                  fontWeight="700"
                >
                  Home
                </MenuButton>
                <MenuButton
                  variant="ghost"
                  color={activeButton === "library" ? "main.text" : "white"}
                  onClick={() => (window.location = "/library")}
                  px={3}
                  py={1}
                  fontWeight="700"
                >
                  Library
                </MenuButton>
                <MenuButton
                  variant="ghost"
                  color={activeButton === "templates" ? "main.text" : "white"}
                  onClick={() => (window.location = "/templates")}
                  py={1}
                  px={3}
                  fontWeight="700"
                >
                  Templates
                </MenuButton>
                <MenuButton
                  variant="ghost"
                  color={activeButton === "leaderboard" ? "main.text" : "white"}
                  onClick={() => (window.location = "/leaderboard")}
                  py={1}
                  px={3}
                  fontWeight="700"
                >
                  Leaderboard
                </MenuButton>
              </Menu>
            </Flex>
            <Flex
              flexDir="row"
              alignItems="left"
              px={2}
              py={{ base: "2rem", lg: "0" }}
            >
              <Menu isLazy>
                <MenuButton px={2}>
                  <svg
                    width="20"
                    height="23"
                    viewBox="0 0 29 33"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={activeButton === "shortcuts" ? "#FFC949" : "white"}
                  >
                    <path
                      d="M14.2337 32.9837C15.3101 32.9837 16.3423 32.5561 17.1034 31.795C17.8645 31.0339 18.2921 30.0016 18.2921 28.9253H10.1753C10.1753 30.0016 10.6029 31.0339 11.364 31.795C12.1251 32.5561 13.1573 32.9837 14.2337 32.9837ZM14.2337 4.40836L12.6164 4.73506C10.7819 5.10884 9.13294 6.10499 7.94854 7.55489C6.76413 9.0048 6.11707 10.8194 6.11687 12.6916C6.11687 13.9659 5.84495 17.1498 5.18546 20.2849C4.86078 21.8413 4.42248 23.4626 3.84009 24.8668H24.6273C24.0449 23.4626 23.6087 21.8433 23.282 20.2849C22.6225 17.1498 22.3505 13.9659 22.3505 12.6916C22.3499 10.8198 21.7026 9.00563 20.5182 7.55614C19.3339 6.10665 17.6851 5.1108 15.851 4.73709L14.2337 4.40633V4.40836ZM26.8554 24.8668C27.3079 25.7739 27.8314 26.4922 28.4382 26.8961H0.0292358C0.63597 26.4922 1.15951 25.7739 1.61202 24.8668C3.43831 21.2143 4.08766 14.4773 4.08766 12.6916C4.08766 7.7809 7.5779 3.6819 12.2146 2.74643C12.1863 2.46428 12.2174 2.17932 12.306 1.90994C12.3946 1.64056 12.5386 1.39273 12.7289 1.18244C12.9191 0.972146 13.1513 0.804063 13.4105 0.689027C13.6697 0.573991 13.9501 0.514557 14.2337 0.514557C14.5173 0.514557 14.7977 0.573991 15.0569 0.689027C15.3161 0.804063 15.5483 0.972146 15.7385 1.18244C15.9288 1.39273 16.0728 1.64056 16.1614 1.90994C16.25 2.17932 16.2811 2.46428 16.2528 2.74643C18.5463 3.21295 20.6082 4.45779 22.0893 6.27016C23.5703 8.08253 24.3795 10.351 24.3798 12.6916C24.3798 14.4773 25.0291 21.2143 26.8554 24.8668Z"
                      fill="white"
                    />
                    <circle cx="23" cy="9" r="6" fill="#FF3D3D" />
                  </svg>
                </MenuButton>
              </Menu>
              <Menu isLazy>
                <MenuButton
                  px={2}
                  onClick={() => (window.location = "/shortcuts")}
                  pt={"3px"}
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 23 27"
                    fill={activeButton === "shortcuts" ? "#FFC949" : "white"}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M21.9032 8.90845H14.9183L21.1993 0.972333C21.3293 0.804289 21.212 0.56015 20.9996 0.56015H8.84019C8.75141 0.56015 8.6658 0.60771 8.62141 0.686976L0.406284 14.8756C0.307994 15.0436 0.428478 15.2561 0.625058 15.2561H6.15465L3.3201 26.5943C3.25986 26.8416 3.5579 27.016 3.7418 26.8384L22.0776 9.34282C22.2425 9.18746 22.1315 8.90845 21.9032 8.90845ZM7.00756 20.7413L8.91945 13.1H3.92887L9.9404 2.71936H17.0617L10.4572 11.0677H17.1473L7.00756 20.7413Z" />
                  </svg>
                </MenuButton>
              </Menu>
              <Menu isLazy>
                <MenuButton
                  pt={"2px"}
                  onClick={() => (window.location = "/help")}
                  px={2}
                >
                  <svg
                    width="24"
                    height="29"
                    viewBox="0 0 29 29"
                    fill={activeButton === "help" ? "#FFC949" : "white"}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14.2662 0.545532C6.42207 0.545532 0.0617676 6.90584 0.0617676 14.75C0.0617676 22.5942 6.42207 28.9545 14.2662 28.9545C22.1104 28.9545 28.4707 22.5942 28.4707 14.75C28.4707 6.90584 22.1104 0.545532 14.2662 0.545532ZM14.2662 26.5448C7.75374 26.5448 2.47145 21.2625 2.47145 14.75C2.47145 8.23751 7.75374 2.95522 14.2662 2.95522C20.7787 2.95522 26.061 8.23751 26.061 14.75C26.061 21.2625 20.7787 26.5448 14.2662 26.5448Z" />
                    <path d="M17.8047 8.55775C16.8535 7.72388 15.5979 7.2673 14.2663 7.2673C12.9346 7.2673 11.679 7.72705 10.7278 8.55775C9.73859 9.42334 9.19324 10.587 9.19324 11.833V12.074C9.19324 12.2135 9.30738 12.3276 9.44689 12.3276H10.9688C11.1083 12.3276 11.2224 12.2135 11.2224 12.074V11.833C11.2224 10.4348 12.589 9.29651 14.2663 9.29651C15.9435 9.29651 17.3101 10.4348 17.3101 11.833C17.3101 12.8191 16.6125 13.7227 15.5313 14.1381C14.8592 14.3949 14.2885 14.8451 13.8794 15.4349C13.4641 16.0373 13.2485 16.7602 13.2485 17.4926V18.1743C13.2485 18.3138 13.3626 18.428 13.5021 18.428H15.024C15.1636 18.428 15.2777 18.3138 15.2777 18.1743V17.4546C15.2793 17.1468 15.3737 16.8466 15.5485 16.5932C15.7232 16.3398 15.9703 16.145 16.2574 16.0341C18.1281 15.3144 19.3361 13.6657 19.3361 11.833C19.3393 10.587 18.7939 9.42334 17.8047 8.55775ZM12.998 21.7254C12.998 22.0618 13.1316 22.3844 13.3695 22.6222C13.6073 22.8601 13.9299 22.9937 14.2663 22.9937C14.6026 22.9937 14.9252 22.8601 15.1631 22.6222C15.4009 22.3844 15.5345 22.0618 15.5345 21.7254C15.5345 21.3891 15.4009 21.0665 15.1631 20.8286C14.9252 20.5908 14.6026 20.4572 14.2663 20.4572C13.9299 20.4572 13.6073 20.5908 13.3695 20.8286C13.1316 21.0665 12.998 21.3891 12.998 21.7254Z" />
                  </svg>
                </MenuButton>
              </Menu>
            </Flex>

            <Menu>
              <MenuButton
                variant="ghost"
                textAlign={"left"}
                rightIcon={<ChevronDownIcon color="white" />}
                _hover={{
                  background: "inherit",
                }}
                p={3}
              >
                {/* <Flex flexDx    ir="row"color="white"> */}
                {/* <Text color="white">{authCtx.name}</Text> */}
                <Avatar
                  bg={"#8796A3"}
                  name={authCtx.name}
                  width="39px"
                  height="39px"
                />

                {/* </Flex> */}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => (window.location = "/reset-password")}>
                  Reset Password
                </MenuItem>
                <MenuItem onClick={logoutHandler}>Logout</MenuItem>
              </MenuList>
            </Menu>
          </>
        )}
      </Flex>

      {/* web */}

      <Flex
        display={{ base: "none", lg: "flex" }}
        width="100%"
        height={{ base: "", lg: "55px" }}
        flexDir={{ base: "column", lg: "row" }}
        justifyContent="space-evenly"
        px="2%"
        py="0.1rem"
        bg={"background.grey0"}
        position="fixed"
        zIndex={"100"}
      >
        <Flex flexDir={{ base: "column", lg: "row" }} alignItems="left">
          <Menu isLazy>
            <MenuButton
              color="white"
              variant="ghost"
              onClick={() => (window.location = "/")}
              borderEnd={"1px solid #6B6B6B"}
              fontWeight="700"
              fontSize={"1.25rem"}
              py={{ base: "0.5rem", lg: "0" }}
            >
              <Flex
                flexDir={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                mr={2}
              >
                <Text mr={2}>
                  <svg
                    width="39"
                    height="39"
                    viewBox="0 0 39 39"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="39" height="39" rx="19.5" fill="#2D3842" />
                    <path
                      d="M19.5683 8.37869L23.941 17.8038L8.5946 30.7297L19.5683 8.37869Z"
                      fill="#BC9842"
                    />
                    <path
                      d="M19.4711 8.35135L15.0984 17.7765L30.4448 30.7024L19.4711 8.35135Z"
                      fill="#BC9842"
                    />
                  </svg>
                </Text>
                <Text>{Title_NavBar}</Text>
              </Flex>
            </MenuButton>
          </Menu>
        </Flex>
        {/* search box componenet  */}
        {/* <MenuItem  variant="ghost" width="50%" color="white"  px={4}fontWeight="700"> */}

        <Flex>
          <Menu isLazy py={{ base: "0.5rem", lg: "0" }}>
            <MenuButton
              variant="ghost"
              color={
                activeButton === "dashboard" || window.location.pathname === "/"
                  ? "main.text"
                  : "white"
              }
              onClick={() => (window.location = "/")}
              px={3}
              fontSize={"1.05rem"}
              fontWeight="700"
            >
              Home
            </MenuButton>
            <MenuButton
              variant="ghost"
              color={activeButton === "library" ? "main.text" : "white"}
              onClick={() => (window.location = "/library")}
              px={3}
              fontSize={"1.05rem"}
              fontWeight="700"
            >
              Library
            </MenuButton>
            <MenuButton
              variant="ghost"
              color={activeButton === "templates" ? "main.text" : "white"}
              onClick={() => (window.location = "/templates")}
              px={3}
              fontSize={"1.05rem"}
              fontWeight="700"
            >
              Templates
            </MenuButton>
            <MenuButton
                  variant="ghost"
                  color={activeButton === "leaderboard" ? "main.text" : "white"}
                  onClick={() => (window.location = "/leaderboard")}
                  py={1}
                  px={3}
                  fontWeight="700"
                >
                  Leaderboard
                </MenuButton>
          </Menu>
        </Flex>
        <InputGroup
          width="50%"
          my="auto"
          color={"white"}
          display={{ base: "none", lg: "block" }}
        >
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon />}
            height="44px"
          />
          <Input
            ref={searchRef}
            onChange={handleSearchChange}
            border="none"
            bg={'background.grey2'}
            borderRadius={searchResults?.length > 0 ? "10px 10px 0 0" : "10px"}
            outline="none"
            height="44px"
            _placeholder={{ opacity: 1, color: "white" }}
            placeholder={
              isProjectPage
                ? "Search for blocks, files and key findings within this project"
                : "Search for projects"
            }
            value={search}
            color="white"
            _focusVisible={{
              border: "none",
            }}
          />
          <Flex
            flexDir={"column"}
            width="100%"
            borderRadius="0 0 10px 10px "
            bg={"#646B72"}
            align="stretch"
            minWidth="300px"
            maxHeight="500px"
            overflowY={"auto"}
          >
            {searchResults?.map(
              (result, index) =>
                isProjectPage && (
                  <Flex
                    p={4}
                    id={index}
                    justifyContent={"space-between"}
                    cursor="pointer"
                    onClick={() => {
                      result?.data?.id &&
                        isProjectPage &&
                        (result?.found === "title"
                          ? (window.location = `/page/${result?.data?.id}`)
                          : (window.location = `/page/${result?.data?.id}?key=${search}`));
                      // result?.found === 'file' && isProjectPage &&result?.file?.file!=='' && (window.location.href = `${result?.file?.file}`);
                      setSearch("");
                      setSearchResults([]);
                    }}
                    alignItems={"center"}
                    borderBottom={
                      index === searchResults.length - 1 || index == 4
                        ? "none"
                        : "1px solid #434E58"
                    }
                  >
                    <Text
                      color="main.searchArea"
                      mr={4}
                      fontWeight={600}
                      fontSize="18px"
                    >
                      {result?.found === "file"
                        ? result?.file?.title
                        : result?.data?.title}
                      <Text color="#D0D0D0" fontWeight={400} fontSize="14px">
                        Created on: {result?.data?.created_on?.split("T")[0]} |{" "}
                        {result?.data?.created_on
                          ?.split("T")[1]
                          .split(".")[0]
                          .slice(0, 5)}{" "}
                      </Text>
                    </Text>
                    <Text
                      color="gray.300"
                      mr={4}
                      fontWeight={600}
                      fontSize="16px"
                    >
                      Result: {result.found?.toUpperCase()}
                    </Text>
                  </Flex>
                )
            )}
            {searchResults?.map(
              (result, index) =>
                isProjectPage === false && (
                  <Flex
                    p={4}
                    id={index}
                    justifyContent={"space-between"}
                    cursor="pointer"
                    onClick={() => {
                      result?.id &&
                        (window.location = `/project/${result?.id}`);
                      setSearch("");
                      setSearchResults([]);
                    }}
                    alignItems={"center"}
                    borderBottom={
                      index === searchResults.length - 1 || index == 4
                        ? "none"
                        : "1px solid #434E58"
                    }
                  >
                    <Text
                      color="main.searchArea"
                      mr={4}
                      fontWeight={600}
                      fontSize="18px"
                    >
                      {result?.title}

                      <Text color="#D0D0D0" fontWeight={300} fontSize="14px">
                        <Avatar
                          size="xs"
                          bg="white"
                          name={result?.project_owner.name}
                          src={result?.project_owner.name}
                          color="#2D3842"
                          fontWeight="700"
                          mr={2}
                        />
                        {result?.project_owner.name + " "}
                        <Text
                          borderRight="2px solid #FFFFFF3D"
                          display="inline"
                        >
                          &nbsp;
                        </Text>{" "}
                        &nbsp; Last modified{" "}
                        {result?.last_modified?.split("T")[0]} |{" "}
                        {result?.last_modified
                          ?.split("T")[1]
                          .split(".")[0]
                          .slice(0, 5)}{" "}
                      </Text>
                    </Text>
                    <Text
                      color="gray.300"
                      mr={4}
                      fontWeight={600}
                      fontSize="16px"
                    >
                      {result?.project_code}
                    </Text>
                  </Flex>
                )
            )}
          </Flex>
        </InputGroup>

        {/* </MenuItem> */}
        {/* add icons */}
        <Flex
          flexDir="row"
          alignItems="left"
          px={2}
          py={{ base: "1rem", lg: "0" }}
        >
          <Menu isLazy>
            <MenuButton
              px={2}
              onClick={() => (window.location = "/notifications")}
            >
              <svg
                width="20"
                height="23"
                viewBox="0 0 29 33"
                xmlns="http://www.w3.org/2000/svg"
                fill={activeButton === "notifications" ? "#FFC949" : "white"}
              >
                <path d="M14.2337 32.9837C15.3101 32.9837 16.3423 32.5561 17.1034 31.795C17.8645 31.0339 18.2921 30.0016 18.2921 28.9253H10.1753C10.1753 30.0016 10.6029 31.0339 11.364 31.795C12.1251 32.5561 13.1573 32.9837 14.2337 32.9837ZM14.2337 4.40836L12.6164 4.73506C10.7819 5.10884 9.13294 6.10499 7.94854 7.55489C6.76413 9.0048 6.11707 10.8194 6.11687 12.6916C6.11687 13.9659 5.84495 17.1498 5.18546 20.2849C4.86078 21.8413 4.42248 23.4626 3.84009 24.8668H24.6273C24.0449 23.4626 23.6087 21.8433 23.282 20.2849C22.6225 17.1498 22.3505 13.9659 22.3505 12.6916C22.3499 10.8198 21.7026 9.00563 20.5182 7.55614C19.3339 6.10665 17.6851 5.1108 15.851 4.73709L14.2337 4.40633V4.40836ZM26.8554 24.8668C27.3079 25.7739 27.8314 26.4922 28.4382 26.8961H0.0292358C0.63597 26.4922 1.15951 25.7739 1.61202 24.8668C3.43831 21.2143 4.08766 14.4773 4.08766 12.6916C4.08766 7.7809 7.5779 3.6819 12.2146 2.74643C12.1863 2.46428 12.2174 2.17932 12.306 1.90994C12.3946 1.64056 12.5386 1.39273 12.7289 1.18244C12.9191 0.972146 13.1513 0.804063 13.4105 0.689027C13.6697 0.573991 13.9501 0.514557 14.2337 0.514557C14.5173 0.514557 14.7977 0.573991 15.0569 0.689027C15.3161 0.804063 15.5483 0.972146 15.7385 1.18244C15.9288 1.39273 16.0728 1.64056 16.1614 1.90994C16.25 2.17932 16.2811 2.46428 16.2528 2.74643C18.5463 3.21295 20.6082 4.45779 22.0893 6.27016C23.5703 8.08253 24.3795 10.351 24.3798 12.6916C24.3798 14.4773 25.0291 21.2143 26.8554 24.8668Z" />
                <circle cx="23" cy="9" r="6" fill="#FF3D3D" />
              </svg>
            </MenuButton>
          </Menu>
          <Menu isLazy>
            <MenuButton
              px={2}
              onClick={() => (window.location = "/shortcuts")}
              pt={"3px"}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 23 27"
                fill={activeButton === "shortcuts" ? "#FFC949" : "white"}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M21.9032 8.90845H14.9183L21.1993 0.972333C21.3293 0.804289 21.212 0.56015 20.9996 0.56015H8.84019C8.75141 0.56015 8.6658 0.60771 8.62141 0.686976L0.406284 14.8756C0.307994 15.0436 0.428478 15.2561 0.625058 15.2561H6.15465L3.3201 26.5943C3.25986 26.8416 3.5579 27.016 3.7418 26.8384L22.0776 9.34282C22.2425 9.18746 22.1315 8.90845 21.9032 8.90845ZM7.00756 20.7413L8.91945 13.1H3.92887L9.9404 2.71936H17.0617L10.4572 11.0677H17.1473L7.00756 20.7413Z" />
              </svg>
            </MenuButton>
          </Menu>
          <Menu isLazy>
            <MenuButton
              pt={"2px"}
              onClick={() => (window.location = "/help")}
              borderEnd={"1px solid #6B6B6B"}
              px={2}
            >
              <svg
                width="24"
                height="29"
                viewBox="0 0 29 29"
                fill={activeButton === "help" ? "#FFC949" : "white"}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M14.2662 0.545532C6.42207 0.545532 0.0617676 6.90584 0.0617676 14.75C0.0617676 22.5942 6.42207 28.9545 14.2662 28.9545C22.1104 28.9545 28.4707 22.5942 28.4707 14.75C28.4707 6.90584 22.1104 0.545532 14.2662 0.545532ZM14.2662 26.5448C7.75374 26.5448 2.47145 21.2625 2.47145 14.75C2.47145 8.23751 7.75374 2.95522 14.2662 2.95522C20.7787 2.95522 26.061 8.23751 26.061 14.75C26.061 21.2625 20.7787 26.5448 14.2662 26.5448Z" />
                <path d="M17.8047 8.55775C16.8535 7.72388 15.5979 7.2673 14.2663 7.2673C12.9346 7.2673 11.679 7.72705 10.7278 8.55775C9.73859 9.42334 9.19324 10.587 9.19324 11.833V12.074C9.19324 12.2135 9.30738 12.3276 9.44689 12.3276H10.9688C11.1083 12.3276 11.2224 12.2135 11.2224 12.074V11.833C11.2224 10.4348 12.589 9.29651 14.2663 9.29651C15.9435 9.29651 17.3101 10.4348 17.3101 11.833C17.3101 12.8191 16.6125 13.7227 15.5313 14.1381C14.8592 14.3949 14.2885 14.8451 13.8794 15.4349C13.4641 16.0373 13.2485 16.7602 13.2485 17.4926V18.1743C13.2485 18.3138 13.3626 18.428 13.5021 18.428H15.024C15.1636 18.428 15.2777 18.3138 15.2777 18.1743V17.4546C15.2793 17.1468 15.3737 16.8466 15.5485 16.5932C15.7232 16.3398 15.9703 16.145 16.2574 16.0341C18.1281 15.3144 19.3361 13.6657 19.3361 11.833C19.3393 10.587 18.7939 9.42334 17.8047 8.55775ZM12.998 21.7254C12.998 22.0618 13.1316 22.3844 13.3695 22.6222C13.6073 22.8601 13.9299 22.9937 14.2663 22.9937C14.6026 22.9937 14.9252 22.8601 15.1631 22.6222C15.4009 22.3844 15.5345 22.0618 15.5345 21.7254C15.5345 21.3891 15.4009 21.0665 15.1631 20.8286C14.9252 20.5908 14.6026 20.4572 14.2663 20.4572C13.9299 20.4572 13.6073 20.5908 13.3695 20.8286C13.1316 21.0665 12.998 21.3891 12.998 21.7254Z" />
              </svg>
            </MenuButton>
          </Menu>
        </Flex>

        <Menu>
          <MenuButton
            variant="ghost"
            rightIcon={<ChevronDownIcon color="white" />}
            _hover={{
              background: "inherit",
            }}
          >
            {/* <Flex flexDir="row"color="white"> */}
            {/* <Text color="white">{authCtx.name}</Text> */}
            <Avatar
              bg={"#8796A3"}
              name={authCtx.name}
              width="39px"
              height="39px"
            />

            {/* </Flex> */}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => (window.location = "/reset-password")}>
              Reset Password
            </MenuItem>
            <MenuItem onClick={logoutHandler}>Logout</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};
