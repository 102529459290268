import { useContext } from "react";
import { Flex, Button, Image, Text, Stack } from "@chakra-ui/react";
import { BsPencilFill, BsQuestionCircleFill } from "react-icons/bs";
import onboardingImage from "../resources/imgs/onboarding.png";

import AuthContext from "../store/auth";
import Breadcrumbs from "../components/BreadCrumbs";
import { Url, themeDefault, namesOfModes, Title_NavBar, KMS, shortHand, UrlBase, TitleCompany } from '../store/global';

const HelpDesk = () => {
  const authCtx = useContext(AuthContext);
  const breadcrumbs = [{ path: "/", breadcrumb: "Home", end: true }];

  return (
    <Flex
      flexDir={[
        "column-reverse",
        "column-reverse",
        "column-reverse",
        "row",
        "row",
      ]}
      width="100vw"
      height="100vh"
      overflow="hidden"
      alignItems="center"
      background="bg"
    >
      <Flex
        flexDir="column"
        width={["95vw", "95vw", "95vw", "60vw", "60vw"]}
        alignItems="center"
        justifyContent="start"
        height="100%"
      >
        <Flex
          flexDir="column"
          width={["90%", "90%", "85%", "85%", "85%"]}
          mt={["50px", "50px", "50px", "0px", "0px"]}
          mb={["50px", "50px", "50px", "0px", "0px"]}
          justifyContent="space-between"
        >
          {" "}
          <Flex color="white" fontSize="20px" fontWeight="400">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </Flex>
          <Flex flexDir="column">
            <Flex flexDir="column">
              <Flex flexDir="column" mt="30px">
                <Flex flexDir="row">
                  <Text color="white" fontSize="24px" fontWeight="700">
                    Welcome to the&nbsp;
                  </Text>
                  <Text color="main.text" fontSize="24px" fontWeight="700">
                    Help Desk!
                  </Text>
                </Flex>
                <Text color="main.grey1" fontSize="18px" fontWeight="700">
                  Hey {authCtx.name}! What do you need help with?
                </Text>
              </Flex>
            </Flex>

            <Flex
              width="85%"
              height="fit-content"
              flexDir="column"
              justifyContent="space-between"
              mt="30px"
            >
              <Text color="white" fontSize="24px" fontWeight="700">
                Access Guidelines on how to use the {shortHand} KMS
              </Text>
              <Stack direction="row" spacing={4}>
                <Button
                  leftIcon={<BsQuestionCircleFill />}
                  bg="background.grey3"
                  color="white"
                  size="lg"
                  variant="solid"
                  mt="5px"
                  as="a"
                  href="https://docs.google.com/document/d/1rO4eY86hYmnqtZemrYjt6CsFOZ5Ykvb44E3FA0b7bp0/edit?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                >
                  Documentation
                </Button>
              </Stack>

              <Text color="white" fontSize="24px" fontWeight="700" mt="50px">
                Contact {shortHand}’s KMS Team regarding specific queries or feedback
              </Text>
              <Stack direction="row" spacing={4}>
                <Button
                  leftIcon={<BsQuestionCircleFill />}
                  bg="background.grey3"
                  color="white"
                  size="lg"
                  variant="solid"
                  mt="5px"
                  as="a"
                  href="mailto:kms@fischer.jordan.com"
                  target="_blank"
                >
                  Contact KMS
                </Button>
              </Stack>

              <Text color="white" fontSize="24px" fontWeight="700" mt="50px">
                Share Feedback on the KMS
              </Text>
              <Stack direction="row" spacing={4}>
                <Button
                  leftIcon={<BsPencilFill />}
                  bg="background.grey3"
                  color="white"
                  size="lg"
                  variant="solid"
                  mt="5px"
                  as="a"
                  href="https://forms.gle/9KdNd1e18ZmJ5qX1A"
                  target="_blank"
                >
                  Click here
                </Button>
              </Stack>
            </Flex>
          </Flex>
          <Button
            bg="main.text"
            width="250px"
            p="2px"
            as="a"
            href="/dashboard"
            mt={["60px", "60px", "60px", "150px", "150px"]}
            mb={["20px", "20px", "20px", "0px", "0px"]}
            color="white"
            fontSize="16px"
            _hover={{
              background: "#F2DBA6",
            }}
            mr="10px"
          >
            Back to Dashboard
          </Button>
        </Flex>
      </Flex>

      <Flex
        flexDir="column"
        width={["100vw", "100vw", "100vw", "45vw", "45vw"]}
        padding="30px"
        height="100%"
      >
        <Image
          src={onboardingImage}
          objectFit="cover"
          borderRadius="15px"
          height={["200px", "200px", "200px", "100%", "100%"]}
          width="100%"
        />
      </Flex>
    </Flex>
  );
};

export default HelpDesk;
