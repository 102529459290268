import { useState, useEffect, useRef, useContext } from "react";
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Select,
  Th,
  Td,
  TableContainer,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Popover,
  PopoverTrigger,
  Spinner,
  Tag,
  Checkbox,
} from "@chakra-ui/react";
import { ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react";

import { useParams } from "react-router-dom";

import AuthContext from "../../store/auth";
import swal from "sweetalert";
import { useHotkeys } from "react-hotkeys-hook";
import Breadcrumbs from "../../components/BreadCrumbs";
import { Url } from '../../store/global';

const TrashProjects = () => {
  const toast = useToast();
  // authentication module
  const authCtx = useContext(AuthContext);

  // filter state
  const [activeFilter, setActiveFilter] = useState("all");
  let { projectSlug } = useParams();

  // new project modal
  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const title = useRef();
  // const industry = useRef();
  // const client = useRef();
  // const visibility = useRef();
  // const code = useRef();
  // const projectID = useRef();

  // projects list object state
  const [delProjectInfo, setDelProjectInfo] = useState();
  const [delProjectInfo2, setDelProjectInfo2] = useState();
  const [checkProjects, setCheckedProjects] = useState([]);



  const logoutHandler = () => {
    authCtx.logout();
  };

  const [bot, setBot] = useState(0);
  const [curr, setCurr] = useState(0);

  const [search, setSearch] = useState("");

  // POST request to create a new project

  // API GET request to query all the projects

  const getDelProjects = () => {
    fetch({Url}.Url+"/deleted_projects/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setDelProjectInfo(res.data);
            console.log(delProjectInfo);
            setDelProjectInfo2(res.data);
          } else {
            toast({
              title: "Oops, something went wrong!",
              description: res.data.detail,
              status: "error",
              position: "top-right",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
          }
        })
    );
  };
  const errorProject = () => {
    toast({
      title: "First select a project!",
      description: "",
      status: "error",
      position: "top-right",
      duration: 2000,
      isClosable: true,
    });
  };
  const sortLol = (direction) => {
    let sortedProjects = delProjectInfo2;
    if (bot === 1) {
      if (curr !== 1) {
        setCurr(1);
        setDirection(-1);
      } else setDirection(-1 * direction);
      sortedProjects = delProjectInfo2.sort((a, b) => {
        if (a.project_code < b.project_code)
          return curr !== 1 ? -1 : direction === 1 ? -1 : 1;
        if (a.project_code > b.project_code)
          return curr !== 1 ? 1 : direction === 1 ? 1 : -1;
        return 0;
      });
    }
    if (bot === 2) {
      if (curr !== 2) {
        setCurr(2);
        setDirection(-1);
      } else setDirection(-1 * direction);
      sortedProjects = delProjectInfo2.sort((a, b) => {
        if (a.title < b.title)
          return curr !== 2 ? -1 : direction === 1 ? -1 : 1;
        if (a.title > b.title) return curr !== 2 ? 1 : direction === 1 ? 1 : -1;
        return 0;
      });
    }
    if (bot === 3) {
      if (curr !== 3) {
        setCurr(3);
        setDirection(-1);
      } else setDirection(-1 * direction);
      sortedProjects = delProjectInfo2.sort((a, b) => {
        if (a.project_owner < b.project_owner)
          return curr !== 3 ? -1 : direction === 1 ? -1 : 1;
        if (a.project_owner > b.project_owner)
          return curr !== 3 ? 1 : direction === 1 ? 1 : -1;
        return 0;
      });
    }
    if (bot === 4) {
      if (curr !== 4) {
        setCurr(4);
        setDirection(-1);
      } else setDirection(-1 * direction);
      sortedProjects = delProjectInfo2.sort((a, b) => {
        if (a.industry < b.industry)
          return curr !== 4 ? -1 : direction === 1 ? -1 : 1;
        if (a.industry > b.industry)
          return curr !== 4 ? 1 : direction === 1 ? 1 : -1;
        return 0;
      });
    }
    if (bot === 5) {
      if (curr !== 5) {
        setCurr(5);
        setDirection(-1);
      } else setDirection(-1 * direction);
      sortedProjects = delProjectInfo2.sort((a, b) => {
        if (a.role < b.role) return curr !== 5 ? -1 : direction === 1 ? -1 : 1;
        if (a.role > b.role) return curr !== 5 ? 1 : direction === 1 ? 1 : -1;
        return 0;
      });
    }
    if (bot === 6) {
      if (curr !== 6) {
        setCurr(6);
        setDirection(-1);
      } else setDirection(-1 * direction);
      sortedProjects = delProjectInfo2.sort((a, b) => {
        if (a.client_name < b.client_name)
          return curr !== 6 ? -1 : direction === 1 ? -1 : 1;
        if (a.client_name > b.client_name)
          return curr !== 6 ? 1 : direction === 1 ? 1 : -1;
        return 0;
      });
    }
    if (bot === 7) {
      if (curr !== 7) {
        setCurr(7);
        setDirection(-1);
      } else setDirection(-1 * direction);
      sortedProjects = delProjectInfo2.sort((a, b) => {
        if (a.last_modified < b.last_modified)
          return curr !== 7 ? -1 : direction === 1 ? -1 : 1;
        if (a.last_modified > b.last_modified)
          return curr !== 7 ? 1 : direction === 1 ? 1 : -1;
        return 0;
      });
    }
    setBot(0);
    setDelProjectInfo(sortedProjects);
  };

  function searchValid(project) {
    return (
      project.title.toLowerCase().includes(search.toLowerCase()) ||
      project.project_owner.toLowerCase().includes(search.toLowerCase()) ||
      project.project_code.toLowerCase().includes(search.toLowerCase()) ||
      project.client_name.toLowerCase().includes(search.toLowerCase()) ||
      project.industry.toLowerCase().includes(search.toLowerCase())
    );
  }

  // runs on initial page load
  useEffect(() => {
    getDelProjects();
    projectSlug ? setActiveFilter(projectSlug) : setActiveFilter("all");
    // console.log(projectID);
    // eslint-disable-next-line
    setCurr(JSON.parse(window.localStorage.getItem("curr")));
  }, []);

  const [first, setFirst] = useState(0);

  const [direction, setDirection] = useState(1);
  useEffect(() => {
    first !== 0 ? sortLol(direction) : setFirst(1);
    // setDirection(-1*direction);
    // eslint-disable-next-line
  }, [bot]);
  // const [charRemain, setCharRemain] = useState(30);
  const maxChar = 40;

  function deleteHandler(projectID) {
    console.log(projectID);
    if (delProjectInfo?.length === 0) {
      toast({
        title: "Oops, something went wrong!",
        description: "No projects to delete",
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    fetch({Url}.Url+"/deleted_projects/", {
      method: "DELETE",
      body: JSON.stringify({
        project_id: projectID.toString(),
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            // console.log(res.data);
            toast({
              title: "Deletion confirmed.",
              description: "Let's head back to the section.",
              status: "success",
              position: "top-right",
              duration: 2000,
              isClosable: true,

              onCloseComplete: () => {
                getDelProjects();
              },
            }).then(() => getDelProjects());
          } else {
            // console.log(res.data);
            toast({
              title: "page editing failed.",
              description: res.data.detail,
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
          }
        })
    );
  }
  function restoreHandler(projectID) {
    console.log(projectID);
    fetch({Url}.Url+"/deleted_projects/", {
      method: "POST",
      body: JSON.stringify({
        project_id: projectID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            // console.log(res.data);
            toast({
              title: "Project restored successfully!.",
              description: "Let's get back to the project page.",
              status: "success",
              position: "top-right",
              duration: 2000,
              isClosable: true,
              onCloseComplete: () => {
                window.location = "/project/" + projectID + "/";
              },
            }).then(() => {
              window.location = "/project/" + projectID + "/";
            });
          } else {
            // console.log(res.data);
            toast({
              title: "Project editing failed.",
              description: res.data.detail,
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
          }
        })
    );
  }
  // useEffect(() => {
  //   setCharRemain( maxChar - title.current.value.length )
  // }, [title.current.value]);

  // // Save to local storage to persist state on reload
  // useEffect(() => {
  //   setDirection(JSON.parse(window.localStorage.getItem('direction')));
  //   setCurr(JSON.parse(window.localStorage.getItem('curr')));
  // }, []);

  // useEffect(() => {
  //   window.localStorage.setItem('direction', direction);
  //   window.localStorage.setItem('curr', curr);
  // }, [direction,curr]);

  // HTML element tree ̦— render function

  useEffect(() => {
    first !== 0 ? sortLol(direction) : setFirst(1);
    // setDirection(-1*direction);
    // console.log(curr);
    window.localStorage.setItem("curr", curr);
    window.localStorage.setItem("direction", direction);

    // eslint-disable-next-line
  }, [bot]);

  const checkBoxList = (e) => {
    console.log(e);
    if (!checkProjects.includes(e)) checkProjects.push(e);
    else checkProjects.splice(checkProjects.indexOf(e), 1);

    console.log(checkProjects.length);
  };
  // shortcuts
  useHotkeys("alt+x", () => (window.location = "/library"));
  useHotkeys("option+x", () => (window.location = "/library"));
  const breadcrumbs = [
    { path: "/", breadcrumb: "Home" },
    { path: "/library", breadcrumb: "Library", end: true },
  ];

  return (
    <Flex
      backgroundColor="bg"
      flexDir="column"
      alignItems="center"
      minHeight="100vh"
      width="100vw"
      pb="40px"
    >
      <Flex
        width="95%"
        height="fit-content"
        flexDir="column"
        justifyContent="space-between"
        px="10px"
      >
        <Flex w="100%" color="white" fontSize="20px" fontWeight="400">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Flex>
        <Flex flexDir="row" alignItems="baseline" justifyContent="space-between">
          {/* <Text color="white" fontSize="24px" fontWeight="700">
              Fischer Jordan&nbsp;
            </Text> */}
          <Text color="white" fontSize="24px" fontWeight="700" style={{display:'inline'}}>
            Trash &nbsp;
            
          </Text>

         
          <Flex>
          {checkProjects.length!==0 ? (<Menu closeOnSelect={false}  bg={"#646B72"}>
                <MenuButton
                  as={Button}
                  bg={"#646B72"}
                  color="#E0E0E0"
                  mr={3}
                  width="max-content"
                >                  
                  <svg
                    style={{ display: "inline-flex" }}
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.46 2.26L2.14 0.579999L5.3 3.86L8.46 0.58L10.14 2.26L5.3 7.1L0.46 2.26Z"
                      fill="white"
                    />
                  </svg>
                </MenuButton>
                <MenuList bg={"#646B72"} color="#E0E0E0">                  
                    <MenuItem bg={"#646B72"}>                      
                   <Text bg={"#646B72"}
                    onClick={() => {
                      checkProjects[0] ? deleteHandler(checkProjects.map((page) => page).join(",")) : errorProject();
                    }}
                    >Delete Selected Projects</Text>
                    </MenuItem>
                    <MenuItem bg={"#646B72"}>                      
                   <Text bg={"#646B72"} onClick={() => {
                      checkProjects[0] ? restoreHandler(checkProjects.map((page) => page).join(",")) : errorProject();
                    }}>Restore Selected Projects</Text>
                    </MenuItem>                 
                </MenuList>
              </Menu>):null}
              {/* {checkProjects[0] ? (
                <>
                  <Button
                    bg="yellow1"
                    px={4}
                    color="white"
                    fontSize="16px"
                    // onClick={() => {
                    //   checkProjects[0] ? onOpen1() : errorProject();
                    // }}
                    _hover={{
                      background: "#F2DBA6",
                    }}
                    mr="10px"
                  >
                    &nbsp; Yay
                  </Button>
                </>
              ) : null} */}
          
          <Popover>
                              <PopoverTrigger>
                                <Button
                                  bg={"#3D454C"}
                                  color="white"
                                  fontSize="14px"
                                  width="100%"
                                  as="a"
                                  mr={3}

                                  // _hover={{
                                  //   bg: "transparent",
                                  // }}
                                 
                                >
                                  <svg
                                    viewBox="0 0 1024 1024"
                                    fill="#fff"
                                    height="1.5em"
                                    width="1.5em"
                                  >
                                    <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" />
                                  </svg>
                                  &nbsp; Delete All Projects
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent
                                bg="background.grey3"
                                color="white"
                                display="block"
                              >
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader>Confirmation!</PopoverHeader>
                                <PopoverBody display="block">
                                  <Text>This action cannot be undone!</Text>
                                  <Button
                                    colorScheme="red"
                                    width="170px"
                                    height="40px"
                                    p="10px"
                                    fontSize="16px"
                                    mr="10px"
                                    mt="20px"
                                    onClick={() =>
                  deleteHandler(delProjectInfo.map((page) => page.id).join(","))
                }
                                    // onClick={() => deleteHandler(project.id)}
                                  >
                                    Delete All forever?
                                  </Button>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                            <Popover>
                              <PopoverTrigger>
                                <Button
                                  bg={"#3D454C"}
                                  color="white"
                                  fontSize="14px"
                                  width="100%"
                                  as="a"
                                  // _hover={{
                                  //   bg: "transparent",
                                  // }}
                                >
                                  <svg
                                  viewBox="0 0 1024 1024"
                                  fill="#fff"
                                  height="1.5em"
                                    width="1.5em"
                                >
                                  <path d="M909.1 209.3l-56.4 44.1C775.8 155.1 656.2 92 521.9 92 290 92 102.3 279.5 102 511.5 101.7 743.7 289.8 932 521.9 932c181.3 0 335.8-115 394.6-276.1 1.5-4.2-.7-8.9-4.9-10.3l-56.7-19.5a8 8 0 00-10.1 4.8c-1.8 5-3.8 10-5.9 14.9-17.3 41-42.1 77.8-73.7 109.4A344.77 344.77 0 01655.9 829c-42.3 17.9-87.4 27-133.8 27-46.5 0-91.5-9.1-133.8-27A341.5 341.5 0 01279 755.2a342.16 342.16 0 01-73.7-109.4c-17.9-42.4-27-87.4-27-133.9s9.1-91.5 27-133.9c17.3-41 42.1-77.8 73.7-109.4 31.6-31.6 68.4-56.4 109.3-73.8 42.3-17.9 87.4-27 133.8-27 46.5 0 91.5 9.1 133.8 27a341.5 341.5 0 01109.3 73.8c9.9 9.9 19.2 20.4 27.8 31.4l-60.2 47a8 8 0 003 14.1l175.6 43c5 1.2 9.9-2.6 9.9-7.7l.8-180.9c-.1-6.6-7.8-10.3-13-6.2z" />
                                </svg>
                                &nbsp;  Restore All Projects
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent
                                bg="background.grey3"
                                color="white"
                                display="block"
                              >
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader>Confirmation!</PopoverHeader>
                                <PopoverBody display="block">
                                  <Text>This action cannot be undone!</Text>
                                  <Button
                                    colorScheme="red"
                                    width="170px"
                                    height="40px"
                                    p="10px"
                                    fontSize="16px"
                                    mr="10px"
                                    mt="20px"
                                    onClick={() => restoreHandler(delProjectInfo.map((page) => page.id).join(","))}
                                  >
                                    Restore All?
                                  </Button>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
        </Flex>
        </Flex>
        
        <Text color="main.grey1" fontSize="18px" fontWeight="700">
        Projects in trash are deleted forever after 7 days
        </Text>
        
      </Flex>
     

      <Flex
        width="95%"
        height="fit-content"
        flexDir="column"
        justifyContent="space-between"
        p="10px"
        mt="20px"
        mb="20px"
      >

        {delProjectInfo ? (
          <TableContainer
            width="100%"
            border="1px solid rgba(255, 255, 255, 0.42)"
            borderRadius="5px"
            padding="1px"
            mt={4}
            mb={10}
          >
            <Table variant="simple" size="sm" bg="#3D454C">
              <Thead cursor="pointer">
                <Tr>
                  {/* <Th
                    color="white"
                    fontSize="14px"
                    onClick={() => {
                      setBot(1);
                    }}
                  >
                    PROJECT CODE{" "}
                    {curr === 1 ? 
                    (direction === -1 ? "↓" : "↑") : ""}
                  </Th> */}
                  <Th color="white" fontSize="14px" cursor="pointer">
                    {"  "}&nbsp;
                    <Text size="xs" display={"inline-block"}>
                      {/* <BsFillStarFill color='#C1962F' /> */}
                    </Text>
                  </Th>
                  <Th
                    color="white"
                    fontSize="14px"
                    onClick={() => {
                      setBot(2);
                    }}
                  >
                    TITLE {curr === 2 ? (direction === -1 ? "↓" : "↑") : ""}
                  </Th>

                  <Th
                    color="white"
                    fontSize="14px"
                    onClick={() => {
                      setBot(6);
                    }}
                  >
                    CLIENT {curr === 6 ? (direction === -1 ? "↓" : "↑") : ""}
                  </Th>
                  <Th
                    color="white"
                    fontSize="14px"
                    onClick={() => {
                      setBot(6);
                    }}
                  >
                    Last Modified{" "}
                    {curr === 6 ? (direction === -1 ? "↓" : "↑") : ""}
                  </Th>
                  <Th
                    color="white"
                    fontSize="14px"
                    onClick={() => {
                      setBot(6);
                    }}
                  >
                    Owner{curr === 6 ? (direction === -1 ? "↓" : "↑") : ""}
                  </Th>
                  <Th
                    color="white"
                    fontSize="14px"
                    onClick={() => {
                      setBot(6);
                    }}
                  >
                    Access {curr === 6 ? (direction === -1 ? "↓" : "↑") : ""}
                  </Th>
                  <Th
                    color="white"
                    fontSize="14px"
                    onClick={() => {
                      setBot(6);
                    }}
                  >
                    Category {curr === 6 ? (direction === -1 ? "↓" : "↑") : ""}
                  </Th>
                  <Th color="white" fontSize="14px" textAlign="center"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {delProjectInfo.map((project, id) => (
                  <>
                    {activeFilter === "all" ||
                    project.industry.toLowerCase() === activeFilter ||
                    project.role.toLowerCase().includes(activeFilter) ? (
                      <Tr key={id} bg={id % 2 === 1 ? "#3D454C" : "#434E58"}>
                        <Td
                          color="white"
                          fontSize="14px"
                          textAlign="left"
                          pl="4"
                          ml="5"
                          style={{
                            minWidth: "35px",
                            maxWidth: "35px",
                            width: "35px",
                          }}
                        >
                          {/* {item.favorite?<>true</>:<>false</>} */}
                          {console.log(project.id)}
                          <Checkbox
                            mr="0.7"
                            style={{ verticalAlign: "middle" }}
                            size="sm"
                            onChange={() => {
                              checkBoxList(project.id);
                            }}
                          ></Checkbox>

                          <Button
                            bg="transparent"
                            // justifyContent={"left"}
                            // color="white"
                            // fontSize="16px"
                            _hover={{
                              background: "transparent",
                            }}
                            pl={4}
                            mr={2}
                            // mr="10px"
                            height="1.9rem"
                            size="sm"
                            // onClick={() => {
                            //   setFavorites(item);
                            // }}
                          >
                            &nbsp;
                          </Button>
                        </Td>
                        <Td color="white" fontWeight="bold" fontSize="14px"  style={{
                              minWidth: "405px",
                              maxWidth: "405px",
                              width: "405px",
                            }}>
                          <Text
                            isTruncated
                            display={"inline"}
                            style={{
                              minWidth: "55px",
                              maxWidth: "55px",
                              width: "55px",
                            }}
                          >
                            {project.title?.length > 30
                              ? project.title.slice(0, 38) + "..."
                              : project.title}
                          </Text>
                          <Tag
                            fontSize={"10px"}
                            bg="background.grey4"
                            color="#FFF"
                            size="sm"
                            borderRadius="full"
                            ml="2"
                          >
                            {project.project_code?.length > 24
                              ? project.project_code.slice(0, 24) + "..."
                              : project.project_code}
                          </Tag>
                        </Td>

                        <Td color="white" fontWeight="bold" fontSize="14px">
                          {/*  */}
                          {project.client_name}
                          {/*  */}
                        </Td>
                        <Td color="white" fontWeight="bold" fontSize="14px"  style={{
                            minWidth: "180px",
                            maxWidth: "180px",
                            width: "180px",
                          }}>
                          {/*  */}
                          {project.last_modified.split("T")[0]} |{" "}
                          {project.last_modified
                            .split("T")[1]
                            .split(".")[0]
                            .slice(0, 5)}{" "}
                          {/*  */}
                        </Td>
                        <Td
                          color="white"
                          fontSize="14px"
                          style={{
                            minWidth: "140px",
                            maxWidth: "140px",
                            width: "140px",
                          }}
                          
                        >
                          {project.project_owner.name?.length > 30
                            ? project.project_owner.name.slice(0, 30) + "..."
                            : project.project_owner.name}
                        </Td>
                        <Td
                          color="white"
                          fontSize="14px"
                          style={{
                            minWidth: "120px",
                            maxWidth: "120px",
                            width: "120px",
                          }}
                         
                        >
                          {project.role}
                        </Td>
                        <Td
                          color="white"
                          fontSize="14px"
                          width="225px"
                          style={{
                            minWidth: "120px",
                            maxWidth: "120px",
                            width: "120px",
                          }}
                          // onClick={() =>
                          //   (window.location = "/project/" + item.id)
                          // }
                        >
                          {project.industry}
                        </Td>
                        <Td
                        style={{
                            minWidth: "110px",
                            maxWidth: "110px",
                            width: "110px",
                          }}>
                          <Flex justifyContent="space-evenly">
                            <Flex>
                              <Text
                                bg="transparent"
                                width="100%"
                                color="white"
                                fontSize="14px"
                                as="a"
                                _hover={{
                                  bg: "transparent",
                                }}
                                onClick={() => restoreHandler(project.id)}

                                // href={"/page/" + projectSlug}
                              >
                                <svg
                                  viewBox="0 0 1024 1024"
                                  fill="#68FFC8"
                                  height="1.5em"
                                    width="1.5em"
                                >
                                  <path d="M909.1 209.3l-56.4 44.1C775.8 155.1 656.2 92 521.9 92 290 92 102.3 279.5 102 511.5 101.7 743.7 289.8 932 521.9 932c181.3 0 335.8-115 394.6-276.1 1.5-4.2-.7-8.9-4.9-10.3l-56.7-19.5a8 8 0 00-10.1 4.8c-1.8 5-3.8 10-5.9 14.9-17.3 41-42.1 77.8-73.7 109.4A344.77 344.77 0 01655.9 829c-42.3 17.9-87.4 27-133.8 27-46.5 0-91.5-9.1-133.8-27A341.5 341.5 0 01279 755.2a342.16 342.16 0 01-73.7-109.4c-17.9-42.4-27-87.4-27-133.9s9.1-91.5 27-133.9c17.3-41 42.1-77.8 73.7-109.4 31.6-31.6 68.4-56.4 109.3-73.8 42.3-17.9 87.4-27 133.8-27 46.5 0 91.5 9.1 133.8 27a341.5 341.5 0 01109.3 73.8c9.9 9.9 19.2 20.4 27.8 31.4l-60.2 47a8 8 0 003 14.1l175.6 43c5 1.2 9.9-2.6 9.9-7.7l.8-180.9c-.1-6.6-7.8-10.3-13-6.2z" />
                                </svg>
                              </Text>
                            </Flex>
                            <Flex>
                            <Popover>
                              <PopoverTrigger>
                                <Text
                                  bg="transparent"
                                  color="white"
                                  fontSize="14px"
                                  width="100%"
                                  as="a"
                                  _hover={{
                                    bg: "transparent",
                                  }}
                                >
                                  <svg
                                    viewBox="0 0 1024 1024"
                                    fill="#FF6968"
                                    height="1.5em"
                                    width="1.5em"
                                  >
                                    <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" />
                                  </svg>
                                </Text>
                              </PopoverTrigger>
                              <PopoverContent
                                bg="background.grey3"
                                color="white"
                                display="block"
                              >
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader>Confirmation!</PopoverHeader>
                                <PopoverBody display="block">
                                  <Text>This action cannot be undone!</Text>
                                  <Button
                                    colorScheme="red"
                                    width="170px"
                                    height="40px"
                                    p="10px"
                                    fontSize="16px"
                                    mr="10px"
                                    mt="20px"
                                    onClick={() => deleteHandler(project.id)}
                                  >
                                    Delete forever?
                                  </Button>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                            </Flex>

                            
                          </Flex>
                        </Td>
                      </Tr>
                    ) : null}
                  </>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Spinner />
        )}
      </Flex>

      {/* <Flex
        height="fit-content"
        flexDir="row"
        justifyContent="space-between"
        p="20px"
        // mt="10px"
        width="95%"
        // mb="30px"
        pos="fixed"
        bottom="0"
        zIndex="1"
        bg="#2A3843"
      >
        <Flex>
          <Button
            bg="yellow1"
            width="250px"
            p="2px"
            mb={["20px", "20px", "20px", "0px", "0px"]}
            color="white"
            fontSize="16px"
            as="a"
            href="/dashboard"
            _hover={{
              background: "#F2DBA6",
            }}
            mr="10px"
          >
            Return to Dashboard
          </Button>
          <Button
            bg="yellow1"
            width="230px"
            p="2px"
            mb={["20px", "20px", "20px", "0px", "0px"]}
            color="white"
            fontSize="16px"
            as="a"
            href="/library"
            _hover={{
              background: "#F2DBA6",
            }}
            mr="10px"
          >
            Return to Library
          </Button>
        </Flex>
        <Popover>
          <PopoverTrigger>
            <Button bg="RED" color="white" fontSize="14px" width="200px" as="a">
              Empty Trash
            </Button>
          </PopoverTrigger>
          <PopoverContent bg="grey3" color="white" display="block">
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Confirmation!</PopoverHeader>
            <PopoverBody display="block">
              <Text>This action cannot be undone!</Text>
              <Button
                colorScheme="red"
                width="200px"
                height="40px"
                p="10px"
                fontSize="16px"
                mr="10px"
                mt="20px"
                onClick={() =>
                  deleteHandler(delProjectInfo.map((page) => page.id).join(","))
                }
              >
                Delete forever?
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex> */}
    </Flex>
  );
};

export default TrashProjects;
