import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
  useMemo,
} from "react";
import S3FileUpload from "react-s3";
import { Buffer } from "buffer";
import dataSheet from "./Sample.js";
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  Input,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Select,
  Switch,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import { BiEdit, BiTrash } from "react-icons/bi";
import { ReactSortable } from "react-sortablejs";
import { useToast } from "@chakra-ui/react";
import { Url } from "../../store/global";
import { Sheet, Op, Selection, colors, getAllSheet } from "@fortune-sheet/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-full";
import { v4 as uuidv4 } from "uuid";
import Breadcrumbs from "../../components/BreadCrumbs";
import AuthContext from "../../store/auth";
import swal from "sweetalert";
import { useHotkeys } from "react-hotkeys-hook";
import "../../styles/edit-page.css";
import { useStepContext } from "@mui/material";
const S3_BUCKET = "kms-assets";
const REGION = "us-east-1";
const ACCESS_KEY = "AKIAQTH52T33TY6UHF7L";
const SECRET_ACCESS_KEY = "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";

const EditPage = () => {
  const toast = useToast();
  const collapseRef = useRef();
  // authentication module
  const authCtx = useContext(AuthContext);
  const [data, setData] = useState();
  const [sheetData, setSheetData] = useState();
  // const [collapse, setCollapse] = useState();
  const [updateData, setUpdateData] = useState();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();

  window.Buffer = Buffer;
  const [filel, setFilel] = useState(false);

  const [value, setValue] = useState("");
  const [textToggle, setTextToggle] = useState();
  const [sheetToggle, setSheetToggle] = useState();
  const [collapse, setCollapse] = useState();
  const [sheet, setSheet] = useState();

  const editorHandler = (e, editor) => {
    // console.log(editor.getData());
    setValue(editor.getData());
  };

  const logoutHandler = () => {
    authCtx.logout();
  };

  const [fiti, setFiti] = useState(null);

  let { pageSlug } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState("");

  const onCloseHandler = () => {
    setFileName("");
    onClose();
  };

  function handleDragOver(event) {
    event.preventDefault();
    setIsDragging(true);
  }

  function handleDragLeave(event) {
    event.preventDefault();
    setIsDragging(false);
  }

  function handleFileDrop(event) {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    handleFileInput(file);
  }
  function convert2DTo1D(matrix) {
    const result = [];

    for (let r = 0; r < matrix.length; r++) {
      for (let c = 0; c < matrix[r].length; c++) {
        const v = matrix[r][c];
        if (v) result.push({ r, c, v });
      }
    }

    return result;
  }

  const onFileChange = (file) => {
    const config = {
      bucketName: S3_BUCKET,
      dirName: "media/public/uploads",
      region: REGION,
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        // console.log(data);
        // console.log(data.key);
        let a = data.key.lastIndexOf("/") + 1;
        let b = data.key.substring(a);
        // console.log(b);
        setFiti(b);
        setFilel(false);
        setFileURL(data.location);
      })
      .catch((err) => {
        alert(err);
        return null;
      });
  };

  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) setInitialRender(false);
    else {
      onFileChange(selectedFile);
      setFilel(true);
    }
    // eslint-disable-next-line
  }, [selectedFile]);

  // new project modal
  const title = useRef(null);

  const [files, setFiles] = useState([]);

  const handleNewFile = () => {
    const prev = files;
    prev.push({
      title: fiti,
      description: descRef.current.value,
      file: fileURL,
    });
    setFiles(prev);
    setFileURL(null);
    onClose();
  };
  console.log(dataSheet);
  const [count, setCount] = useState(1);

  const removeFile = (id) => {
    const rand = files;
    // console.log(rand);
    rand.splice(id, 1);
    // console.log(rand);
    setFiles(rand);
    setCount(count + 1);
  };
  const [pageInfo, setPageInfo] = useState();

  const getPageInformation = () => {
    fetch({ Url }.Url + "/get_page/" + pageSlug + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            // console.log(res.data);
            setPageInfo(res.data);
            setPageTitle(res.data.title);
            if (title.current)
              title.current.value = res.data?.title
                ?.replace("🆕", "")
                .replace("📝", "");
            setValue(res.data?.key_findings);
            setCollapse(res.data?.collapse_value);
            // collapseRef.current.value = "sss"
            // setSheetData(res.data?.key_findings_spreadsheet);
            getToggles(res.data?.collapse_value);
            console.log(res.data?.key_findings_spreadsheet);
            if (res.data?.key_findings_spreadsheet[0])
              makeData(res.data?.key_findings_spreadsheet);
            else
              setSheetData([
                {
                  name: "Cell",
                  id: "0",
                  zoomRatio: 1,
                  order: "0",
                  column: 18,
                  addRows: 70,
                  row: 36,
                  status: 1,
                  celldata: [
                    {
                      r: 0,
                      c: 1,
                      v: {
                        v: "Start",
                        ct: {
                          fa: "General",
                          t: "g",
                        },
                        m: "Start",
                      },
                    },
                  ],
                  ch_width: 2321,
                  rh_height: 936,
                  luckysheet_select_save: [
                    {
                      left: 741,
                      width: 138,
                      top: 796,
                      height: 19,
                      left_move: 741,
                      width_move: 138,
                      top_move: 796,
                      height_move: 19,
                      row: [33, 33],
                      column: [6, 6],
                      row_focus: 33,
                      column_focus: 6,
                    },
                  ],
                  calcChain: [
                    {
                      r: 0,
                      c: 3,
                      id: "0",
                      func: [true, 3, "=Formula!A1+Formula!B1"],
                      color: "w",
                      parent: null,
                      chidren: {},
                      times: 0,
                    },
                  ],
                  scrollLeft: 0,
                  scrollTop: 0,
                },
              ]); // console.log(convert2DTo1D(res.data?.key_findings_spreadsheet[0].data));
            // setSheetData(convert2DTo1D(res.data?.key_findings_spreadsheet[0].data));
            // setUpdateData(convert2DTo1D(res.data?.key_findings_spreadsheet[0].data));

            console.log("AA", sheetData);

            // setSheetData(prev => ({...prev, data:upDate}));

            console.log(res.data.key_findings);
            setFiles(res.data?.files);
          } else {
            toast({
              title: "",
              description: res.data.detail,
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });

            // swal('Oops, something went wrong!', res.data.detail, 'error');
          }
        })
    );
  };

  function getToggles(data) {
    if (data === "none") {
      setTextToggle(true);
      setSheetToggle(true);
    } else if (data === "both") {
      setTextToggle(false);
      setSheetToggle(false);
    } else if (data === "document") {
      setTextToggle(false);
      setSheetToggle(true);
    } else if (data === "spreadsheet") {
      setTextToggle(true);
      setSheetToggle(false);
    }

    console.log(textToggle, sheetToggle);
  }
  const getTextToggle = () => {
    setTextToggle(!textToggle);
    setCollapse(calculateData(!textToggle, sheetToggle));
    console.log(textToggle, sheetToggle, collapse);
  };

  const getSheetToggle = () => {
    setSheetToggle(!sheetToggle);
    setCollapse(calculateData(textToggle, !sheetToggle));
    console.log(textToggle, sheetToggle, collapse);
  };

  const calculateData = (buttonAState, buttonBState) => {
    if (buttonAState && buttonBState) {
      return "none";
    } else if (buttonAState) {
      return "spreadsheet";
    } else if (buttonBState) {
      return "document";
    } else {
      return "both";
    }
  };
  function makeData(data) {
    console.log("function makeData", data);
    const data1 = convert2DTo1D(data[0].data);
    console.log("data1", data1);
    data[0].celldata = data1;
    setSheetData(data);
  }

  useEffect(() => {
    getPageInformation();
    // eslint-disable-next-line
  }, []);

  const timeoutId = useRef(null);
  const timeoutDuration = 120000; // 2 minutes in milliseconds

  useEffect(() => {
    clearTimeout(timeoutId.current);
    startTimeout();
  }, [files, value, title.current?.value, selectedFile]);

  const startTimeout = () => {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(handleTimeout, timeoutDuration);
  };

  const handleTimeout = async () => {
    // Perform any necessary actions here
    // console.log("Timeout occurred");

    // Call the editPageSession function
    try {
      await editPageSession();
      // Redirect to the home page
      window.location = "/page/" + pageSlug + "/";
      // console.log("Edited page session successfully")
    } catch (error) {
      console.error("Failed to edit page session: ", error);
    }
  };
  const descRef = useRef();

  const [fileURL, setFileURL] = useState(null);

  const handleFileInput = (e) => {
    setFileName(e.name);

    setSelectedFile(e);
    clearTimeout(timeoutId.current);
    startTimeout();
    // console.log(e);
  };

  function editPageHandler() {
    const rtitle = title.current.value;

    if (rtitle) {
      fetch({ Url }.Url + "/edit_page/" + pageSlug + "/", {
        method: "POST",
        body: JSON.stringify({
          title: rtitle,
          key_findings: value.toString("html"),
          files: files,
          key_findings_spreadsheet: data,
          collapse_value: collapse,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            if (res.status < 300) {
              toast({
                title: "Block edited Successfully!",
                description: "Block edited Successfully!",
                status: "success",
                duration: 2000,
                isClosable: true,
                position: "top-right",
                onCloseComplete: () => {
                  window.location = "/page/" + res.data.page_id;
                },
              });

              // swal('Block edited Successfully!', "Let's head over to the block.", 'success', { timer: 3000 }).then(() => (window.location = '/page/' + res.data.page_id));
            } else {
              toast({
                title: "Editing failed.",
                description: "Something went wrong.",
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top-right",
              });
              // swal('Editing failed.', 'Something went wrong.', 'error');
            }
          })
      );
    }
  }

  function editPageSession() {
    const rtitle = title.current.value;

    let hasChanged = false;
    if (pageInfo) {
      if (rtitle && rtitle !== pageInfo?.title) hasChanged = true;
      if (value.toString("html") !== pageInfo?.key_findings) hasChanged = true;
      if (files && files !== pageInfo?.files) hasChanged = true;
    }
    // console.log(rtitle, value.toString('html'), files)
    // console.log(pageInfo?.key_findings,value.toString('html'))
    if (rtitle && hasChanged === true) {
      return fetch({ Url }.Url + "/edit_page_session/" + pageSlug + "/", {
        method: "POST",
        body: JSON.stringify({
          title: rtitle,
          key_findings: value.toString("html"),
          files: files,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            if (res.status < 300) {
              // console.log("auto saved");
              getPageInformation();
              // swal('Block edited Successfully!', "Let's head over to the block.", 'success', { timer: 3000 }).then(() => (window.location = '/page/' + res.data.page_id));
            } else {
              // swal('Editing failed.', 'Something went wrong.', 'error');
            }
          })
      );
    }
  }
  function seeChange(e) {
    console.log(e);
  }

  useEffect(() => {
    const timeout = setTimeout(editPageSession, 15000); // 15 seconds

    return () => clearTimeout(timeout); // Clear the timeout on component unmount
  });

  const descRef2 = useRef();
  const fiTitle = useRef();
  const fileRef = useRef();
  const dataSheetRef = useRef(null);

  useEffect(() => {
    console.log(JSON.stringify(dataSheetRef));
    // eslint-disable-next-line
  }, [dataSheetRef]);

  function sendGoogleDoc() {
    clearTimeout(timeoutId.current);
    startTimeout();
    const abc = fileRef.current.value;
    const def = descRef2.current.value;
    const xyz = fiTitle.current.value;
    const prev = files;
    prev.push({
      title: xyz,
      description: def,
      file: abc,
    });
    setFiles(prev);
    onClose2();
    // fetch('https://fj.orangeyak.xyz/api/core/add_google_doc/', {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     page_id: pageSlug,
    //     file: abc,
    //     description: def,
    //     title: xyz,
    //   }),
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: 'Token ' + authCtx.token,
    //   },
    // }).then((response) =>
    //   response
    //     .json()
    //     .then((data) => ({
    //       data: data,
    //       status: response.status,
    //     }))
    //     .then((res) => {
    //       if (res.status < 300) {
    //         swal('Doc added Successfully!', "Let's head back to editing..", 'success');
    //         window.location.reload();
    //       } else {
    //         swal('Adding file failed.', res.data.detail, 'error');
    //       }
    //     })
    // );
  }

  const descRef3 = useRef();
  const titleRef3 = useRef();
  const [currDesc, setCurrDesc] = useState(null);
  const [currTitle, setCurrTitle] = useState(null);
  const [pageTitle, setPageTitle] = useState(null);
  const [currId, setCurrId] = useState(null);

  function fileEditHandler(ti, desc, id) {
    setCurrDesc(desc);
    setCurrTitle(ti);
    setCurrId(id);
    // console.log(currDesc);
    onOpen3();
  }
  const wsRef = useRef();
  const onOp = useCallback((op) => {
    const socket = wsRef.current;
    if (!socket) return;
    socket.send(JSON.stringify({ req: "op", data: op }));
  }, []);

  function editFile() {
    console.log("editted");
    const desc = descRef3.current.value;
    console.log(currId, desc, currTitle);
    console.log(
      JSON.stringify({
        file_id: files[currId].id,
        title: titleRef3.current.value,
        description: desc,
      })
    );
    console.log(titleRef3.current.value, desc);
    fetch({ Url }.Url + "/edit_page/" + pageSlug + "/", {
      method: "POST",
      body: JSON.stringify({
        files: [
          ...files,
          {
            id: files[currId].id,
            description: desc,
            title: titleRef3.current.value,
          },
        ],

        // title: titleRef3.current.value,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            toast({
              title: "File edited Successfully!",
              description: "Let's head back to editing..",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
            // swal('File edited Successfully!', "Let's head back to editing..", 'success');
            getPageInformation();
          } else {
            toast({
              title: "Editing file failed.",
              description: res.data.detail,
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
            // swal('Editing file failed.', res.data.detail, 'error');
          }
        })
    );

    onClose3();
  }

  function hashCode(str) {
    let hash = 0;
    let i;
    let chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i += 1) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  const lastSelection = useRef();
  const { username, userId } = useMemo(() => {
    const _userId = uuidv4();
    return { username: `User-${_userId.slice(0, 3)}`, userId: _userId };
  }, []);

  const afterSelectionChange = useCallback(
    (sheetId, selection) => {
      const socket = wsRef.current;
      if (!socket) return;
      const s = {
        r: selection.row[0],
        c: selection.column[0],
      };
      if (
        lastSelection.current?.r === s.r &&
        lastSelection.current?.c === s.c
      ) {
        return;
      }
      lastSelection.current = s;
      socket.send(
        JSON.stringify({
          req: "addPresences",
          data: [
            {
              sheetId,
              username,
              userId,
              color: colors[Math.abs(hashCode(userId)) % colors.length],
              selection: s,
            },
          ],
        })
      );
    },
    [userId, username]
  );
  function onChangeSheet(d) {
    // {d?setData(d.current.value):<></>};
    if (d) setData(d);
    console.log(data);
    // console.log(data[0].data)/
    // const newd = data[0].data
    // console.log(convert2DTo1D(newd))
  }

  useHotkeys("alt+x", () => (window.location = "/page/" + pageSlug));
  useHotkeys("option+x", () => (window.location = "/page/" + pageSlug));
  useHotkeys("alt+n", () => onOpen());
  useHotkeys("option+n", () => onOpen());
  useHotkeys("alt+s", () => editPageHandler());
  useHotkeys("option+s", () => editPageHandler());

  const breadcrumbs = [
    { path: "/", breadcrumb: "Home" },
    { path: "/library", breadcrumb: "Library" },
    {
      path: "/project/" + pageInfo?.project?.id,
      breadcrumb: pageInfo?.project?.title,
    },
    { path: "/page/" + pageInfo?.id, breadcrumb: pageInfo?.title },

    { path: "/edit-page/" + pageInfo?.id, breadcrumb: "Edit", end: true },
  ];
  // HTML element tree ̦— render function
  return (
    <Flex flexDir="column" alignItems="center" width="100%">
      {pageInfo && (
        <Flex
          width={{ base: "100%", lg: "95%" }}
          height="fit-content"
          flexDir="column"
          justifyContent="space-between"
          px="10px"
          pb="80px"
        >
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <Flex flexDir="row" justifyContent="space-between" mt="10px">
            <Flex flexDir="column">
              <Text color="main.grey1" fontSize="18px" fontWeight="700">
                Please edit critical information before confirming this base
                block.
              </Text>
            </Flex>
          </Flex>

          <Flex
            flexDir={{ base: "column", xl: "row" }}
            justifyContent="space-between"
          >
            <Flex width="50%" flexDir="column">
              <Flex justifyContent="space-between" alignItems="center">
                <Flex width="100%">
                  <FormControl mt="20px">
                    <FormLabel color="white" fontSize="18px" fontWeight="700">
                      Title of the Base Block
                    </FormLabel>
                    <Input
                      id="name"
                      value={pageTitle}
                      onChange={(e) => setPageTitle(e.target.value)}
                      bg="background.grey3"
                      color="white"
                      fontWeight="600"
                      focusBorderColor="white"
                      width={{ base: "100%", xl: "80%" }}
                      border="none"
                      size="lg"
                      type="text"
                      placeholder="Enter block name here"
                      autoComplete="off"
                      ref={title ? title : ""}
                      maxLength="40"
                    />
                  </FormControl>{" "}
                </Flex>
              </Flex>

              <FormControl mt="20px" className="editPageForm">
                <FormLabel color="white" fontSize="18px" fontWeight="700">
                  Key Findings
                </FormLabel>
                <Flex className="ckparent" mr={{ xl: 3 }}>
                  <CKEditor
                    data={value ? value : ""}
                    editor={ClassicEditor}
                    config={{
                      typing: {
                        transformations: {
                          remove: [
                            "symbols",
                            "quotes",
                            "arrowLeft",
                            "arrowRight",
                            "mathematical",
                            "typography",
                          ],
                        },
                      },

                      toolbar: {
                        items: [
                          "heading",
                          "|",
                          "fontfamily",
                          "fontsize",
                          "fontColor",
                          "fontBackgroundColor",
                          "|",
                          "alignment",
                          "insertTable",
                          "|",
                          "bold",
                          "italic",
                          "strikethrough",
                          "underline",
                          "subscript",
                          "superscript",
                          "|",
                          "link",
                          "|",
                          "bulletedList",
                          "numberedList",
                          "todoList",
                          "undo",
                          "redo",
                          "highlight",
                          "|",
                          "horizontalLine",
                          "removeFormat",
                          "|",
                        ],
                      },
                    }}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setAttribute(
                          "spellcheck",
                          "false",
                          editor.editing.view.document.getRoot()
                        );
                        writer.setAttribute(
                          "autocomplete",
                          "off",
                          editor.editing.view.document.getRoot()
                        );
                        writer.setAttribute(
                          "autocorrect",
                          "off",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onChange={(event, editor) => {
                      editorHandler(event, editor);
                    }}
                  />
                  {/* <div style={{ width: "100%", height: "70vh" }} className="sheet-editor2">
                   
                   <Workbook            
                     data={sheetData}
                     onChange={(data)=>{onChangeSheet(data)}}              
                   />
                 </div> */}
                </Flex>
              </FormControl>
              {/* Spreadsheet Commit */}
              {/* <FormControl
                mt={"20px"}
                style={{ width: "55vw", height: "70vh" }}
              >
                <div
                  style={{ width: "100%", height: "70vh" }}
                  className="sheet-editor2"
                >
                  <Workbook
                    data={sheetData}
                    onChange={(data) => {
                      onChangeSheet(data);
                    }}
                  />
                </div>
              </FormControl> */}
            </Flex>
            {/* Spreadsheet Commit */}
            {/* <Flex flexDir="column" justifyContent={"space-evenly"}>
            <Flex
              ml={"-90px"}
              mt={"-40vh"}
              color="main.plainWhite"
              justifyContent="space-between"
              alignItems="center"
            >
              <Tooltip label={textToggle?"Hide Document":"Show Document"}><Box><Switch isChecked={textToggle} onChange={getTextToggle} /></Box></Tooltip>
            </Flex>
            <Flex
              ml={"-90px"}
              mt={"10vh"}
              color="main.plainWhite"
              justifyContent="space-between"
              alignItems="center"
            >
              <Tooltip label={sheetToggle?"Hide Spreadsheet":"Show Spreadsheet"}><Box><Switch isChecked={sheetToggle} onChange={getSheetToggle} /></Box></Tooltip>
            </Flex>
            </Flex> */}

            <Flex flexDir="column">
              <Text color="white" fontSize="18px" fontWeight="700" mb="10px">
                Add Files
              </Text>

              {console.log(files)}

              {count && files ? (
                <>
                  <ReactSortable
                    list={files}
                    setList={setFiles}
                    animation={200}
                    delayOnTouchStart={true}
                    swap
                    // ghostClass={"ghost_reorder"}
                    filter={"ignore-elements"}
                    chosenClass={"chosen_reorder"}
                  >
                    {/* {console.log(files)} */}
                    {files
                      ? files.map((file, id) => (
                          <>
                            <Flex
                              flexDir="row"
                              key={id}
                              bg="background.grey3"
                              p="20px"
                              // maxW={"25vw"}
                              borderRadius="10px"
                              cursor="move"
                              mb="10px"
                            >
                              <Flex
                                flexDir="column"
                                width="100%"
                                justifyContent="space-between"
                              >
                                <Flex flexDir="row">
                                  <Text
                                    color="#A0AFBF"
                                    fontSize="18px"
                                    lineHeight="1.3"
                                    fontWeight="700"
                                    as="a"
                                    href={file.file}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {id + 1}. Name:&nbsp;
                                  </Text>
                                  <Text
                                    color="white"
                                    fontSize="18px"
                                    lineHeight="1.3"
                                    fontWeight="700"
                                    as="a"
                                    href={file.file}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {file.title}
                                  </Text>
                                </Flex>

                                <Flex flexDir="row">
                                  <Text
                                    color="#A0AFBF"
                                    fontSize="18px"
                                    lineHeight="1.3"
                                    fontWeight="700"
                                    as="a"
                                    href={file.file}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Description:&nbsp;
                                  </Text>
                                  <Text
                                    color="white"
                                    fontSize="18px"
                                    lineHeight="1.3"
                                    fontWeight="700"
                                    as="a"
                                    maxW={"20vw"}
                                    href={file.file}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {file.description}{" "}
                                    {/* lorem.paralorem.paralorem.paralorem.paralorem.paralorem.paralorem.paralorem.paralorem.para */}
                                  </Text>
                                </Flex>
                              </Flex>
                              <Flex flexDir="column">
                                <Icon
                                  as={BiTrash}
                                  m="3px"
                                  color="white"
                                  height="18px"
                                  width="18px"
                                  cursor="pointer"
                                  onClick={() => removeFile(id)}
                                />
                                <Icon
                                  as={BiEdit}
                                  m="3px"
                                  color="white"
                                  height="18px"
                                  width="18px"
                                  cursor="pointer"
                                  onClick={() =>
                                    fileEditHandler(
                                      file.title,
                                      file.description,
                                      id
                                    )
                                  }
                                />
                              </Flex>
                            </Flex>
                          </>
                        ))
                      : null}
                  </ReactSortable>
                </>
              ) : null}
            </Flex>
          </Flex>
        </Flex>
      )}
      <Flex
        height="fit-content"
        flexDir={{ base: "column", md: "row" }}
        justifyContent="space-between"
        mt="10px"
        position="fixed"
        pb="10px"
        pt="8px"
        zIndex={"2000"}
        bg="background.main"
        bottom="0"
        width="95%"
      >
        <Button
          bg="background.yellow1"
          color="white"
          fontSize="16px"
          onClick={editPageHandler}
          _hover={{
            background: "#F2DBA6",
          }}
          ml="10px"
        >
          Confirm changes
        </Button>
        <Flex flexDir={{ base: "column", md: "row" }}>
          <Button
            mr="10px"
            bg="background.grey3"
            color="white"
            onClick={onOpen}
          >
            Upload New File
          </Button>

          <Button
            mr="10px"
            bg="background.grey3"
            color="white"
            onClick={onOpen2}
          >
            Add link to a New File
          </Button>
        </Flex>
      </Flex>
      <>
        <Modal
          bg={"background.grey0"}
          isOpen={isOpen}
          onClose={onCloseHandler}
          isCentered
        >
          <ModalOverlay BG={"background.grey0"} />
          <ModalContent bg={"background.grey0"} color="white">
            <ModalHeader>Upload New File</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontWeight="700" fontSize="18px" mb="10px">
                Upload File
              </Text>
              <label
                for="file_upload"
                style={{
                  borderColor: "#6c757d",
                  display: "block",
                  width: "100%",
                  borderWidth: "2px",
                  borderStyle: "dashed",
                  borderRadius: "5px",
                  padding: "10px",
                  textAlign: "center",
                  fontSize: "1rem",
                  cursor: "pointer",
                  color: "#6c757d",
                }}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleFileDrop}
              >
                {fileName
                  ? "File uploaded: " + fileName
                  : "Select or drag a file"}
              </label>
              <Input
                bg={"background.grey1"}
                id="file_upload"
                type="file"
                onChange={(e) => handleFileInput(e.target.files[0])}
              />
              {/* <FileUploader
              // multiple={true}
              style={{color:"white",fontSize:"18px",border:"none"}}
              multiple={false}
              handleChange={handleFileInput}
              name="file"
            />
             */}
              <Text fontWeight="700" fontSize="18px" mb="5px" mt="10px">
                Enter file description
              </Text>
              <Input placeholder="Enter file description" ref={descRef} />
            </ModalBody>

            <ModalFooter>
              <Button
                bg="background.yellow1"
                mr={3}
                isDisabled={fileURL === null}
                onClick={handleNewFile}
              >
                {filel ? "Uploading..." : "Submit"}
              </Button>
              <Button variant="ghost" onClick={onCloseHandler}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
      <>
        <Modal
          bg={"background.grey0"}
          isOpen={isOpen2}
          onClose={onClose2}
          isCentered
        >
          <ModalOverlay BG={"background.grey0"} />
          <ModalContent bg={"background.grey0"} color="white">
            <ModalHeader>Add link to a New File</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontWeight="700" fontSize="18px" mb="5px" mt="10px">
                Enter file title
              </Text>
              <Input
                bg={"background.grey1"}
                placeholder="Enter file title"
                ref={fiTitle}
                maxLength="40"
              />
              <Text fontWeight="700" fontSize="18px" mb="5px" mt="10px">
                Add link to file
              </Text>
              <Input
                bg={"background.grey1"}
                placeholder="Enter file link"
                ref={fileRef}
              />
              <Text fontWeight="700" fontSize="18px" mb="5px" mt="10px">
                Enter file description
              </Text>
              <Input
                bg={"background.grey1"}
                placeholder="Enter file description"
                ref={descRef2}
              />
            </ModalBody>

            <ModalFooter>
              <Button bg="background.yellow1" mr={3} onClick={sendGoogleDoc}>
                Submit
              </Button>
              <Button variant="ghost" onClick={onClose2}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isOpen3} onClose={onClose3} isCentered>
          <ModalOverlay />
          <ModalContent bg="background.grey3" color="white">
            <ModalHeader>Edit File Detail</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontWeight="700" fontSize="18px" mb="5px" mt="10px">
                Enter file title
              </Text>
              <Input
                placeholder="Enter file description"
                ref={titleRef3}
                value={currTitle}
                onChange={(e) => setCurrTitle(e.target.value)}
              />
              <Text fontWeight="700" fontSize="18px" mb="5px" mt="10px">
                Enter file description
              </Text>
              <Input
                placeholder="Enter file description"
                ref={descRef3}
                value={currDesc}
                onChange={(e) => setCurrDesc(e.target.value)}
              />
            </ModalBody>

            <ModalFooter>
              <Button bg="background.yellow1" mr={3} onClick={editFile}>
                Save
              </Button>
              <Button variant="ghost" onClick={onClose3}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </Flex>
  );
};

export default EditPage;