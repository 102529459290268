import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Select,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Spinner,
  Checkbox,
  Tag,
  Icon,
  Box,
} from "@chakra-ui/react";
import { ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react";
import { CheckboxGroup } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { BsFolderPlus } from "react-icons/bs";
import {
  BsFillFolderFill,
  BsPencilFill,
  BsSearch,
  BsHouse,
  BsTrash,
  BsStar,
  BsFillStarFill,
} from "react-icons/bs";
import {
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
} from "@chakra-ui/react";
import AuthContext from "../store/auth";
import swal from "sweetalert";
import Pagination from "../components/Pagination";
import { useHotkeys } from "react-hotkeys-hook";
import Breadcrumbs from "../components/BreadCrumbs";
import { Url } from '../store/global';

const roleFilters = [
  { index: 0, value: "owner", label: "Owner", isChecked: false },
  { index: 1, value: "collaborator", label: "Collaborator", isChecked: false },
  // {value: "client", label: "Client"},
  { index: 2, value: "approver", label: "Approver", isChecked: false },
];

const industryFilters = [
  {
    index: 0,
    value: "Financial+Services",
    label: "Financial Services",
    isChecked: false,
  },
  { index: 1, value: "Healthcare", label: "Healthcare", isChecked: false },
  { index: 2, value: "Retail", label: "Retail", isChecked: false },
  { index: 3, value: "Technology", label: "Technology", isChecked: false },
  { index: 4, value: "Legal", label: "Legal", isChecked: false },
  { index: 5, value: "Government", label: "Government", isChecked: false },
  { index: 6, value: "Other", label: "Other", isChecked: false },
];

const Library = () => {
  // authentication module
  const toast = useToast();

  const authCtx = useContext(AuthContext);

  // filter state
  let { projectSlug } = useParams();
  const [activeFilter, setActiveFilter] = useState(
    window.localStorage.getItem("activeFilter")
      ? projectSlug
        ? projectSlug
        : window.localStorage.getItem("activeFilter")
      : "all"
  );

  // new project modal
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const title = useRef();
  const industry = useRef();
  const folderSlug = useRef();
  const client = useRef();
  const searchRef = useRef();
  const visibility = useRef();
  const code = useRef();

  // projects list object state
  const [projectInfo, setProjectInfo] = useState();
  const [projectInfo2, setProjectInfo2] = useState();

  const [folders, setFolders] = useState();

  // folder info state
  const [checkProjects, setCheckedProjects] = useState([]);
  const [projects, setProjects] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const [sortby, setSortby] = useState("last_modified");
  const [roles, setRoles] = useState("");
  const [industries, setIndustries] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [colorFont, setColorFont] = useState();
  const [fav, setFav] = useState();

  const [folderInfo, setFolderInfo] = useState();
  const [filters, setFilters] = useState([]);

  const logoutHandler = () => {
    authCtx.logout();
  };
  // bot here represents the column currently being sorted
  // const [bot, setBot] = useState(JSON.parse(window.localStorage.getItem('sortingIndex')) ? JSON.parse(window.localStorage.getItem('sortingIndex')) : 0);
  // direction here represents the direction of sorting
  const [direction, setDirection] = useState(-1);
  // const [direction, setDirection] = useState(-1);

  // sortingIndex represents the column that was last sorted
  // const [sortingIndex, setCurr] = useState(0);

  const [search, setSearch] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  // const [currentLength, setCurrentLength] = useState(projectInfo?projectInfo.length:0);
  const currentLength = useRef(projectInfo2 ? projectInfo2.length : 0);
  let PageSize = 15;

  // const projectInfoData = useMemo(() => {
  //   const firstPageIndex = (currentPage - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;
  //   currentLength.current = projectInfo2 ? (projectInfo2.filter(searchValid) ? projectInfo2.length : projectInfo2.length) : 0;
  //   // console.log(currentLength.current, projectInfo2?.length)
  //   // projectInfo?setCurrentLength(projectInfo.filter(searchValid).length):setCurrentLength(0);

  //   return projectInfo ? projectInfo.filter(searchValid).slice(firstPageIndex, lastPageIndex) : [];
  // }, [currentPage, projectInfo2, projectInfo, bot, search, filters]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search, activeFilter, filters]);

  //   useEffect(()=>{
  //     getProjectsHandler();
  //     // if(fav)
  //     // setColorFont('yellow');
  //     // else
  //     // setColorFont('green');

  // }, [fav])

  // console.log(projectInfoData)
  useEffect(() => {
    if (projectInfo) currentLength.current = projectInfo.length;
  }, [projectInfo]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  function handleFilterChange(updatedFilter) {
    setFilters((prevFilters) =>
      prevFilters.map((filter) => {
        if (filter.value == updatedFilter.value) {
          // console.log(filter.value, updatedFilter.value);
          filter.isChecked = updatedFilter.isChecked;
        }

        return filter;
      })
    );
    // console.log(filters);
  }

  // // Use the filters object to filter your data
  // const filteredData = projectInfo.filter(item => {
  //   // Check if each filter applies to the item
  //   return Object.keys(filters).every(filterKey => {
  //     const filterValue = filters[filterKey];
  //     return item[filterKey] === filterValue;
  //   });
  // });

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const getFolders = () => {
    fetch({Url}.Url+"/get_folder_titles/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setFolders(res.data);
            console.log(res.data);
          } else {
            toast({
              title: "Failed to fetch folders",
              description: "Something went wrong.",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
            // swal('Failed to fetch folders', 'Something went wrong.', 'error');
          }
        })
    );
  };

  const addProjectToFolderHandler = () => {
    // const type = add_type.current.value;
    if (checkProjects) {
      const idArray = checkProjects?.map((item) => item.toString()).join(",");
      console.log(idArray);
      // onClose2();
      fetch(
        {Url}.Url+"/add_remove_items_from_folder/" +
          folderSlug.current.value +
          "/",
        {
          method: "POST",
          body: JSON.stringify({
            type: "project",
            item_id: idArray,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + authCtx.token,
          },
        }
      ).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            if (res.status < 300) {
              toast({
                title: "Addition confirmed.",
                description: "Let's head back to the folder.",
                status: "success",
                position: "top-right",
                duration: 2000,
                isClosable: true,
                // position: "top-right",
                onCloseComplete: () => {
                  window.location = "/folder/" + folderSlug.current.value;
                  onClose1();
                  // onClose2();
                },
              }).then(() => {
                // getfolderInformation();
                // onClose();
                // onClose2();
              });
            } else {
              setCheckedProjects([]);
              toast({
                title: "Oops, something went wrong!",
                description: res.data.detail,
                status: "error",
                position: "top-right",
                duration: 2000,
                isClosable: true,
                onCloseComplete: () => {
                  window.location = "/library";
                  onClose1();
                  // onClose2();
                },
              });
            }
          })
      );
    } else {
      toast({
        title: "First select a project!",
        description: "",
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  // POST request to create a new project
  const addProjectHandler = () => {
    // onClose();

    const rtitle = title.current.value;
    const rindustry = industry.current.value;
    const rclient = client.current.value;
    const rcode = code.current.value;
    const rvisibility = visibility.current.value;

    if (rtitle && rindustry && rclient && rcode) {
      fetch({Url}.Url+"/create_project/", {
        method: "POST",
        body: JSON.stringify({
          title: rtitle,
          industry: rindustry,
          client_name: rclient,
          project_code: rcode,
          visibility: rvisibility,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            if (res.status < 300) {
              // console.log(res.data);
              toast({
                title: "Project created successfully!",
                description: "Let's visit the new project page.",
                status: "success",
                duration: 2000,
                isClosable: true,
                position: "top-right",
                onCloseComplete: () => {
                  window.location = "/project/" + res.data.project_id;
                },
              });
            } else {
              // console.log(res.data);
              toast({
                title: "Project created failed.",
                description: res.data.detail,
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top-right",
              });
            }
          })
      );
    }
  };

  // API GET request to query all the projects
  // const getProjects = () => {
  //   fetch({Url}.Url+"/library/", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Token " + authCtx.token,
  //     },
  //   }).then((response) =>
  //     response
  //       .json()
  //       .then((data) => ({
  //         data: data,
  //         status: response.status,
  //       }))
  //       .then((res) => {
  //         if (res.status < 300) {
  //           setProjectInfo(res.data.projects);
  //           setFolderInfo(res.data.folders);
  //           // console.log(res.data);
  //           console.log(projectInfo);
  //           setProjectInfo2(res.data.projects);
  //           // console.log(direction);
  //         } else {
  //           toast({
  //             title: "Oops, something went wrong!",
  //             description: res.data.detail,
  //             status: "error",
  //             duration: 2000,
  //             isClosable: true,
  //             position: "top-right",
  //           });
  //         }
  //       })
  //   );
  // };
  // const sortLol = (direction, data, setFunction) => {
  //   // console.log(bot)

  //   // console.log(data);
  //   if (bot === 0) {
  //     return;
  //   }
  //   let sortedData = data;
  //   if (bot === 1) {
  //     if (sortingIndex !== 1) {
  //       setCurr(1);
  //       setDirection(-1);
  //     } else setDirection(-1 * direction);
  //     sortedData = data.sort((a, b) => {
  //       if (a.project_code.toLowerCase() < b.project_code.toLowerCase()) return sortingIndex !== 1 ? -1 : direction === 1 ? -1 : 1;
  //       if (a.project_code.toLowerCase() > b.project_code.toLowerCase()) return sortingIndex !== 1 ? 1 : direction === 1 ? 1 : -1;
  //       return 0;
  //     });
  //   }
  //   if (bot === 2) {
  //     if (sortingIndex !== 2) {
  //       setCurr(2);
  //       setDirection(-1);
  //     } else setDirection(-1 * direction);
  //     sortedData = data.sort((a, b) => {
  //       if (a.title.toLowerCase() < b.title.toLowerCase()) return sortingIndex !== 2 ? -1 : direction === 1 ? -1 : 1;
  //       if (a.title.toLowerCase() > b.title.toLowerCase()) return sortingIndex !== 2 ? 1 : direction === 1 ? 1 : -1;
  //       return 0;
  //     });
  //   }
  //   if (bot === 3) {
  //     if (sortingIndex !== 3) {
  //       setCurr(3);
  //       setDirection(-1);
  //     } else setDirection(-1 * direction);
  //     sortedData = data.sort((a, b) => {
  //       if (a.client_name.toLowerCase() < b.client_name.toLowerCase()) return sortingIndex !== 3 ? -1 : direction === 1 ? -1 : 1;
  //       if (a.client_name.toLowerCase() > b.client_name.toLowerCase()) return sortingIndex !== 3 ? 1 : direction === 1 ? 1 : -1;
  //       return 0;
  //     });
  //   }

  //   if (bot === 4) {
  //     if (sortingIndex !== 4) {
  //       setCurr(4);
  //       setDirection(-1);
  //     } else setDirection(-1 * direction);
  //     sortedData = data.sort((a, b) => {
  //       if (a.industry < b.industry) return sortingIndex !== 4 ? -1 : direction === 1 ? -1 : 1;
  //       if (a.industry > b.industry) return sortingIndex !== 4 ? 1 : direction === 1 ? 1 : -1;
  //       return 0;
  //     });
  //   }
  //   if (bot === 5) {
  //     if (sortingIndex !== 5) {
  //       setCurr(5);
  //       setDirection(-1);
  //     } else setDirection(-1 * direction);
  //     sortedData = data.sort((a, b) => {
  //       if (a.project_owner < b.project_owner) return sortingIndex !== 5 ? -1 : direction === 1 ? -1 : 1;
  //       if (a.project_owner > b.project_owner) return sortingIndex !== 5 ? 1 : direction === 1 ? 1 : -1;
  //       return 0;
  //     });
  //   }
  //   if (bot === 6) {
  //     if (sortingIndex !== 6) {
  //       setCurr(6);
  //       setDirection(-1);
  //     } else setDirection(-1 * direction);
  //     sortedData = data.sort((a, b) => {
  //       if (a.last_modified < b.last_modified) return sortingIndex !== 6 ? -1 : direction === 1 ? -1 : 1;
  //       if (a.last_modified > b.last_modified) return sortingIndex !== 6 ? 1 : direction === 1 ? 1 : -1;
  //       return 0;
  //     });
  //   }
  //   if (bot === 7) {
  //     if (sortingIndex !== 7) {
  //       setCurr(7);
  //       setDirection(-1);
  //     } else setDirection(-1 * direction);
  //     sortedData = data.sort((a, b) => {
  //       if (a.role < b.role) return sortingIndex !== 7 ? -1 : direction === 1 ? -1 : 1;
  //       if (a.role > b.role) return sortingIndex !== 7 ? 1 : direction === 1 ? 1 : -1;
  //       return 0;
  //     });
  //   }

  //   setBot(0);
  //   setFunction(sortedData);
  //   console.log(sortedData);
  // };
  function searchValid(project) {
    return (
      project.title.toLowerCase().includes(search.toLowerCase()) ||
      project.project_owner.name.toLowerCase().includes(search.toLowerCase()) ||
      project.project_code.toLowerCase().includes(search.toLowerCase()) ||
      project.client_name.toLowerCase().includes(search.toLowerCase()) ||
      project.industry.toLowerCase().includes(search.toLowerCase())
    );
  }

  // function to get sorted and filtered results from the backend api
  const getProjectsHandler = (direction, sort, industry, role, pageNo) => {
    setIsLoading(true);
    //  console.log(
    //   {Url}.Url+"/projects/?" +
    //     `sort=${
    //       (direction === 1 ? "" : "-") + sort
    //     }&industry=${industry}&role=${role}&page=${pageNo}`
    // );
    fetch(
      {Url}.Url+"/projects/?" +
        `sort=${
          (sort ? (direction === 1 ? "" : "-") : "") + sort
        }&industry=${industry}&role=${role}&page=${pageNo}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setProjectInfo(() => {
              let data = res.data.results;
              setIsLoading(false);
              return data;
            });
            // console.log(res.data.results);
            console.log(res.data.results);

            setPageCount(Math.ceil(res.data.count / 15));
          } else {
            toast({
              title: "Oops, something went wrong!",
              description: res.data.detail,
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
          }
        })
    );
  };
  const handlePrevious = () => {
    setPageNo((oldPage) => {
      const newPage = Math.max(oldPage - 1, 1);
      getProjectsHandler(direction, sortby, industries, roles, newPage);
      return newPage;
    });
  };

  const handleNext = () => {
    setPageNo((oldPage) => {
      const newPage = Math.min(oldPage + 1, pageCount);
      getProjectsHandler(direction, sortby, industries, roles, newPage);
      return newPage;
    });
  };
  const handlePage = (page) => {
    setPageNo(page);
    getProjectsHandler(direction, sortby, industries, roles, page);
  };

  useEffect(() => {
    getProjectsHandler(direction, sortby, industries, roles, pageNo);
    // console.log(direction, sortby);
  }, [direction, roles, industries, pageNo, sortby, fav]);

  // runs on initial page load
  useEffect(() => {
    // getProjects();
    // projectSlug && setActiveFilter(projectSlug) ;
    // JSON.parse(window.localStorage.getItem("currentPage"))
    //   ? setCurrentPage(JSON.parse(window.localStorage.getItem("currentPage")))
    //   : setCurrentPage(1);
    // console.log(currentPage)
    // setCurr(JSON.parse(window.localStorage.getItem('sortingIndex')));
    getProjectsHandler(direction, sortby, industries, roles, pageNo);
    // eslint-disable-next-line
  }, []);

  // // set current page to local storage
  // useEffect(() => {
  //   window.localStorage.setItem("currentPage", JSON.stringify(currentPage));
  // }, [currentPage]);

  const [first, setFirst] = useState(0);

  useEffect(() => {
    // setDirection(-1*direction);
    window.localStorage.setItem("sortby", sortby);
    window.localStorage.setItem("direction", direction);

    // eslint-disable-next-line
  }, [sortby, direction]);

  // setting filters to local storage
  useEffect(() => {
    activeFilter && window.localStorage.setItem("activeFilter", activeFilter);
    // console.log(activeFilter);
  }, [activeFilter]);

  // const [charRemain, setCharRemain] = useState(30);
  const maxChar = 40;
  const onEscape = function (action) {
    window &&
      window.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          action();
        }
      });
  };

  onEscape(() => {
    searchRef.current.blur();
    // console.log(searchRef.current.focus);
  });
  // useEffect(() => {
  //   setCharRemain( maxChar - title.current.value.length )
  // }, [title.current.value]);

  // // Save to local storage to persist state on reload
  // useEffect(() => {
  //   setDirection(JSON.parse(window.localStorage.getItem('direction')));
  //   setCurr(JSON.parse(window.localStorage.getItem('sortingIndex')));
  // }, []);

  // useEffect(() => {
  //   window.localStorage.setItem('direction', direction);
  //   window.localStorage.setItem('sortingIndex', sortingIndex);
  // }, [direction,sortingIndex]);

  // HTML element tree ̦— render function
  const errorProject = () => {
    toast({
      title: "First select a project!",
      description: "",
      status: "error",
      position: "top-right",
      duration: 2000,
      isClosable: true,
    });
  };
  const handleFilterChange1 = (filter) => {
    setPageNo(1);
    let updatedRoles = roles ? roles.split(",") : [];
    // console.log(updatedRoles, roles);

    if (filter.isChecked) {
      // Add role to the roles state if the checkbox is checked
      updatedRoles.push(filter.value);
    } else {
      // Remove role from the roles state if the checkbox is unchecked
      updatedRoles = updatedRoles.filter((role) => role !== filter.value);
    }

    // Update the roles state
    // console.log(updatedRoles);
    setRoles(updatedRoles.join(","));
  };
  const handleFilterChange2 = (filter) => {
    setPageNo(1);
    let updatedIndustries = industries ? industries.split(",") : [];

    if (filter.isChecked) {
      // Add industry to the industries state if the checkbox is checked
      updatedIndustries.push(filter.value);
    } else {
      // Remove industry from the industries state if the checkbox is unchecked
      updatedIndustries = updatedIndustries.filter(
        (industry) => industry !== filter.value
      );
    }

    // Update the industries state
    setIndustries(updatedIndustries.join(","));
  };

  const checkBoxList = (e) => {
    console.log(e);
    if (!checkProjects.includes(e)) checkProjects.push(e);
    else checkProjects.splice(checkProjects.indexOf(e), 1);

    console.log(checkProjects);
  };

  // shortcuts used
  useHotkeys("alt+x", () => (window.location = "/dashboard"));
  useHotkeys("option+x", () => (window.location = "/dashboard"));
  useHotkeys("alt+n", () => onOpen());
  useHotkeys("option+n", () => onOpen());
  useHotkeys("alt+q", () => searchRef.current.focus());
  useHotkeys("option+q", () => searchRef.current.focus());
  const breadcrumbs = [
    { path: "/", breadcrumb: "Home" },
    { path: "/library", breadcrumb: "Library", end: true },
  ];

  const setFavorites = (item) => {
    console.log(item.favorite);
    if (item.favorite) {
      fetch(
        {Url}.Url+"/remove_project_from_favorite/" +
          item.id +
          "/",
        {
          method: "POST",
          body: JSON.stringify({
            //  version_id: activeVersion,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + authCtx.token,
          },
        }
      ).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            console.log(res);
            //  toggleColorMode();
            // setFav(projectInfo.favorite)
            setFav(false);
            console.log(item.favorite, "change");
            setColorFont("white");
            toast({
              title: "Removed from Favorites!",
              description: "Done!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
          })
      );
    } else {
      fetch(
        {Url}.Url+"/add_project_to_favorite/" +
          item.id +
          "/",
        {
          method: "POST",
          body: JSON.stringify({
            //  version_id: activeVersion,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + authCtx.token,
          },
        }
      ).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            console.log(res);
            //  toggleColorMode();
            setFav(true);
            console.log(item.favorite, "change");
            setColorFont("yellow");
            toast({
              title: "Added to Favorites!",
              description: "Done!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
          })
      );
    }
  };

  const searchHandler = () => {
    setIsLoading(true);
    let url =
      {Url}.Url+"/search/?type=project_deepsearch&q=" +
      searchRef.current.value;
    // console.log(url);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            // function sortByDate(jsonArray, dateKey) {
            //   return jsonArray.sort(function(a, b) {
            //     var dateA = new Date(a[dateKey]).getTime();
            //     var dateB = new Date(b[dateKey]).getTime();
            //     return dateA > dateB ? -1 : 1;
            //   });
            // }
            // sort by result by last modified date
            // var lol = sortByDate(res?.data?.results, "last_modified");
            // console.log(res?.data);
            setProjectInfo(() => {
              let data = res?.data?.results;
              setIsLoading(false);
              return data;
            });
            setPageCount(Math.ceil(res.data?.results?.length / 15));
          } else {
            toast({
              title: "Oops, something went wrong!",
              description: res.data.detail,
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
          }
        })
    );
  };
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    // console.log(search);
    const timeoutId = setTimeout(() => {
      if (search !== "") {
        searchHandler(search);
      } else {
        getProjectsHandler(direction, sortby, industries, roles, pageNo);
      }
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [search]);

  return (
    // <HotKeys
    //   style={{ width: "100%" }}
    //   // width="100%"
    //   tabIndex="0"
    //   keyMap={{
    //     backLibDash: ["alt+x", "option+x"],
    //   }}
    //   handlers={{
    //     backLibDash: React.useCallback((e) => {
    //         e.preventDefault()
    //         // logic here
    //         window.location="/dashboard"
    //       }, [])

    //   }}
    // >
    <Flex
      backgroundColor="bg"
      flexDir="column"
      alignItems="center"
      minHeight="100vh"
      height="fit-content"
      width="100%"
    >
      <Flex
        width={{ base: "100%", md: "95%" }}
        height="fit-content"
        flexDir="column"
        justifyContent="space-between"
        px="10px"
      >
        <Flex
          w="100%"
          color="white"
          fontSize="20px"
          fontWeight="400"
          mb="10px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Flex>
        {/* <InputGroup width="80%" m="auto">
          <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
          <Input ref={searchRef} onChange={handleSearchChange} placeholder="Search projects by title, author, client, category, or project code" color="white" />
        </InputGroup> */}
        <Flex
          flexDir={{ base: "column", md: "row" }}
          alignItems={{ base: "", md: "center" }}
          justifyContent="space-between"
        >
          <InputGroup maxW={"550px"} color={"white"} mr={3}>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon />}
              height="44px"
            />
            <Input
              ref={searchRef}
              onChange={handleSearchChange}
              border="none"
              bg={"#646B72"}
              borderRadius={"10px"}
              height="44px"
              _placeholder={{ opacity: 1, color: "white" }}
              placeholder="Search within projects"
              color="white"
            />
          </InputGroup>

          {/* <Flex flexDir="row" m="20px" alignItems="center">
          <Text color="white" mr="40px" fontSize="14px" fontWeight="800" textTransform="uppercase">
            filter by Industry
          </Text>
          <Button bg={activeFilter === 'all' ? 'yellow1' : 'transparent'} color="white" variant={activeFilter === 'all' ? 'solid' : 'ghost'} mr="10px" onClick={() => handleFilterChange('all')}>
            All
          </Button>
          <Button 
            color="white"
            variant={activeFilter === 'finance' ? 'solid' : 'ghost'}
            mr="10px"
            onClick={() => setActiveFilter('finance')}
          >
            Financial Services
          </Button>
          <Button
            bg={activeFilter === 'healthcare' ? 'yellow1' : 'transparent'}
            color="white"
            variant={activeFilter === 'healthcare' ? 'solid' : 'ghost'}
            mr="10px"
            onClick={() => setActiveFilter('healthcare')}
          >
            Healthcare
          </Button>
          <Button bg={activeFilter === 'retail' ? 'yellow1' : 'transparent'} color="white" variant={activeFilter === 'retail' ? 'solid' : 'ghost'} mr="10px" onClick={() => setActiveFilter('retail')}>
            Retail
          </Button>
          <Button
            bg={activeFilter === 'technology' ? 'yellow1' : 'transparent'}
            color="white"
            variant={activeFilter === 'technology' ? 'solid' : 'ghost'}
            mr="10px"
            onClick={() => setActiveFilter('technology')}
          >
            Technology
          </Button>
          <Button
            bg={activeFilter === 'government' ? 'yellow1' : 'transparent'}
            color="white"
            variant={activeFilter === 'government' ? 'solid' : 'ghost'}
            mr="10px"
            onClick={() => setActiveFilter('government')}
          >
            Government
          </Button>
          <Button bg={activeFilter === 'legal' ? 'yellow1' : 'transparent'} color="white" variant={activeFilter === 'legal' ? 'solid' : 'ghost'} mr="10px" onClick={() => setActiveFilter('legal')}>
            Legal
          </Button>
          <Button bg={activeFilter === 'other' ? 'yellow1' : 'transparent'} color="white" variant={activeFilter === 'other' ? 'solid' : 'ghost'} mr="10px" onClick={() => setActiveFilter('other')}>
            Other
          </Button>
        </Flex>

        <Flex flexDir="row" m="20px" mt="0px" alignItems="center">
          <Text color="white" mr="70px" fontSize="14px" fontWeight="800" textTransform="uppercase">
            Filter by Role
          </Text>
          <Button bg={activeFilter === 'all' ? 'yellow1' : 'transparent'} color="white" variant={activeFilter === 'all' ? 'solid' : 'ghost'} mr="10px" onClick={() => setActiveFilter('all')}>
            All
          </Button>
          <Button bg={activeFilter === 'owner' ? 'yellow1' : 'transparent'} color="white" variant={activeFilter === 'owner' ? 'solid' : 'ghost'} mr="10px" onClick={() => setActiveFilter('owner')}>
            Owner
          </Button>
          <Button
            bg={activeFilter === 'approver' ? 'yellow1' : 'transparent'}
            color="white"
            variant={activeFilter === 'approver' ? 'solid' : 'ghost'}
            mr="10px"
            onClick={() => setActiveFilter('approver')}
          >
            Approver
          </Button>
          <Button
            bg={activeFilter === 'collaborator' ? 'yellow1' : 'transparent'}
            color="white"
            variant={activeFilter === 'collaborator' ? 'solid' : 'ghost'}
            mr="10px"
            onClick={() => setActiveFilter('collaborator')}
          >
            Collaborator
          </Button>
          <Button bg={activeFilter === 'viewer' ? 'yellow1' : 'transparent'} color="white" variant={activeFilter === 'viewer' ? 'solid' : 'ghost'} mr="10px" onClick={() => setActiveFilter('viewer')}>
            Viewer
          </Button>
        </Flex> */}
          <Flex
            display={{ base: "none", md: "flex" }}
            flexDir={{ base: "row", sm: "row", md: "row" }}
            alignItems="left"
            py={2}
          >
            <Flex mr={3}>
              {checkProjects[0] ? (
                <>
                  <Button
                    bg="yellow1"
                    px={4}
                    color="white"
                    fontSize="16px"
                    onClick={() => {
                      checkProjects[0] ? onOpen1() : errorProject();
                    }}
                    _hover={{
                      background: "#F2DBA6",
                    }}
                    mr="10px"
                  >
                    &nbsp; <BsFolderPlus />
                  </Button>
                </>
              ) : null}

              <Menu closeOnSelect={false}>
                <MenuButton
                  as={Button}
                  bg={"#646B72"}
                  mr={3}
                  color="#E0E0E0"
                  px={5}
                  width="max-content"
                >
                  Industry &nbsp;&nbsp;&nbsp;{" "}
                  <svg
                    style={{ display: "inline-flex" }}
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.46 2.26L2.14 0.579999L5.3 3.86L8.46 0.58L10.14 2.26L5.3 7.1L0.46 2.26Z"
                      fill="white"
                    />
                  </svg>
                </MenuButton>
                <MenuList bg={"#646B72"} color="#E0E0E0">
                  {industryFilters?.map((filter, id) => (
                    <MenuItem key={id} bg={"#646B72"}>
                      <Checkbox
                        bg={"grey1"}
                        id={id}
                        isChecked={filters[filter.index]?.isChecked}
                        onChange={(e) => {
                          const updatedFilter = {
                            ...filter,
                            isChecked: e.target.checked,
                          };
                          handleFilterChange2(updatedFilter);
                        }}
                        sx={{
                          span: {
                            border: "none", // remove border from the span element
                            background: filters[filter.index]?.isChecked
                              ? "yellow"
                              : "background.grey1",
                          },
                        }}
                        mx={3}
                      />
                      {filter?.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              <Menu closeOnSelect={false}>
                <MenuButton
                  as={Button}
                  bg={"#646B72"}
                  color="#E0E0E0"
                  px={5}
                  width="max-content"
                >
                  Role &nbsp;&nbsp;&nbsp;{" "}
                  <svg
                    style={{ display: "inline-flex" }}
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.46 2.26L2.14 0.579999L5.3 3.86L8.46 0.58L10.14 2.26L5.3 7.1L0.46 2.26Z"
                      fill="white"
                    />
                  </svg>
                </MenuButton>
                <MenuList bg={"#646B72"} color="#E0E0E0">
                  {roleFilters?.map((filter, id) => (
                    <MenuItem key={id} bg={"#646B72"}>
                      <Checkbox
                        bg={"background.yellow1"}
                        id={id}
                        isChecked={filters[filter.index + 8]?.isChecked}
                        onChange={(e) => {
                          const updatedFilter = {
                            ...filter,
                            isChecked: e.target.checked,
                          };
                          handleFilterChange1(updatedFilter);
                        }}
                        sx={{
                          span: {
                            border: "none", // remove border from the span element
                          },
                        }}
                        mx={3}
                      />
                      {filter?.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Flex>

            <Flex pb={2}>
              {authCtx.role !== "Client" && (
                <>
                  <Button
                    bg="background.yellow1"
                    px={4}
                    color="white"
                    fontSize="16px"
                    onClick={() => {
                      onOpen();
                    }}
                    _hover={{
                      background: "#F2DBA6",
                    }}
                    mr="10px"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.29688 0.5625H9.70312C9.82812 0.5625 9.89062 0.625 9.89062 0.75V17.25C9.89062 17.375 9.82812 17.4375 9.70312 17.4375H8.29688C8.17187 17.4375 8.10938 17.375 8.10938 17.25V0.75C8.10938 0.625 8.17187 0.5625 8.29688 0.5625Z"
                        fill="white"
                      />
                      <path
                        d="M1.125 8.10938H16.875C17 8.10938 17.0625 8.17187 17.0625 8.29688V9.70312C17.0625 9.82813 17 9.89062 16.875 9.89062H1.125C1 9.89062 0.9375 9.82813 0.9375 9.70312V8.29688C0.9375 8.17187 1 8.10938 1.125 8.10938Z"
                        fill="white"
                      />
                    </svg>
                    &nbsp; New Project
                  </Button>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
        {projectInfo && !isLoading ? (
          <TableContainer
            width="100%"
            border="1px solid rgba(255, 255, 255, 0.42)"
            borderRadius="5px"
            padding="1px"
            mt={4}
            mb="69px"
          >
            <Table variant="simple" size="sm" bg="#3D454C">
              <Thead>
                <Tr>
                  {/* <Th
                    color="white"
                    fontSize="12px"
                    
                
                    cursor="pointer"
                  >
                    {"  "}&nbsp;
                    <Text
                      size="xs"
                      display={"inline-block"}
                      
                     
                    >
                     
                    </Text>
                  </Th> */}
                  <Th color="white" fontSize="14px" cursor="pointer">
                    {"  "}&nbsp;
                    <Text size="xs" display={"inline-block"}>
                      {/* <BsFillStarFill color='#C1962F' /> */}
                    </Text>
                  </Th>
                  {/* <Th
                    color="white"
                    fontSize="14px"
                    onClick={() => {
                      if (sortby !== "project_code") setSortby("project_code");
                      setDirection((direction) => direction * -1);
                    }}
                    cursor="pointer"
                  >
                    Code{"  "}&nbsp;
                    <Text
                      size="xs"
                      display={"inline"}
                      color={sortby === "project_code" ? "yellow" : "white"}
                    >
                      {sortby === "project_code"
                        ? direction === 1
                          ? "▲"
                          : "▼"
                        : "▼"}
                    </Text>
                  </Th> */}
                  <Th
                    color="white"
                    fontSize="14px"
                    onClick={() => {
                      if (sortby !== "title") setSortby("title");
                      setDirection((direction) => direction * -1);
                    }}
                    cursor="pointer"
                  >
                    Project Title{"  "}&nbsp;
                    <Text
                      size="xs"
                      display={"inline"}
                      cursor="pointer"
                      color={sortby === "title" ? "main.text" : "white"}
                    >
                      {sortby === "title" ? (direction === 1 ? "▲" : "▼") : "▼"}
                    </Text>
                  </Th>
                  <Th
                    color="white"
                    fontSize="14px"
                    onClick={() => {
                      if (sortby !== "client_name") setSortby("client_name");
                      setDirection((direction) => direction * -1);
                    }}
                    cursor="pointer"
                  >
                    Client{"  "}&nbsp;
                    <Text
                      size="xs"
                      display={"inline"}
                      cursor="pointer"
                      color={sortby === "client_name" ? "main.text" : "white"}
                    >
                      {sortby === "client_name"
                        ? direction === 1
                          ? "▲"
                          : "▼"
                        : "▼"}
                    </Text>
                  </Th>

                  <Th
                    color="white"
                    fontSize="14px"
                    cursor="pointer"
                    onClick={() => {
                      if (sortby !== "last_modified")
                        setSortby("last_modified");
                      setDirection((direction) => direction * -1);
                    }}
                  >
                    Last Modified{"  "}&nbsp;
                    <Text
                      size="xs"
                      display={"inline"}
                      cursor="pointer"
                      color={sortby === "last_modified" ? "main.text" : "white"}
                    >
                      {sortby === "last_modified"
                        ? direction === 1
                          ? "▲"
                          : "▼"
                        : "▼"}
                    </Text>
                  </Th>

                  <Th
                    color="white"
                    cursor="pointer"
                    fontSize="14px"
                    onClick={() => {
                      if (sortby !== "project_owner__email")
                        setSortby("project_owner__email");
                      setDirection((direction) => direction * -1);
                    }}
                  >
                    Owner{"  "}&nbsp;
                    <Text
                      size="xs"
                      display={"inline"}
                      cursor="pointer"
                      color={
                        sortby === "project_owner__email" ? "main.text" : "white"
                      }
                    >
                      {sortby === "project_owner__email"
                        ? direction === 1
                          ? "▲"
                          : "▼"
                        : "▼"}
                    </Text>{" "}
                  </Th>
                  <Th
                    color="white"
                    fontSize="14px"
                    cursor="pointer"
                    onClick={() => {
                      if (sortby !== "role") setSortby("role");
                      setDirection((direction) => direction * -1);
                    }}
                  >
                    Access{"  "}&nbsp;
                    <Text
                      size="xs"
                      display={"inline"}
                      cursor="pointer"
                      color={sortby === "role" ? "main.text" : "white"}
                    >
                      {sortby === "role" ? (direction === 1 ? "▲" : "▼") : "▼"}
                    </Text>
                  </Th>
                  <Th
                    color="white"
                    fontSize="14px"
                    cursor="pointer"
                    onClick={() => {
                      if (sortby !== "industry") setSortby("industry");
                      setDirection((direction) => direction * -1);
                    }}
                  >
                    Category{"  "}&nbsp;
                    <Text
                      size="xs"
                      display={"inline"}
                      cursor="pointer"
                      color={sortby === "industry" ? "main.text" : "white"}
                    >
                      {sortby === "industry"
                        ? direction === 1
                          ? "▲"
                          : "▼"
                        : "▼"}
                    </Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {projectInfo &&
                  projectInfo.map((item, index) => (
                    <>
                      {
                        <Tr
                          key={index}
                          bg={index % 2 === 1 ? "#3D454C" : "#434E58"}
                          // _hover={{opacity:'0.6'}}
                          cursor="pointer"
                        >
                          {" "}
                          {/* <Td
                            color="white"
                           
                            
                            style={{
                              minWidth: "5px",
                              maxWidth: "5px",
                              width: "5px",
                             
                             
                            }}
                          >
                            
                            </Td> */}
                          <Td
                            color="white"
                            fontSize="14px"
                            textAlign="left"
                            pl="4"
                            ml="5"
                            style={{
                              minWidth: "65px",
                              maxWidth: "65px",
                              width: "65px",
                            }}
                          >
                            {/* {item.favorite?<>true</>:<>false</>} */}
                            {/* {console.log(item)} */}
                            <Checkbox
                              mr="0.7"
                              style={{ verticalAlign: "middle" }}
                              size="sm"
                              onChange={() => {
                                checkBoxList(item.id);
                                getFolders();
                              }}
                            ></Checkbox>

                            <Button
                              bg="transparent"
                              // justifyContent={"left"}
                              // color="white"
                              // fontSize="16px"
                              _hover={{
                                background: "transparent",
                              }}
                              pl={4}
                              mr={2}
                              // mr="10px"
                              height="1.9rem"
                              size="sm"
                              onClick={() => {
                                setFavorites(item);
                              }}
                            >
                              &nbsp;
                              {item.favorite ? (
                                <>
                                  <BsFillStarFill color="#C1962F" />
                                </>
                              ) : (
                                <>
                                  <BsStar color="#fff" />
                                </>
                              )}
                            </Button>
                          </Td>
                          {/* <Td
                            color="white"
                            fontSize="14px"
                            style={{
                              minWidth: "180px",
                              maxWidth: "180px",
                              width: "180px",
                            }}
                            onClick={() =>
                            (window.location = "/project/" + item.id)
                          }
                          >
                            {item.project_code?.length > 24
                              ? item.project_code.slice(0, 24) + "..."
                              : item.project_code}
                          </Td> */}
                          <Td
                            color="white"
                            fontSize="14px"
                            style={{
                              minWidth: "405px",
                              maxWidth: "405px",
                              width: "405px",
                            }}
                            onClick={() =>
                              (window.location = "/project/" + item.id)
                            }
                          >
                            <Text
                              isTruncated
                              display={"inline"}
                              style={{
                                minWidth: "205px",
                                maxWidth: "205px",
                                width: "205px",
                              }}
                            >
                              {item.title?.length > 30
                                ? item.title.slice(0, 38) + "..."
                                : item.title}
                            </Text>
                            <Tag
                              fontSize={"10px"}
                              bg="background.grey4"
                              color="#FFF"
                              size="sm"
                              borderRadius="full"
                              ml="2"
                            >
                              {item.project_code?.length > 24
                                ? item.project_code.slice(0, 24) + "..."
                                : item.project_code}
                            </Tag>
                          </Td>
                          <Td
                            color="white"
                            fontSize="14px"
                            style={{
                              minWidth: "200px",
                              maxWidth: "200px",
                              width: "200px",
                            }}
                            onClick={() =>
                              (window.location = "/project/" + item.id)
                            }
                          >
                            {item.client_name?.length > 24
                              ? item.client_name.slice(0, 24) + "..."
                              : item.client_name}
                          </Td>
                          <Td
                            color="white"
                            fontSize="14px"
                            style={{
                              minWidth: "180px",
                              maxWidth: "180px",
                              width: "180px",
                            }}
                            onClick={() =>
                              (window.location = "/project/" + item.id)
                            }
                          >
                            {item.last_modified.split("T")[0]} |{" "}
                            {item.last_modified
                              .split("T")[1]
                              .split(".")[0]
                              .slice(0, 5)}{" "}
                          </Td>
                          <Td
                            color="white"
                            fontSize="14px"
                            style={{
                              minWidth: "140px",
                              maxWidth: "140px",
                              width: "140px",
                            }}
                            onClick={() =>
                              (window.location = "/project/" + item.id)
                            }
                          >
                            {item.project_owner.name?.length > 30
                              ? item.project_owner.name.slice(0, 30) + "..."
                              : item.project_owner.name}
                          </Td>
                          <Td
                            color="white"
                            fontSize="14px"
                            style={{
                              minWidth: "120px",
                              maxWidth: "120px",
                              width: "120px",
                            }}
                            onClick={() =>
                              (window.location = "/project/" + item.id)
                            }
                          >
                            {item.role}
                          </Td>
                          <Td
                            color="white"
                            fontSize="14px"
                            width="225px"
                            style={{
                              minWidth: "150px",
                              maxWidth: "150px",
                              width: "150px",
                            }}
                            onClick={() =>
                              (window.location = "/project/" + item.id)
                            }
                          >
                            {item.industry}
                          </Td>
                        </Tr>
                      }
                    </>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Spinner />
        )}
      </Flex>
      <Flex
        zIndex="10"
        // pos="fixed"
        bottom="0"
        width={{ base: "100%", md: "95%" }}
        bg="bg"
        p={2}
      >
        {projectInfo && (
          <Flex
            borderRadius="5px"
            height="fit-content"
            flexDir="row"
            justifyContent="space-between"
            py={1}
            width="100%"
            mb="10px"
            bg={{ base: "inherit", lg: "rgba(67, 78, 88, 0.26)" }}
          >
            <Pagination
              className="pagination-bar"
              currentPage={pageNo}
              totalCount={pageCount * PageSize}
              pageSize={PageSize}
              onPageChange={(page) => handlePage(page)}
              onNext={handleNext}
              onPrevious={handlePrevious}
            />
            {currentLength.current < PageSize && <Flex></Flex>}
            <Flex
              justifyContent="space-between"
              alignItems="center"
              cursor="pointer"
              fontWeight={500}
            >
              {authCtx.role !== "Client" && (
                <Text
                  bg="inherit"
                  pr={{ base: 0, md: 2 }}
                  color="white"
                  fontSize="18px"
                  onClick={() => (window.location = "/trash-projects")}
                  display="inline-flex"
                  alignItems="center"
                >
                  <svg
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.4375 2.3125H5.25C5.35313 2.3125 5.4375 2.22812 5.4375 2.125V2.3125H12.5625V2.125C12.5625 2.22812 12.6469 2.3125 12.75 2.3125H12.5625V4H14.25V2.125C14.25 1.29766 13.5773 0.625 12.75 0.625H5.25C4.42266 0.625 3.75 1.29766 3.75 2.125V4H5.4375V2.3125ZM17.25 4H0.75C0.335156 4 0 4.33516 0 4.75V5.5C0 5.60313 0.084375 5.6875 0.1875 5.6875H1.60312L2.18203 17.9453C2.21953 18.7445 2.88047 19.375 3.67969 19.375H14.3203C15.1219 19.375 15.7805 18.7469 15.818 17.9453L16.3969 5.6875H17.8125C17.9156 5.6875 18 5.60313 18 5.5V4.75C18 4.33516 17.6648 4 17.25 4ZM14.1398 17.6875H3.86016L3.29297 5.6875H14.707L14.1398 17.6875Z"
                      fill="white"
                    />
                  </svg>{" "}
                  &nbsp;{" "}
                  <Text display={{ base: "none", sm: "inline" }} width="">
                    {"Trash"}
                  </Text>
                </Text>
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
      <>
        <Modal bg="#444d54" isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay BG="#2A3843" />
          <ModalContent bg="#444d54">
            <ModalHeader color="white">Add a New Project</ModalHeader>
            <ModalBody>
              <Flex flexDir="column" mb="10px">
                <Text
                  color="white"
                  fontWeight="700"
                  fontSize="18px"
                  mb="5px"
                  mt="15px"
                >
                  Project Title
                </Text>
                <Input
                  bg="#525C64"
                  color="white"
                  fontWeight="700"
                  placeholder="Add Project Title"
                  maxlength={maxChar}
                  ref={title}
                  // enable this function to print the number of characters left
                  // onChange={charRem}
                ></Input>
                {/* This line is for printing the characters left */}
                {/* <Text color="white"float="right">{maxChar - charRemain} characters remaining</Text> */}
              </Flex>
              <Flex flexDir="column">
                <Text color="white" fontWeight="700" fontSize="18px" mb="5px">
                  Industry
                </Text>
                <Select
                  bg="#525C64"
                  isMulti="true"
                  color="white"
                  ref={industry}
                  fontWeight="700"
                  placeholder="Select Industry"
                >
                  <option value="Financial Services">Financial Services</option>
                  <option value="Healthcare">Healthcare</option>
                  <option value="Retail">Retail</option>
                  <option value="Technology">Technology</option>
                  <option value="Government">Government</option>
                  <option value="Legal">Legal</option>
                  <option value="Other">Other</option>
                </Select>
              </Flex>
              <Flex flexDir="column">
                <Text
                  color="white"
                  fontWeight="700"
                  fontSize="18px"
                  mb="5px"
                  mt="15px"
                >
                  Name of the Client
                </Text>
                <Input
                  bg="#525C64"
                  color="white"
                  fontWeight="700"
                  placeholder="Enter Client Name"
                  ref={client}
                ></Input>
              </Flex>
              <Flex flexDir="column">
                <Text
                  color="white"
                  fontWeight="700"
                  fontSize="18px"
                  mb="5px"
                  mt="15px"
                >
                  Project Code
                </Text>
                <Input
                  bg="#525C64"
                  color="white"
                  fontWeight="700"
                  placeholder="Add Project Code"
                  ref={code}
                ></Input>
              </Flex>
              <Flex flexDir="column">
                <Text color="white" fontWeight="700" fontSize="18px" mb="5px">
                  Visibility
                </Text>
                <Select
                  bg="#525C64"
                  color="white"
                  ref={visibility}
                  fontWeight="700"
                  placeholder="Choose Visibility"
                >
                  <option value="public">public</option>
                  <option value="private">private</option>
                </Select>
              </Flex>
            </ModalBody>

            <ModalFooter mt="10px">
              <Button
                bg="background.yellow1"
                color="white"
                mr={3}
                onClick={addProjectHandler}
              >
                Add Project to Library
              </Button>
              <Button color="white" variant="ghost" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
      <>
        <Modal bg="#444d54" isOpen={isOpen1} onClose={onClose1} isCentered>
          <ModalOverlay BG="#2A3843" />
          <ModalContent bg="#444d54">
            <ModalHeader color="white">Add Projects to Folder</ModalHeader>
            <ModalBody>
              <Flex flexDir="column">
                <Text color="white" fontWeight="700" fontSize="18px" mb="5px">
                  Your Folders
                </Text>

                <Select
                  bg="#525C64"
                  isMulti="true"
                  color="white"
                  ref={folderSlug}
                  fontWeight="700"
                  placeholder="Select Folder"
                >
                  {folders?.map((f) => (
                    <>
                      <option value={`${f.id}`}>{f.title}</option>
                    </>
                  ))}

                  {/* <option value="Financial Services">Financial Services</option>
                  <option value="Healthcare">Healthcare</option>
                  <option value="Retail">Retail</option>
                  <option value="Technology">Technology</option>
                  <option value="Government">Government</option>
                  <option value="Legal">Legal</option>
                  <option value="Other">Other</option> */}
                </Select>
              </Flex>
            </ModalBody>

            <ModalFooter mt="10px">
              <Button
                bg="yellow1"
                color="white"
                mr={3}
                onClick={addProjectToFolderHandler}
              >
                Add Projects to Folder
              </Button>
              <Button color="white" variant="ghost" onClick={onClose1}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </Flex>
    // </HotKeys>
  );
};

export default Library;
